import { useQuery } from '@tanstack/react-query'
import { PublicInstructorEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCourseInstructorsPublic(courseId: string): {
  instructors: PublicInstructorEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: instructors,
    isLoading,
    isError,
  } = useQuery(['public', 'instructors', courseId], async () => {
    return qoursesApi.coursePublic.coursePublicControllerGetCourseInstructor(courseId)
  })

  let isEmpty = true

  if (instructors === undefined) {
    return { instructors: [], isLoading, isEmpty, isError }
  }

  if (instructors.length > 0) {
    isEmpty = false
  }

  return { instructors, isLoading, isEmpty, isError }
}
