import { APP_METADATA_CLAIM_NAMESPACE } from '@/SidebarLayoutOutlet.tsx'
import { organizationStore } from '@/hydration/organization.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { Turnstile } from '@marsidev/react-turnstile'
import { useQueryClient } from '@tanstack/react-query'
import { Crisp } from 'crisp-sdk-web'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronLeft, LogOut, Undo2 } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { qoursesApi, setActiveApiOrganization } from '../api/qourses.tsx'
import { minDelay } from '../utils.tsx'
import { AnimatedCheckIcon } from './AnimatedCheckIcon.tsx'
import LoadingSpinner from './LoadingSpinner.tsx'

export default function OnboardingScreen() {
  const { t: translate } = useTranslation()

  const { user, logout } = useAuth0()

  const [searchParams] = useSearchParams()

  const [organizationName, setOrganizationName] = useState('')
  const [firstName, setFirstName] = useState(user?.given_name)
  const [lastName, setLastName] = useState(user?.family_name)
  const { updateActiveOrganization } = organizationStore((state) => state)

  const queryClient = useQueryClient()

  const [hasProgressedAtLeastOnce, setHasProgressedAtLeastOnce] = useState(false)

  const [turnstileToken, setTurnstileToken] = useState<string | null>(null)
  //const [turnstileError, setTurnstileError] = useState<boolean | null>(null)
  const turnstileSiteId = import.meta.env.VITE_TURNSTILE_SITE_ID

  const turnstileRef = useRef(null)
  const [showTurnstile, setShowTurnstile] = useState(false)

  const [step, setStep] = useState(0)

  const navigate = useNavigate()

  const userHasNameBecauseOfSocial = !!user?.given_name && !!user?.family_name

  const organizationKeys =
    user && user[APP_METADATA_CLAIM_NAMESPACE] && user[APP_METADATA_CLAIM_NAMESPACE].organizations
      ? Object.keys(user[APP_METADATA_CLAIM_NAMESPACE].organizations)
      : undefined

  useEffect(() => {
    const hasOrganization = Array.isArray(organizationKeys) && organizationKeys.length > 0

    // If a user is navigated here via /onboarding link, we only allow them to proceed if they DO NOT already have an organization
    // If they do, we redirect them to the app
    // A user may create another organization if they are redirected here via the /onboarding link with the allow=true query param
    if (hasOrganization) {
      const allowWithExistingOrganization = searchParams.get('allow') === 'true'
      console.log('allowWithExistingOrganization', allowWithExistingOrganization)
      if (allowWithExistingOrganization) {
        // Do nothing
      } else {
        navigate('/')
      }
    }
  }, [])

  const handleNextStep = async () => {
    setHasProgressedAtLeastOnce(true)
    if (!!organizationName && !!firstName && !!lastName && !!turnstileToken) {
      setStep(4)

      try {
        const organization = await minDelay(
          qoursesApi.organization.organizationControllerCreateFirstOrganization({
            familyName: lastName,
            firstName: firstName,
            organizationName: organizationName,
            turnstileChallenge: turnstileToken,
          }),
          1000,
        )
        queryClient.invalidateQueries(['activeOrganization'])
        updateActiveOrganization(organization)
        setActiveApiOrganization(organization.slug)
        navigate('/')
      } catch (e) {
        //TODO: Show some error state when this happens!!
        console.error(e)
        setStep(0)
      }
    }
    // If the user already has a first a lastname we can use, don't ask for it
    if (!!firstName && !!lastName) {
      setStep(3)
    } else {
      setStep(step + 1)
    }
  }

  const handlePreviousStep = () => {
    if (step === 3 && userHasNameBecauseOfSocial) {
      setStep(0)
    } else {
      setStep(step - 1)
    }
  }

  const alreadyHasOrganization = user[APP_METADATA_CLAIM_NAMESPACE]?.organizations !== undefined

  return (
    <div className="flex flex-row justify-center h-screen bg-white">
      <div className="flex h-full items-center m-5 sm:m-8">
        <div className="h-1/2 sm:h-1/2 flex flex-col sm:justify-between w-auto md:w-[20rem] lg:w-[30rem]">
          <div>
            <AnimatePresence mode="wait">
              {match(step)
                .with(0, () => (
                  <motion.div
                    key={'step-0'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                  >
                    <h1 className="text-gray-800 font-semibold text-4xl font-merriweather">
                      {translate('onboarding.step-0.title', {
                        name: user?.given_name,
                      })}
                    </h1>
                    <span>
                      <p className="text-gray-500 font-normal text-lg max-w-md pt-4">
                        {translate('onboarding.step-0.subtitle')}
                      </p>
                    </span>
                  </motion.div>
                ))
                .with(1, () => (
                  <motion.div
                    key={'step-1'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                  >
                    <h1 className="text-gray-800 font-semibold text-4xl font-merriweather">
                      {translate('onboarding.step-1.title')}
                    </h1>
                    <span>
                      <p className="text-gray-500 font-normal text-lg max-w-md pt-4">
                        {translate('onboarding.step-1.subtitle')}
                      </p>
                    </span>
                  </motion.div>
                ))
                .with(2, () => (
                  <motion.div
                    key={'step-2'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                  >
                    <h1 className="text-gray-800 font-semibold text-4xl font-merriweather">
                      {translate('onboarding.step-2.title')}
                    </h1>
                    <span>
                      <p className="text-gray-500 font-normal text-lg max-w-md pt-4">
                        {translate('onboarding.step-2.subtitle')}
                      </p>
                    </span>
                  </motion.div>
                ))
                .with(3, () => (
                  <motion.div
                    key={'step-3'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                  >
                    <h1 className="text-gray-800 font-semibold text-4xl font-merriweather">
                      {translate('onboarding.step-3.title')}
                    </h1>
                    <span>
                      <p className="text-gray-500 font-normal text-lg max-w-md pt-4">
                        {translate('onboarding.step-3.subtitle')}
                      </p>
                    </span>
                  </motion.div>
                ))
                .with(4, () => (
                  <motion.div
                    key={'step-3'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                  >
                    <h1 className="text-gray-800 font-semibold text-4xl font-merriweather">
                      {translate('onboarding.loading')}
                    </h1>
                    <span>
                      <p className="text-gray-500 font-normal text-lg max-w-md pt-4 ">
                        {translate('onboarding.loading-subtitle')}
                      </p>
                    </span>
                  </motion.div>
                ))
                .otherwise((): never => null as never)}
            </AnimatePresence>
            <div className="pt-8 w-3/4">
              <div className="mt-2">
                <AnimatePresence mode="wait">
                  {match(step)
                    .with(0, () => <></>)
                    .with(1, () => (
                      <motion.div
                        key={'input-step-1'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        exit={{ opacity: 0 }}
                      >
                        <input
                          type="text"
                          name="text"
                          id="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder={translate('onboarding.step-2.input-placeholder')}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <p className="mt-2 ml-1 text-xs text-gray-500" id="email-description">
                          {translate('onboarding.step-1.input-subtitle')}
                        </p>
                      </motion.div>
                    ))
                    .with(2, () => (
                      <motion.div
                        key={'input-step-2'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        exit={{ opacity: 0 }}
                      >
                        <input
                          type="text"
                          name="text"
                          id="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder={translate('onboarding.step-2.input-placeholder')}
                          aria-describedby="email-description"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <p className="mt-2 ml-1 text-xs text-gray-500" id="email-description">
                          {translate('onboarding.step-2.input-subtitle')}
                        </p>
                      </motion.div>
                    ))
                    .with(3, () => (
                      <motion.div
                        key={'input-step-3'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        exit={{ opacity: 0 }}
                        className="relative"
                      >
                        <input
                          type="text"
                          name="text"
                          id="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder={translate('onboarding.step-3.input-placeholder')}
                          aria-describedby="email-description"
                          onChange={(e) => setOrganizationName(e.target.value)}
                        />
                        {organizationName.length > 2 && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex top-0.5 items-center pr-3 h-8 w-8">
                            <AnimatedCheckIcon className="h-6 w-6 text-green-500" />
                          </div>
                        )}
                        <p className="mt-2 ml-1 text-xs text-gray-500">
                          {translate('onboarding.step-3.input-subtitle')}
                        </p>
                        <div className="mt-5">
                          <Turnstile
                            ref={turnstileRef}
                            // See: https://docs.page/marsidev/react-turnstile/use-ref-methods
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            onExpire={() => turnstileRef.current?.reset()}
                            onError={() => setShowTurnstile(true)}
                            onBeforeInteractive={() => {
                              setShowTurnstile(true)
                            }}
                            siteKey={turnstileSiteId}
                            options={{
                              theme: 'light',
                              size: !showTurnstile ? 'invisible' : 'normal',
                            }}
                            onSuccess={(token) => setTurnstileToken(token)}
                          />
                        </div>
                      </motion.div>
                    ))
                    .otherwise((): never => null as never)}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-10 gap-x-2">
            <div>
              {hasProgressedAtLeastOnce && (
                <Button
                  disabled={!(step > 0)}
                  variant="ghost"
                  onClick={() => {
                    handlePreviousStep()
                  }}
                >
                  <ChevronLeft className="h-4 w-4" />
                </Button>
              )}
            </div>
            {match(step)
              .with(0, () => (
                <Button variant="indigo" onClick={() => handleNextStep()}>
                  {translate('onboarding.step-0.button')}
                </Button>
              ))
              .with(1, () => (
                <Button variant="indigo" onClick={() => handleNextStep()}>
                  {translate('onboarding.step-1.button')}
                </Button>
              ))
              .with(2, () => (
                <Button variant="indigo" onClick={() => handleNextStep()}>
                  {translate('onboarding.step-2.button')}
                </Button>
              ))
              .with(3, () => (
                <Button
                  variant="indigo"
                  disabled={!(turnstileToken && organizationName.length > 0)}
                  onClick={() => handleNextStep()}
                >
                  {translate('onboarding.step-3.button')}
                </Button>
              ))
              .with(4, () => (
                <Button variant="indigo" disabled={true}>
                  <div className="w-5 mx-auto">
                    <LoadingSpinner className="" />
                  </div>
                </Button>
              ))
              .otherwise(() => 'Get started')}
            {!hasProgressedAtLeastOnce && (
              <Button
                variant="ghost"
                className="text-gray-400"
                onClick={() => {
                  Crisp.session.reset(false)
                  logout()
                }}
              >
                {translate('onboarding.logout')}
                <LogOut className="h-4 w-4 ml-1" />
              </Button>
            )}
          </div>
          {alreadyHasOrganization && !hasProgressedAtLeastOnce && (
            <div className="mt-4 text-muted-foreground p-2 rounded-lg text-sm flex">
              <p>{translate('onboarding.alreadyHaveOrganizationHint')}</p>
              <Button size="sm" className="ml-4" variant="secondary" onClick={() => navigate('/')}>
                Zur App
                <Undo2 className="h-4 w-4 ml-1" />
              </Button>
            </div>
          )}
        </div>
        <div className="h-3/4 sm:h-1/2 flex flex-col justify-between">
          <div className="hidden sm:flex flex-col shrink justify-start relative h-full ">
            <motion.div
              drag={true}
              dragSnapToOrigin={true}
              initial={{ opacity: 0, translateY: -20, width: 0, height: 0 }}
              animate={{
                opacity: 1,
                translateY: 0,
                width: '16rem',
                height: step > 0 ? '90%' : '60%',
              }}
              transition={{
                type: 'spring',
                bounce: 0.5,
                delay: 0.5,
              }}
              dragTransition={{
                power: 10,
                min: 0,
                max: 200,
                timeConstant: 250,
                bounceDamping: 12,
              }}
              className="bg-gray-200 w-64 h-full rounded-2xl ml-4 relative cursor-grab"
            >
              <div className="absolute flex w-full z-10 h-10 rounded-t-2xl top-4 left-4">
                <div className="flex flex-row gap-2">
                  <div className="flex w-3 h-3 bg-gray-400 rounded-full hover:bg-red-500 transition-all"></div>
                  <div className="flex w-3 h-3 bg-gray-400 rounded-full hover:bg-yellow-500 transition-all"></div>
                  <div className="flex w-3 h-3 bg-gray-400 rounded-full hover:bg-green-500 transition-all"></div>
                </div>
              </div>
              <div className="justify-start flex pt-16 flex-col pl-4 gap-y-4 pr-4">
                <div className="bg-gray-300 w-full h-7 rounded-lg" />
                <div className="bg-gray-300 w-full h-7 rounded-lg" />
                <div className="bg-gray-300 w-full h-7 rounded-lg" />
                <AnimatePresence>
                  {step > 0 && firstName && firstName.length > 3 && (
                    <motion.p
                      key={'first-name-card'}
                      initial={{ opacity: 0, translateY: -20 }}
                      animate={{ opacity: 1, translateY: 0 }}
                      transition={{
                        type: 'spring',
                        bounce: 0.5,
                        delay: 0.5,
                      }}
                      exit={{ opacity: 0, translateY: -20 }}
                      className="text-gray-800 text-left font-semibold pt-2"
                    >
                      {firstName}'s team
                    </motion.p>
                  )}
                  {organizationName.length > 2 && (
                    <>
                      <motion.div
                        key={'organization-name-card'}
                        initial={{ opacity: 0, translateX: 20 }}
                        animate={{ opacity: 1, translateX: 0 }}
                        transition={{
                          type: 'spring',
                          bounce: 0.5,
                          delay: 0.5,
                        }}
                        exit={{ opacity: 0, translateX: 20 }}
                        className="flex bg-gray-300 rounded-lg p-1 text-gray-700 w-full"
                      >
                        <span className="uppercase ml-1 mr-2 text-gray-500 border-gray-600 transition flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white">
                          {organizationName[0]}
                        </span>
                        {organizationName}
                      </motion.div>
                    </>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
