/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateCourseDto = {
    name?: string;
    description?: string;
    maximumAttendees?: number;
    priceInMills?: number;
    defaultInstructorIds?: Array<string>;
    scheduleId?: string;
    locationId?: string;
    termsOfServiceId?: string;
    revocationPolicyId?: string;
    publiclyVisible?: boolean;
    formIds?: Array<string>;
    tagIds?: Array<string>;
    autoSchedulingType?: UpdateCourseDto.autoSchedulingType;
    stripePaymentProfileId?: string;
    formSubmissionRequiredByDaysBeforeStart?: number;
};
export namespace UpdateCourseDto {
    export enum autoSchedulingType {
        OFF = 'OFF',
        REMINDER = 'REMINDER',
        COPILOT = 'COPILOT',
        AUTOMATIC = 'AUTOMATIC',
    }
}

