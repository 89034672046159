import { useQuery } from '@tanstack/react-query'
import { MeetingEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetMeeting(meetingId: string): {
  meeting: MeetingEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: meeting,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(
    getMeetingQueryKey(meetingId),
    async () => {
      return qoursesApi.meeting.meetingControllerGetMeeting(meetingId)
    },
    {
      enabled: !!meetingId,
    },
  )

  return { meeting, isLoading, isError, refetch, isRefetching }
}

export const getMeetingQueryKey = (meetingId: string) => ['meeting', meetingId]
