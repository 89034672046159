import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { MediaImageEntity } from '../../../qourses-api-client'

export default function useGetMediaImages(): {
  mediaImages: MediaImageEntity[]
  isLoading: boolean
  isEmptyMediaImages: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: mediaImages,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getMediaImagesQueryKey(), async () => {
    return qoursesApi.media.mediaControllerGetMediaImages('false')
  })

  const isEmpty = !mediaImages || mediaImages.length === 0

  return {
    mediaImages: mediaImages || [],
    isLoading,
    isEmptyMediaImages: isEmpty,
    isError,
    refetch,
    isRefetching,
  }
}

export const getMediaImagesQueryKey = () => ['mediaImages']
