import useGetEndCustomerMultipassesForCourse from '@/hooks/customer/useGetEndCustomerMultipassesForCourse.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { BadgeEuro, Ticket, Timer, UsersRound } from 'lucide-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {
  PublicCourseWithPriceDetailsEntity,
  PublicLocationEntity,
} from '../../../../../qourses-api-client'
import useReverseGeocoding from '../../../../hooks/locations/useReverseGeocoding.tsx'
import useGetCourseInstructorsPublic from '../../../../hooks/public/useGetCourseInstructorsPublic.tsx'
import useGetCourseMeetingsPublic from '../../../../hooks/public/useGetCourseMeetingsPublic.tsx'
import useGetLocationPublic from '../../../../hooks/public/useGetLocationPublic.tsx'

function CourseRowComponent(props: { course: PublicCourseWithPriceDetailsEntity }) {
  const course = props.course
  const { t: translate } = useTranslation()

  const { meetings, isLoading: meetingsLoading } = useGetCourseMeetingsPublic(course.id)
  const { instructors, isLoading: instructorLoading } = useGetCourseInstructorsPublic(course.id)
  const { location, isLoading: locationLoading } = useGetLocationPublic(course.locationId)

  const { isAuthenticated } = useAuth0()

  return (
    <div className="flex flex-row" id={course.id} data-testid={course.id}>
      <div className="flex flex-col justify-start sm:gap-y-2.5">
        <div>
          <p className="items-center pr-2 font-medium" translate="no">
            {course.name}
            <span className="ml-1 gap-x-1 text-sm font-normal text-gray-800">
              {instructorLoading ? (
                <Skeleton width={100} />
              ) : (
                instructors
                  .map((instructor) => instructor.firstName)
                  .reduce((acc, name, index) => {
                    if (index === 0) return `mit ${name}`
                    return `${acc} und ${name}`
                  }, '')
              )}
            </span>
          </p>
          <div>
            {locationLoading ? (
              <Skeleton width={200} />
            ) : (
              <div className="text-xs font-normal text-muted-foreground">
                <AddressLocation location={location} />
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center text-sm font-normal text-gray-800 sm:mt-0">
          <div className="my-2 flex max-w-md flex-wrap items-center gap-x-1.5 gap-y-2 whitespace-nowrap sm:my-1">
            {!isAuthenticated ? (
              <Badge variant="outline" className="ring-1 ring-gray-200">
                <BadgeEuro className="mr-1 h-4 w-4 text-indigo-600" aria-hidden="true" />
                {translate('common.currency.startsAtEUR', {
                  val: course.startsAtPriceWithTaxesInMills / 1000,
                  minimumFractionDigits: 2,
                })}
              </Badge>
            ) : (
              <PriceBadgeAndMaybeMultipass course={course} />
            )}
            {meetings.length > 0 && (
              <Badge variant="outline" className="ring-1 ring-gray-200">
                <Timer className="mr-1 h-4 w-4 text-indigo-700" aria-hidden="true" />
                {meetingsLoading ? (
                  <Skeleton width={50} className="ring-1 ring-gray-300" />
                ) : (
                  <p className="font-medium">
                    {meetings[0].durationInMinutes} {translate('common.time.minutes-short')}
                  </p>
                )}
              </Badge>
            )}
            <Badge variant="outline" className="ring-1 ring-gray-200">
              <UsersRound className="mr-1 h-4 w-4 text-indigo-600" aria-hidden="true" />
              {translate('pages.public.booking.badge.maximum-attendees', {
                count: course.maximumAttendees,
              })}
            </Badge>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CourseRow = React.memo(CourseRowComponent)

function AddressLocation(props: { location: PublicLocationEntity }) {
  const location = props.location

  const { geocodeResult, isLoading } = useReverseGeocoding(
    parseFloat(location.latitude),
    parseFloat(location.longitude),
  )

  return <div>{isLoading ? <Skeleton width={15} /> : geocodeResult.features[0].place_name}</div>
}

function PriceBadgeAndMaybeMultipass(props: { course: PublicCourseWithPriceDetailsEntity }) {
  const { t: translate } = useTranslation()
  const { multipasses, isLoading, isError } = useGetEndCustomerMultipassesForCourse(props.course.id)

  // Check if any of the multipasses available for the course have remaining usages
  const hasAvailableUsages = useMemo(() => {
    return multipasses.some((multipass) => multipass.usagesRemaining > 0)
  }, [multipasses])

  if (isLoading || isError) {
    return (
      <Badge variant="outline">
        <Skeleton className="h-full w-20" />
      </Badge>
    )
  }

  return (
    <>
      {hasAvailableUsages ? (
        <Badge variant="indigoBezel" className="ring-1 ring-gray-200">
          {translate('pages.public.booking.badge.multipass-available')}
          <Ticket className="ml-1 h-4 w-4 text-white" aria-hidden="true" />
        </Badge>
      ) : (
        <Badge variant="outline" className="ring-1 ring-gray-200">
          <BadgeEuro className="mr-1 h-4 w-4 text-indigo-600" aria-hidden="true" />
          {translate('common.currency.startsAtEUR', {
            val: props.course.startsAtPriceWithTaxesInMills / 1000,
            minimumFractionDigits: 2,
          })}
        </Badge>
      )}
    </>
  )
}
