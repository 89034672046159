import { useQuery } from '@tanstack/react-query'
import { TagEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetTagsPublic(organizationId: string): {
  tags: TagEntity[]
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: tags,
    isLoading,
    isError,
    refetch,
  } = useQuery(['tag', 'organization', organizationId], async () => {
    return qoursesApi.tagPublic.tagPublicControllerGetAllTags(organizationId)
  })

  return { tags, isLoading, isError, refetch }
}
