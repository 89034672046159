import { motion } from 'framer-motion'

export default function NotificationBubble(props: { notification: string; layoutId: string }) {
  return (
    <motion.div
      layoutId={props.layoutId}
      layout
      className="mr-2 flex size-5 justify-end rounded-full bg-red-500 bg-gradient-to-b from-red-500 to-red-600 p-0.5 ring-[3px] ring-red-300"
      transition={{
        rotate: {
          repeat: Infinity,
          repeatDelay: 1.3,
          duration: 0.5,
        },
        translateX: {
          type: 'spring',
          bounce: 0.3,
        },
        opacity: {
          duration: 0.3,
        },
      }}
      animate={{
        opacity: 1,
        rotate: [0, -30, 30, 0, 15, 0],
      }}
    >
      <p className="mx-auto my-auto text-xs font-semibold text-white">{props.notification}</p>
    </motion.div>
  )
}
