import BackofficeAddMeetingsToCalendarModal from '@/components/modals/AddMeetingsToCalendarBackofficeModal.tsx'
import AddMeetingsToCalendarModal from '@/components/modals/AddMeetingsToCalendarModal.tsx'
import ApproveScheduleBatchModal from '@/components/modals/ApproveScheduleBatchModal.tsx'
import ArchiveCouponModal from '@/components/modals/ArchiveCouponModal.tsx'
import ArchiveCourseGroupModal from '@/components/modals/ArchiveCourseGroupModal.tsx'
import ArchiveCourseModal from '@/components/modals/ArchiveCourseModal.tsx'
import ArchiveMeetingModal from '@/components/modals/ArchiveMeetingModal.tsx'
import ArchiveMultipassProductModal from '@/components/modals/ArchiveMultipassProductModal.tsx'
import BookingPageCourseDetailsModal from '@/components/modals/BookingPageCourseDetailsModal.tsx'
import CancelMeetingModal from '@/components/modals/CancelMeetingModal.tsx'
import CourseCreatedModal from '@/components/modals/CourseCreatedModal.tsx'
import { CreateBookingLinkModal } from '@/components/modals/CreateBookingLinkModal.tsx'
import { CreateScheduleBatchModal } from '@/components/modals/CreateScheduleBatchModal.tsx'
import CustomerRefundBookingOptionsModal from '@/components/modals/CustomerRefundBookingOptionsModal.tsx'
import InputRebateCodeModal from '@/components/modals/InputRebateCodeModal.tsx'
import { MediaImageContentReviewModal } from '@/components/modals/MediaImageContentReviewModal.tsx'
import MediaImagePickerModal from '@/components/modals/MediaImagePickerModal.tsx'
import RefundBookingOptionsModal from '@/components/modals/RefundBookingOptionsModal.tsx'
import RestoreCouponModal from '@/components/modals/RestoreCouponModal.tsx'
import RestoreCourseGroupModal from '@/components/modals/RestoreCourseGroupModal.tsx'
import RestoreCourseModal from '@/components/modals/RestoreCourseModal.tsx'
import RestoreMeetingModal from '@/components/modals/RestoreMeetingModal.tsx'
import RestoreMultipassProductModal from '@/components/modals/RestoreMultipassProductModal.tsx'
import { TransferBookingModal } from '@/components/modals/TransferBookingModal.tsx'
import UpdateCustomerNameModal from '@/components/modals/UpdateCustomerNameModal.tsx'
import UpdateInstructorProfileModal from '@/components/modals/UpdateInstructorProfileModal.tsx'
import { UpdateMeetingTimeModal } from '@/components/modals/UpdateMeetingTimeModal.tsx'
import UpdateOrganizationTrialModal from '@/components/modals/UpdateOrganizationTrialModal.tsx'
import { UpdateOrganizationalBookingModal } from '@/components/modals/UpdateOrganizationalBookingModal.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { createPushModal } from 'pushmodal'
import InstructorAddMeetingsToCalendarModal from './AddMeetingsToCalendarInstructorModal'

export const { pushModal, popModal, popAllModals, ModalProvider } = createPushModal({
  modals: {
    RefundBookingOptionsModal: {
      Component: RefundBookingOptionsModal,
      Wrapper: Dynamic.Wrapper,
    },
    TransferBookingModal: {
      Component: TransferBookingModal,
      Wrapper: Dynamic.Wrapper,
    },
    CustomerRefundBookingOptionsModal: {
      Component: CustomerRefundBookingOptionsModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateMeetingTimeModal: {
      Component: UpdateMeetingTimeModal,
      Wrapper: Dynamic.Wrapper,
    },
    DeleteMeetingConfirmationModal: {
      Component: CancelMeetingModal,
      Wrapper: Dynamic.Wrapper,
    },
    CreateBookingLinkModal: {
      Component: CreateBookingLinkModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateOrganizationalBookingModal: {
      Component: UpdateOrganizationalBookingModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateCustomerName: {
      Component: UpdateCustomerNameModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateInstructorProfileModal: {
      Component: UpdateInstructorProfileModal,
      Wrapper: Dynamic.Wrapper,
    },
    ApproveScheduleBatchModal: {
      Component: ApproveScheduleBatchModal,
      Wrapper: Dynamic.Wrapper,
    },
    CreateScheduleBatchModal: {
      Component: CreateScheduleBatchModal,
      Wrapper: Dynamic.Wrapper,
    },
    InputRebateCodeModal: {
      Component: InputRebateCodeModal,
      Wrapper: Dynamic.Wrapper,
    },
    ArchiveCourseModal: {
      Component: ArchiveCourseModal,
      Wrapper: Dynamic.Wrapper,
    },
    RestoreCourseModal: {
      Component: RestoreCourseModal,
      Wrapper: Dynamic.Wrapper,
    },
    ArchiveMeetingModal: {
      Component: ArchiveMeetingModal,
      Wrapper: Dynamic.Wrapper,
    },
    RestoreMeetingModal: {
      Component: RestoreMeetingModal,
      Wrapper: Dynamic.Wrapper,
    },
    ArchiveCourseGroupModal: {
      Component: ArchiveCourseGroupModal,
      Wrapper: Dynamic.Wrapper,
    },
    RestoreCourseGroupModal: {
      Component: RestoreCourseGroupModal,
      Wrapper: Dynamic.Wrapper,
    },
    ArchiveMultipassProductModal: {
      Component: ArchiveMultipassProductModal,
      Wrapper: Dynamic.Wrapper,
    },
    RestoreMultipassProductModal: {
      Component: RestoreMultipassProductModal,
      Wrapper: Dynamic.Wrapper,
    },
    ArchiveCouponModal: {
      Component: ArchiveCouponModal,
      Wrapper: Dynamic.Wrapper,
    },
    RestoreCouponModal: {
      Component: RestoreCouponModal,
      Wrapper: Dynamic.Wrapper,
    },
    CourseCreatedModal: {
      Component: CourseCreatedModal,
      Wrapper: Dynamic.Wrapper,
    },
    AddMeetingsToCalendarModal: {
      Component: AddMeetingsToCalendarModal,
      Wrapper: Dynamic.Wrapper,
    },
    BackofficeAddMeetingsToCalendarModal: {
      Component: BackofficeAddMeetingsToCalendarModal,
      Wrapper: Dynamic.Wrapper,
    },
    InstructorAddMeetingsToCalendarModal: {
      Component: InstructorAddMeetingsToCalendarModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateOrganizationTrialModal: {
      Component: UpdateOrganizationTrialModal,
      Wrapper: Dynamic.Wrapper,
    },
    MediaImagePickerModal: {
      Component: MediaImagePickerModal,
      Wrapper: Dynamic.Wrapper,
    },
    MediaImageContentReviewModal: {
      Component: MediaImageContentReviewModal,
      Wrapper: Dynamic.Wrapper,
    },
    BookingPageCourseDetailsModal: {
      Component: BookingPageCourseDetailsModal,
      Wrapper: Dynamic.Wrapper,
    },
  },
})
