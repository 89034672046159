import useGetCoupon from '@/hooks/coupons/useGetCoupon.tsx'
import useGetCourseGroup from '@/hooks/courseGroups/useGetCourseGroup.tsx'
import useGetCourseGroupMeetings from '@/hooks/courseGroups/useGetCourseGroupMeetings.tsx'
import useGetCourse from '@/hooks/courses/useGetCourse.tsx'
import useGetInstructor from '@/hooks/instructors/useGetInstructor.tsx'
import useReverseGeocoding from '@/hooks/locations/useReverseGeocoding.tsx'
import useGetMeeting from '@/hooks/meetings/useGetMeeting.tsx'
import useGetMultipassProduct from '@/hooks/multipasses/useGetMultipassProduct.tsx'
import useGetPaymentProfile from '@/hooks/organization/useGetPaymentProfile.tsx'
import useGetCouponPublic from '@/hooks/public/useGetCouponPublic.tsx'
import useGetCourseGroupPublic from '@/hooks/public/useGetCourseGroupPublic.tsx'
import useGetCoursePublic from '@/hooks/public/useGetCoursePublic.tsx'
import useGetLocationPublic from '@/hooks/public/useGetLocationPublic.tsx'
import useGetMeetingPublic from '@/hooks/public/useGetMeetingPublic.tsx'
import useGetMultipassProductPublic from '@/hooks/public/useGetMultipassProductPublic.tsx'
import useGetOrganizationPublic from '@/hooks/public/useGetOrganizationPublic.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import {
  BookingEntity,
  RebateCodeEntity,
  StripePaymentProfileEntity,
} from '../../qourses-api-client'

export function CourseName({ courseId }: { courseId: string }) {
  const { course, isError, isLoading } = useGetCourse(courseId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{course.name}</>
}

export function CourseGroupName({ courseGroupId }: { courseGroupId: string }) {
  const { courseGroup, isError, isLoading } = useGetCourseGroup(courseGroupId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{courseGroup.name}</>
}

export function CourseNameWithMeetingStart({ meetingId }: { meetingId: string }) {
  const { meeting, isError, isLoading } = useGetMeeting(meetingId)

  if (isLoading) {
    return (
      <div className="space-y-2">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-3 w-24" />
      </div>
    )
  }

  if (isError) return null

  return (
    <div className="text-sm">
      <CourseName courseId={meeting.courseId} />
      <p className="text-xs text-muted-foreground">
        {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED)}
      </p>
    </div>
  )
}

export function BookingCourseGroupName({ booking }: { booking: BookingEntity }) {
  const { courseGroup, isError, isLoading } = useGetCourseGroup(booking.courseGroupId)
  const { meetings } = useGetCourseGroupMeetings(booking.courseGroupId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  const startAndEndDates = meetings.reduce(
    (acc, meeting) => {
      const start = DateTime.fromISO(meeting.start)
      if (acc.start === null || start < acc.start) {
        acc.start = start
      }
      if (acc.end === null || start > acc.end) {
        acc.end = start
      }
      return acc
    },
    { start: null, end: null },
  )

  return (
    <div
      className={cn(
        'text-sm text-muted-foreground',
        booking.bookingStatus === BookingEntity.bookingStatus.FULFILLED && 'text-gray-800',
      )}
    >
      {courseGroup.name}
      <div className="flex max-w-xs flex-wrap gap-x-1 text-xs text-muted-foreground">
        <p>Kursgruppe</p>
        <div>({DateTime.fromISO(startAndEndDates.start).toLocaleString(DateTime.DATE_SHORT)})</div>
      </div>
    </div>
  )
}

export function MultipassProductNameWithHeader({
  multipassProductId,
}: {
  multipassProductId: string
}) {
  const { t: translate } = useTranslation()

  const { multipassProduct, isError, isLoading } = useGetMultipassProduct(multipassProductId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <div className="text-sm">
      {multipassProduct.name}
      <p className="text-xs text-muted-foreground">
        {translate('common.resolvers.multipass-product-name.titleForEndUser')}
      </p>
    </div>
  )
}

export function CourseNamePublicViaMeetingId({ meetingId }: { meetingId: string }) {
  const { meeting, isError, isLoading } = useGetMeetingPublic(meetingId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <CourseNamePublic courseId={meeting.courseId} />
}

export function MeetingTime({ meetingId }: { meetingId: string }) {
  const { meeting, isError, isLoading } = useGetMeeting(meetingId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <div>
      <p>{DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED)}</p>
    </div>
  )
}

export function MeetingTimePublic({ meetingId }: { meetingId: string }) {
  const { meeting, isError, isLoading } = useGetMeetingPublic(meetingId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <div>
      <p>{DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED)}</p>
    </div>
  )
}

export function OrganizationNamePublic({ organizationId }: { organizationId: string }) {
  const { organization, isError, isLoading } = useGetOrganizationPublic(organizationId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{organization.name}</>
}

export function CourseNamePublic({ courseId }: { courseId: string }) {
  const { course, isError, isLoading } = useGetCoursePublic(courseId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{course.name}</>
}

export function CourseGroupNamePublic({ courseGroupId }: { courseGroupId: string }) {
  const { courseGroup, isError, isLoading } = useGetCourseGroupPublic(courseGroupId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{courseGroup.name}</>
}

// Child component that handles location fetching
function LocationFetcher({ courseId, locationId }: { courseId: string; locationId: string }) {
  const { location, isLoading: locationLoading } = useGetLocationPublic(locationId)

  if (locationLoading) {
    return <span>Loading location...</span>
  }

  if (!location) {
    return null
  }

  return (
    <GeocodeFetcher
      courseId={courseId}
      latitude={location.latitude}
      longitude={location.longitude}
    />
  )
}

// Child component that handles geocoding
function GeocodeFetcher({
  latitude,
  longitude,
}: {
  courseId: string
  latitude: string
  longitude: string
}) {
  const { geocodeResult, isLoading: geocodeResultLoading } = useReverseGeocoding(
    parseFloat(latitude),
    parseFloat(longitude),
  )

  if (geocodeResultLoading) {
    return <span>Loading geocode...</span>
  }

  if (!geocodeResult?.features?.[0]) {
    return null
  }

  return <span>{geocodeResult.features[0].place_name}</span>
}

// Main component that orchestrates the conditional rendering
export function CourseLocationNamePublic({ courseId }: { courseId: string }) {
  const { course, isLoading: courseLoading } = useGetCoursePublic(courseId)

  if (courseLoading) {
    return <span>Loading course...</span>
  }

  if (!course?.locationId) {
    return null
  }

  return <LocationFetcher courseId={courseId} locationId={course.locationId} />
}

export function MultipassProductNamePublic({ multipassProductId }: { multipassProductId: string }) {
  const { multipassProduct, isError, isLoading } = useGetMultipassProductPublic(multipassProductId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{multipassProduct.name}</>
}

export function MultipassProductName({ multipassProductId }: { multipassProductId: string }) {
  const { multipassProduct, isLoading } = useGetMultipassProduct(multipassProductId)

  if (isLoading) {
    return <Skeleton className="h-5 w-3/4" />
  }

  return <p>{multipassProduct.name}</p>
}

export function OrganizationLegalNameOrInstructorLegalNameViaId({
  stripePaymentProfileId,
}: {
  stripePaymentProfileId: string
}) {
  const { paymentProfile, isError, isLoading } = useGetPaymentProfile(stripePaymentProfileId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <OrganizationLegalNameOrInstructorLegalName stripePaymentProfile={paymentProfile} />
}

export function OrganizationLegalNameOrInstructorLegalName({
  stripePaymentProfile,
}: {
  stripePaymentProfile: StripePaymentProfileEntity
}) {
  if (stripePaymentProfile.organizationId) {
    return <OrganizationLegalName organizationId={stripePaymentProfile.organizationId} />
  }

  if (stripePaymentProfile.instructorId) {
    return <InstructorLegalName instructorId={stripePaymentProfile.instructorId} />
  }
}

export function OrganizationLegalName({ organizationId }: { organizationId: string }) {
  const { organization, isLoading } = useGetOrganizationPublic(organizationId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  return <>{organization.companyLegalName ? organization.companyLegalName : organization.name}</>
}

export function InstructorLegalName({ instructorId }: { instructorId: string }) {
  const { instructor, isError, isLoading } = useGetInstructor(instructorId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <>
      {instructor.legalName
        ? instructor.legalName
        : `${instructor.firstName} ${instructor.lastName}`}
    </>
  )
}

export function InstructorName({ instructorId }: { instructorId: string }) {
  const { instructor, isError, isLoading } = useGetInstructor(instructorId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <>
      {instructor.firstName} {instructor.lastName}
    </>
  )
}

export function CouponName({ rebateCodeId }: { rebateCodeId: string }) {
  const { coupon, isError, isLoading } = useGetCoupon(rebateCodeId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{coupon.name}</>
}

export function CouponInternalNote({ rebateCodeId }: { rebateCodeId: string }) {
  const { coupon, isError, isLoading } = useGetCoupon(rebateCodeId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{coupon.internalNote}</>
}

export function CouponValue({ rebateCodeId }: { rebateCodeId: string }) {
  const { coupon, isError, isLoading } = useGetCoupon(rebateCodeId)

  const { t: translate } = useTranslation()

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <>
      {coupon.type === RebateCodeEntity.type.ABSOLUTE && (
        <div className="flex items-center gap-x-1">
          {translate('pages.coupons.rebateAbsolute', {
            amount: coupon.valueInMills / 1000,
          })}
        </div>
      )}
      {coupon.type === RebateCodeEntity.type.PERMILLAGE && (
        <div className="flex items-center gap-x-1">
          {translate('pages.coupons.rebatePermillage', {
            amount: coupon.valueInMills / 10,
          })}
        </div>
      )}
    </>
  )
}

export function CouponValuePublic({ rebateCodeId }: { rebateCodeId: string }) {
  const { coupon, isError, isLoading } = useGetCouponPublic(rebateCodeId)

  const { t: translate } = useTranslation()

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <>
      {coupon.type === RebateCodeEntity.type.ABSOLUTE && (
        <div className="flex items-center gap-x-1">
          {translate('pages.coupons.rebateAbsolute', {
            amount: coupon.valueInMills / 1000,
          })}
        </div>
      )}
      {coupon.type === RebateCodeEntity.type.PERMILLAGE && (
        <div className="flex items-center gap-x-1">
          {translate('pages.coupons.rebatePermillage', {
            amount: coupon.valueInMills / 10,
          })}
        </div>
      )}
    </>
  )
}
