import { MultipassProductNamePublic } from '@/components/Resolvers.tsx'
import useGetEndCustomerMultipassesForOrder from '@/hooks/customer/useGetEndCustomerMultipassesForOrder.tsx'
import useGetOrderBookingsPublic from '@/hooks/public/useGetOrderBookingsPublic.tsx'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultipassEntity } from '../../../qourses-api-client'

export function CustomerOrderMultipassSelection(props: {
  selectedMultipass: MultipassEntity
  setSelectedMultipass: Dispatch<SetStateAction<MultipassEntity>>
  disabled: boolean
  orderId: string
  loading?: boolean
  preselectedMultipassId?: string
}) {
  const { t: translate } = useTranslation()
  const { multipasses, isLoading, isError } = useGetEndCustomerMultipassesForOrder(props.orderId)
  const { bookings, isError: isLoadingBookings } = useGetOrderBookingsPublic(props.orderId)

  const calculateUsageForOrder = useCallback(
    (multipass: MultipassEntity) => {
      const bookingsForMultipass =
        !isLoadingBookings && bookings.filter((booking) => booking.multipassId === multipass.id)
      return bookingsForMultipass.length
    },
    [isLoadingBookings, bookings],
  )

  const [MultipassJson, setMultipassJson] = useState<string | null>(null)
  const hasPreselected = useRef(false)

  useEffect(() => {
    if (props.preselectedMultipassId && !hasPreselected.current && multipasses.length > 0) {
      const preselectedMultipass = multipasses.find(
        (multipass) => multipass.id === props.preselectedMultipassId,
      )
      if (preselectedMultipass) {
        const preselectedMultipassJson = JSON.stringify({
          value: preselectedMultipass,
          label: preselectedMultipass.multipassProductId,
        })
        setMultipassJson(preselectedMultipassJson)
        props.setSelectedMultipass(preselectedMultipass)
      }
      hasPreselected.current = true
    }
  }, [props.preselectedMultipassId, multipasses, props.setSelectedMultipass])

  useEffect(() => {
    if (MultipassJson) {
      const Multipass = JSON.parse(MultipassJson).value as MultipassEntity
      props.setSelectedMultipass(Multipass)
    }
  }, [MultipassJson])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue>
            <Skeleton className="h-4 w-24" />
          </SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const mappedMultipass = multipasses.map((mulitpass) => ({
    value: mulitpass,
    label: mulitpass.multipassProductId,
  }))

  return (
    <AnimatePresence>
      <Select
        onValueChange={(value) => setMultipassJson(value)}
        value={MultipassJson ?? undefined}
        disabled={props.disabled ?? false}
      >
        <SelectTrigger className="bg-white text-xs">
          <SelectValue placeholder={translate('dropdowns.customerMultipasses.placeholder')}>
            <div className="flex items-center justify-between gap-x-3">
              <div className="flex flex-col items-start">
                {props.selectedMultipass && (
                  <>
                    <MultipassProductNamePublic
                      multipassProductId={props.selectedMultipass.multipassProductId}
                    />
                    <p className="text-muted-foreground">
                      {calculateUsageForOrder(props.selectedMultipass) === 0
                        ? translate('dropdowns.customerMultipasses.usagesRemaining', {
                            usages:
                              props.selectedMultipass.usagesRemaining -
                              calculateUsageForOrder(props.selectedMultipass),
                          })
                        : translate(
                            'dropdowns.customerMultipasses.usagesRemainingWithOrderDeduction',
                            {
                              usages:
                                props.selectedMultipass.usagesRemaining -
                                calculateUsageForOrder(props.selectedMultipass),
                              usageOrderDeduction: calculateUsageForOrder(props.selectedMultipass),
                            },
                          )}
                    </p>
                  </>
                )}
                {props.selectedMultipass == null && (
                  <div className="flex items-center gap-x-1 justify-center">
                    <p className="text-muted-foreground">
                      {translate('dropdowns.customerMultipasses.removed')}
                    </p>
                    <motion.div
                      key={'loading-multipass-select-no-selection'}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{
                        opacity: 0,
                        x: -10,
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                {props.loading && (
                  <motion.div
                    key={'loading-multipass-select'}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{
                      opacity: 0,
                      x: -10,
                    }}
                  >
                    <Loader2 className="size-5 animate-spin text-muted-foreground" />
                  </motion.div>
                )}
              </div>
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.customerMultipasses.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              mappedMultipass
                .filter((multipass) => !!multipass.label)
                .map((multipass) => (
                  <SelectItem
                    value={JSON.stringify(multipass)}
                    disabled={
                      multipass &&
                      calculateUsageForOrder(multipass.value) >= multipass.value.usagesRemaining
                    }
                  >
                    <div className="flex flex-col text-xs">
                      <MultipassProductNamePublic multipassProductId={multipass.label} />
                      <p className="text-muted-foreground">
                        {translate('dropdowns.customerMultipasses.usagesRemaining', {
                          usages:
                            multipass.value.usagesRemaining -
                            calculateUsageForOrder(multipass.value),
                        })}
                      </p>
                      <p>
                        {translate('dropdowns.customerMultipasses.receivedAt', {
                          date: DateTime.fromISO(multipass.value.createdAt).toLocaleString(
                            DateTime.DATE_MED,
                          ),
                        })}
                      </p>
                    </div>
                  </SelectItem>
                ))}
            <SelectItem
              disabled={props.selectedMultipass == null}
              value={JSON.stringify({
                value: null,
                label: null,
              })}
            >
              <div className="flex gap-x-2">
                <div className="flex flex-col text-xs">
                  <p className="font-semibold">
                    {translate('dropdowns.customerMultipasses.remove')}
                  </p>
                </div>
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </AnimatePresence>
  )
}
