import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { MediaImageEntity } from '../../../qourses-api-client'

export default function useGetMediaImagePublic(imageId: string): {
  mediaImage: MediaImageEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: mediaImage,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(
    getMediaImageQueryKey(imageId),
    async () => {
      return qoursesApi.mediaPublic.mediaPublicControllerGetMediaImageById(imageId)
    },
    {
      enabled: !!imageId,
    },
  )

  return {
    mediaImage: mediaImage,
    isLoading,
    isError,
    refetch,
    isRefetching,
  }
}

export const getMediaImageQueryKey = (imageId: string) => ['public', 'mediaImage', imageId]
