import 'filepond/dist/filepond.min.css'
import convert from 'heic-convert/browser'
import React, { useState } from 'react'
import { FilePond } from 'react-filepond'
import { useTranslation } from 'react-i18next'

import { qoursesApi } from '@/api/qourses.tsx'
import { sendNotification, ToastVariant } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import {
  FilePondInitialFile,
  ProcessServerConfigFunction,
  RevertServerConfigFunction,
} from 'filepond'

interface ProperDropperProps {
  handleProcess?: ProcessServerConfigFunction
  handleRevert?: RevertServerConfigFunction
  processFinishCallback?: () => void
  allowMultiple?: boolean
  maxFiles?: number
  additionalFilePondProps?: Record<string, never>
}

const MediaImageUploadDropzone: React.FC<ProperDropperProps> = ({
  handleProcess: customHandleProcess,
  handleRevert: customHandleRevert,
  allowMultiple = true,
  maxFiles = 10,
  additionalFilePondProps = {},
  processFinishCallback,
}) => {
  const { t: translate } = useTranslation()
  const [files] = useState<(string | FilePondInitialFile | Blob)[]>([])

  const queryClient = useQueryClient()

  // Default handlers that can be overridden by props
  const defaultHandleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    abort,
  ) => {
    try {
      const draftMedia = await qoursesApi.media.mediaControllerCreateMediaImage('false')

      const formData = new FormData()

      const fileIsHEIC = file.type === 'image/heic'

      // detect if we have a HEIC file and convert it to JPEG
      if (fileIsHEIC) {
        const inputBuffer = new Uint8Array(await file.arrayBuffer())

        const outputBuffer = await convert({
          buffer: inputBuffer as unknown as ArrayBuffer,
          format: 'JPEG',
          quality: 1,
        })

        const heicFile = new Blob([outputBuffer], { type: 'image/jpeg' })
        formData.append('file', heicFile, draftMedia.id)
      } else {
        formData.append('file', file, draftMedia.id)
      }

      const uploadedImage = await axios.post(draftMedia.imageUploadUrl, formData, {
        onUploadProgress: (progressEvent) => {
          progress(true, progressEvent.loaded, progressEvent.total)
        },
      })

      if (uploadedImage.status === 200) {
        // Confirm our upload
        await qoursesApi.media.mediaControllerCheckUploadMediaImage(draftMedia.id, 'false')

        sendNotification(
          translate('fileUpload.notificationUploadComplete.title', {
            filename: file.name,
          }),
          translate('fileUpload.notificationUploadComplete.content'),
          ToastVariant.Success,
        )

        queryClient.invalidateQueries(['mediaImages'])

        load(draftMedia.id)

        if (processFinishCallback) {
          processFinishCallback()
        }
      }
    } catch (e) {
      abort()
      console.error(e)
      error('Upload failed')
    }
  }

  const defaultHandleRevert: RevertServerConfigFunction = async (_uniqueFileId, load, error) => {
    try {
      console.log('Reverted!')
      load()
    } catch (e) {
      console.error(e)
      error('Revert failed')
    }
  }

  // Use custom handlers or fall back to defaults
  const handleProcess = customHandleProcess || defaultHandleProcess
  const handleRevert = customHandleRevert || defaultHandleRevert

  return (
    <FilePond
      files={files}
      allowMultiple={allowMultiple}
      maxFiles={maxFiles}
      server={{
        process: handleProcess,
        revert: handleRevert,
      }}
      dropOnPage={true}
      credits={false}
      instantUpload={true}
      maxParallelUploads={10}
      labelIdle={translate('fileUpload.labelIdle')}
      labelInvalidField={translate('fileUpload.labelInvalidField')}
      labelFileWaitingForSize={translate('fileUpload.labelFileWaitingForSize')}
      labelFileSizeNotAvailable={translate('fileUpload.labelFileSizeNotAvailable')}
      labelFileLoading={translate('fileUpload.labelFileLoading')}
      labelFileLoadError={translate('fileUpload.labelFileLoadError')}
      labelFileProcessing={translate('fileUpload.labelFileProcessing')}
      labelFileProcessingComplete={translate('fileUpload.labelFileProcessingComplete')}
      labelFileProcessingAborted={translate('fileUpload.labelFileProcessingAborted')}
      labelFileProcessingError={translate('fileUpload.labelFileProcessingError')}
      labelFileProcessingRevertError={translate('fileUpload.labelFileProcessingRevertError')}
      labelFileRemoveError={translate('fileUpload.labelFileRemoveError')}
      labelTapToCancel={translate('fileUpload.labelTapToCancel')}
      labelTapToRetry={translate('fileUpload.labelTapToRetry')}
      labelTapToUndo={translate('fileUpload.labelTapToUndo')}
      labelButtonRemoveItem={translate('fileUpload.labelButtonRemoveItem')}
      labelButtonAbortItemLoad={translate('fileUpload.labelButtonAbortItemLoad')}
      labelButtonRetryItemLoad={translate('fileUpload.labelButtonRetryItemLoad')}
      labelButtonAbortItemProcessing={translate('fileUpload.labelButtonAbortItemProcessing')}
      labelButtonUndoItemProcessing={translate('fileUpload.labelButtonUndoItemProcessing')}
      labelButtonRetryItemProcessing={translate('fileUpload.labelButtonRetryItemProcessing')}
      labelButtonProcessItem={translate('fileUpload.labelButtonProcessItem')}
      {...additionalFilePondProps}
    />
  )
}

export default MediaImageUploadDropzone
