import { useQuery } from '@tanstack/react-query'
import { OrganizationEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetUserOrganizations(): {
  organizations: OrganizationEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: organizations,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(GetOrganizationsQueryKey(), async () => {
    return qoursesApi.organization.organizationControllerGetOrganizationsForUser()
  })

  let isEmpty = true

  if (organizations === undefined) {
    return { organizations: [], isLoading, isEmpty, isError, refetch, isRefetching }
  }

  if (organizations.length > 0) {
    isEmpty = false
  }

  return { organizations, isLoading, isEmpty, isError, refetch, isRefetching }
}

export const GetOrganizationsQueryKey = () => ['organizations']
