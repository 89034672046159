import { qoursesApi } from '@/api/qourses.tsx'
import NotificationBubble from '@/components/NotificationBubble.tsx'
import { CustomerOrderMultipassSelection } from '@/components/entityDropdowns/CustomerOrderMultipassSelection.tsx'
import { pushModal } from '@/components/modals'
import useGetEndCustomerMultipassesForOrder from '@/hooks/customer/useGetEndCustomerMultipassesForOrder.tsx'
import useGetEndCustomerRebateCodesForOrder, {
  GetEndCustomerRebateCodesForOrderQueryKey,
} from '@/hooks/customer/useGetEndCustomerRebateCodesForOrder.tsx'
import useGetCouponOfBookingPublic from '@/hooks/public/useGetCouponOfBookingPublic.tsx'
import useGetCouponOfOrderPublic from '@/hooks/public/useGetCouponOfOrderPublic.tsx'
import useGetCourseGroupPublic from '@/hooks/public/useGetCourseGroupPublic.tsx'
import useGetMultipassProductPublic from '@/hooks/public/useGetMultipassProductPublic.tsx'
import useGetOrderBookingsPublic from '@/hooks/public/useGetOrderBookingsPublic.tsx'
import useGetOrderPublic from '@/hooks/public/useGetOrderPublic.tsx'
import useGetOrganizationPublic from '@/hooks/public/useGetOrganizationPublic.tsx'
import { removeMeetingIdFromLocalStorage } from '@/pages/public/booking/BookingUtilFunctions.tsx'
import { iframeParam } from '@/pages/public/booking/SessionParams.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/shadcn/components/ui/drawer.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { ToastVariant, classNames, minDelay, sendNotification } from '@/utils.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import NumberFlow from '@number-flow/react'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronDown, Loader2, ShoppingCart, Ticket, Tickets, Undo2, X } from 'lucide-react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { Link, useSearchParams } from 'react-router-dom'
import {
  BookingEntity,
  OrderEntity,
  OrderWithPriceDetailsEntity,
  PublicBookingWithPriceDetailsEntity,
  PublicCourseWithPriceDetailsEntity,
  PublicMultipassProductWithBookingInfoEntity,
  PublicRebateCodeEntity,
} from '../../../../../qourses-api-client'
import useGetMeetingPublic from '../../../../hooks/public/useGetMeetingPublic.tsx'

export function OrderSummary(props: {
  orderId: string
  courseOrMultipass:
    | PublicCourseWithPriceDetailsEntity
    | PublicMultipassProductWithBookingInfoEntity
  email: string
  demoMode: boolean
}) {
  const { order, isError, isLoading } = useGetOrderPublic(props.orderId)

  if (isError) {
    throw new Error('Error while fetching order')
  }

  if (isLoading) return null

  return (
    <OrderSummaryContent
      order={order}
      courseOrMultipass={props.courseOrMultipass}
      email={props.email}
      demoMode={props.demoMode}
    />
  )
}

function OrderSummaryContent(props: {
  order: OrderWithPriceDetailsEntity
  courseOrMultipass:
    | PublicCourseWithPriceDetailsEntity
    | PublicMultipassProductWithBookingInfoEntity
  email: string
  demoMode: boolean
}) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const { organization } = useGetOrganizationPublic(props.order.organizationId)

  const { bookings, isError, refetch, isEmpty, isLoading } = useGetOrderBookingsPublic(
    props.order.id,
  )

  const {
    coupon,
    isLoading: isLoadingCoupons,
    isError: isErrorCoupons,
  } = useGetCouponOfOrderPublic(props.order.id)

  const [isRemovingOrderCoupon, setIsRemovingOrderCoupon] = useState(false)

  const { rebateCodes } = useGetEndCustomerRebateCodesForOrder(
    isAuthenticated ? props.order.id : undefined,
  )

  const [searchParams] = useSearchParams()

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(true)

  const atLeastOnePurchasableBooking = bookings.some(
    (booking) => booking.bookingStatus !== BookingEntity.bookingStatus.WITHDRAWN,
  )

  // Using this we can check if the drawer open button is fully in view (sticky) and toggle some styles
  const { ref, inView } = useInView({
    threshold: 1,
  })

  // Closes the drawer if the last booking is deleted
  useEffect(() => {
    if (isEmpty && mobileDrawerOpen && !isLoading) {
      setMobileDrawerOpen(false)
    }
  }, [isEmpty, bookings])

  const handleUpdateBookedMeetings = async () => {
    refetch()
    if (props.order) {
      await queryClient.invalidateQueries(['public', 'order'])
    }
    if (isEmpty && mobileDrawerOpen) {
      setMobileDrawerOpen(false)
    }
  }

  const handleRemoveCouponFromOrder = async () => {
    try {
      if (isRemovingOrderCoupon || !props.order || !props.order.id) return
      setIsRemovingOrderCoupon(true)
      const couponName = coupon?.name
      const orderId = props.order.id
      await minDelay(
        qoursesApi.paymentPublic.paymentPublicControllerRemoveOrderRebateCode(orderId),
        200,
      )
      await handleUpdateBookedMeetings()
      sendNotification(
        translate('pages.public.booking.summary.couponNotifications.orderCouponRemoved.title'),
        translate('pages.public.booking.summary.couponNotifications.orderCouponRemoved.subtitle', {
          name: couponName,
        }),
        ToastVariant.Success,
      )
      setIsRemovingOrderCoupon(false)
      await queryClient.invalidateQueries(GetEndCustomerRebateCodesForOrderQueryKey(orderId))
    } catch (e) {
      console.error(e)
    }
  }

  const handleRemoveCouponFromBooking = async (bookingId: string, couponName: string) => {
    try {
      if (isRemovingOrderCoupon || !props.order || !props.order.id) return
      setIsRemovingOrderCoupon(true)
      await minDelay(
        qoursesApi.paymentPublic.paymentPublicControllerRemoveBookingRebateCode(
          props.order.id,
          bookingId,
        ),
        200,
      )
      await handleUpdateBookedMeetings()
      sendNotification(
        translate('pages.public.booking.summary.couponNotifications.bookingCouponRemoved.title'),
        translate(
          'pages.public.booking.summary.couponNotifications.bookingCouponRemoved.subtitle',
          {
            name: couponName,
          },
        ),
        ToastVariant.Success,
      )
      setIsRemovingOrderCoupon(false)
      await queryClient.invalidateQueries(GetEndCustomerRebateCodesForOrderQueryKey(props.order.id))
    } catch (e) {
      console.error(e)
    }
  }

  const handleCheckout = async () => {
    let newTab: Window | null
    try {
      const isIframe = !!searchParams.get(iframeParam)
      if (organization.activeCustomer) {
        setCheckoutLoading(true)
        if (isIframe) {
          newTab = window.open('', '_blank')
        }
        const session = await qoursesApi.paymentPublic.paymentPublicControllerCreateOrderCheckout(
          props.order.id,
        )

        if (isIframe && newTab) {
          newTab.window.location.href = session.checkoutUrl
        } else if (!isIframe) {
          window.location.href = session.checkoutUrl
        } else {
          throw new Error('Could not open new tab for checkout')
        }
      }
    } catch (e) {
      console.error(e)
      sendNotification(
        translate('pages.public.booking.summary.checkoutError.title'),
        translate('pages.public.booking.summary.checkoutError.subtitle'),
        ToastVariant.Error,
      )
      Sentry.captureException(e)
      if (newTab) {
        newTab.close()
      }
      setCheckoutLoading(false)
    }
  }

  if (isError) return null

  if (isMobile || props.demoMode) {
    return (
      <Drawer
        shouldScaleBackground={false}
        modal={true}
        disablePreventScroll={false}
        open={mobileDrawerOpen}
        onOpenChange={(open) => {
          setMobileDrawerOpen(open)
        }}
      >
        {!isEmpty && (
          <div
            ref={ref}
            className={classNames(
              inView ? '' : 'rounded-t-xl bg-white/30 ring-1 backdrop-blur-md',
              'sticky bottom-[-1px] z-30 flex w-full flex-1 ring-gray-300 transition-all ',
            )}
          >
            <Button
              onClick={() => {
                setMobileDrawerOpen(true)
              }}
              className="py-auto mx-4 mb-6 mt-6 flex h-12 flex-1 rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 text-center text-lg font-semibold text-white shadow-sm ring-1 ring-indigo-600 md:text-sm"
            >
              <ShoppingCart className="mr-2 h-6 w-6" />
              {translate('pages.public.booking.summary.drawerButton')}
            </Button>
          </div>
        )}
        <DrawerContent className="h-[90%] sm:h-auto focus-visible:ring-transparent">
          <div className="mx-auto w-full max-w-sm overflow-y-auto">
            <DrawerHeader>
              <DrawerTitle>{translate('pages.public.booking.summary.title')} </DrawerTitle>
              <DrawerDescription translate="no">{props.courseOrMultipass?.name}</DrawerDescription>
            </DrawerHeader>
            <div>
              <dl
                className={cn(
                  'relative mb-4 flex max-h-52 flex-wrap overflow-auto rounded-lg',
                  bookings.length > 1 && 'm-2 bg-gray-50 py-2 shadow-inner ring-1 ring-gray-200',
                )}
              >
                {bookings.map((bookedMeeting) =>
                  bookedMeeting.courseGroupId ? (
                    // The booked meeting is a course group meeting
                    <BookedCourseGroupDetails
                      bookedMeeting={bookedMeeting}
                      order={props.order}
                      updateBookedMeetingsCallback={handleUpdateBookedMeetings}
                    />
                  ) : (
                    <>
                      {bookedMeeting.multipassProductId ? (
                        <BookedMultipassDetails
                          key={bookedMeeting.id}
                          booking={bookedMeeting}
                          order={props.order}
                          updateBookedMeetingsCallback={handleUpdateBookedMeetings}
                        />
                      ) : (
                        <BookedMeetingDetails
                          key={bookedMeeting.id}
                          bookedMeeting={bookedMeeting}
                          order={props.order}
                          updateBookedMeetingsCallback={handleUpdateBookedMeetings}
                        />
                      )}
                    </>
                  ),
                )}
                {bookings.length > 1 && (
                  <div className="sticky -bottom-2 h-12 w-full bg-gradient-to-t from-white to-transparent" />
                )}
              </dl>
              <dl className="mt-4 border-t-2 border-dashed px-8">
                <div className="flex justify-end">
                  <Button
                    className="relative mt-4 mb-2 bg-gray-50 border bg-gradient-to-b from-gray-50 to-gray-100 text-gray-800"
                    variant="ghost"
                    onClick={() => {
                      pushModal('InputRebateCodeModal', {
                        orderId: props.order.id,
                        guestId: props.order.guestId ?? undefined,
                        isAuthenticated: isAuthenticated,
                        loginWithRedirect: loginWithRedirect,
                      })
                    }}
                  >
                    {translate('pages.public.booking.summary.applyCoupon')}
                    <Tickets className="ml-2 h-4 w-4" />
                    {rebateCodes && rebateCodes.length > 0 && (
                      <div className="absolute -right-4 -top-2">
                        <NotificationBubble
                          notification={rebateCodes.length.toString()}
                          layoutId={'rebateCodeNotification'}
                        />
                      </div>
                    )}
                  </Button>
                </div>
                <div className="flex justify-between pb-2">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    {translate('pages.public.booking.summary.price')}
                    {props.order.priceRebateCalculation.totalAfterRebate.taxesInMills > 0 && (
                      <div className="text-xs font-normal text-gray-500">inkl. MwSt.</div>
                    )}
                  </dt>
                  <div>
                    <dd className="mt-1 text-sm font-semibold text-gray-800">
                      {translate('common.currency.EUR', {
                        val:
                          props.order.priceRebateCalculation.totalAfterRebate.totalPriceInMills /
                          1000,
                        minimumFractionDigits: 2,
                      })}
                    </dd>
                  </div>
                </div>
                {!isLoadingCoupons &&
                  !isErrorCoupons &&
                  (coupon || bookings.some((booking) => booking.rebateCodeId)) && (
                    <div className=" mb-3 gap-y-2 flex flex-col mt-2 pb-3 border-dashed border-t-2 pt-2">
                      <p className="text-sm font-semibold">
                        {translate('pages.public.booking.summary.applied-discounts')}
                      </p>
                      {bookings.map((bookedMeeting) => (
                        <>
                          {bookedMeeting.rebateCodeId && (
                            <OrderSummaryRebateDisplay
                              orderId={props.order.id}
                              bookingId={bookedMeeting.id}
                              handleRemovalCallback={handleRemoveCouponFromBooking}
                            />
                          )}
                        </>
                      ))}
                      {coupon && (
                        <div>
                          <div className="flex justify-between items-center">
                            <dt className="text-sm text-muted-foreground leading-6 text-gray-900 flex gap-x-1 items-center max-w-[200px] ">
                              <Tickets className="h-4 w-4" />
                              <p className="truncate">{coupon.name}</p>
                            </dt>
                            <div>
                              <dd className="text-sm font-semibold">
                                {coupon.type === PublicRebateCodeEntity.type.PERMILLAGE && (
                                  <>-{coupon.valueInMills / 10} %</>
                                )}
                                {coupon.type === PublicRebateCodeEntity.type.ABSOLUTE && (
                                  <>
                                    -
                                    {translate('common.currency.EUR', {
                                      val: coupon.valueInMills / 1000,
                                    })}
                                  </>
                                )}
                              </dd>
                            </div>
                          </div>
                          <div
                            className="flex gap-x-1 cursor-pointer hover:gap-x-2 transition-all hover:text-red-500 text-gray-800"
                            onClick={handleRemoveCouponFromOrder}
                          >
                            <Undo2 className="h-4 w-4 " />
                            <p className="text-xs">
                              {translate('pages.public.booking.summary.removeCoupon')}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
              </dl>
            </div>
            <DrawerFooter>
              <div className="flex flex-col justify-center gap-y-4 px-3">
                {!checkoutLoading ? (
                  <Button
                    disabled={!organization.activeCustomer || !atLeastOnePurchasableBooking}
                    className="bezel py-auto text-md relative h-10 w-full overflow-hidden rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 px-3 py-2 text-center font-semibold text-white shadow-sm ring-1 ring-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                    onClick={() => {
                      handleCheckout()
                    }}
                  >
                    <motion.div
                      initial={{
                        rotate: 45,
                      }}
                      animate={{
                        rotate: 45,
                        x: [-200, 200],
                        opacity: [0.4, 0.8, 0.4],
                      }}
                      transition={{
                        repeat: Infinity,
                        duration: 1.5,
                        repeatDelay: 0.5,
                      }}
                      className="z-100 absolute h-40 w-20 bg-white/0 bg-gradient-to-r from-white/0 via-white/30 to-white/0"
                    />
                    <span>{translate('pages.public.booking.summary.button')}</span>
                  </Button>
                ) : (
                  <Button
                    disabled
                    className="py-auto text-md h-10 w-full rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 px-3 py-2 text-center font-semibold text-white shadow-sm ring-1 ring-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                  >
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {translate('pages.public.booking.summary.button-loading')}
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setMobileDrawerOpen(false)
                  }}
                  variant="secondary"
                  className="w-full"
                >
                  {translate('pages.public.booking.summary.button-close-drawer')}
                  <ChevronDown className="ml-2 h-4 w-4" />
                </Button>
                <div>
                  {/*TODO: Add links to TOS and Privacy Policy (or modal)*/}
                  <div className="pb-2 text-xs text-gray-900 sm:text-xs">
                    {translate('pages.public.booking.summary.booking-mail-reminder')}{' '}
                    <p className="inline-flex font-semibold">{props.email}</p>
                  </div>
                  <LegalDisclaimer order={props.order} />
                </div>
              </div>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <AnimatePresence>
      {!isEmpty && (
        <div
          id={'order-summary'}
          className="bottom-0 top-10 z-10 row-end-4 -m-4 p-4 sm:m-0 sm:p-0 lg:sticky lg:col-start-3 lg:row-end-3"
        >
          <div className="rounded-lg bg-white shadow-xl ring-4 ring-gray-200">
            <dl className="flex flex-wrap">
              <div className="flex flex-auto items-center justify-evenly pb-2 pl-6 pt-6 md:flex-col md:items-start">
                <dt className="text-md flex flex-1 font-semibold leading-6 text-gray-900">
                  {translate('pages.public.booking.summary.title')}
                </dt>
                <dd className="text-sm font-normal leading-6 text-gray-600" translate="no">
                  {props.courseOrMultipass?.name}
                </dd>
              </div>
              <>
                {bookings.map((bookedMeeting) =>
                  bookedMeeting.courseGroupId ? (
                    // The booked meeting is a course group meeting
                    <BookedCourseGroupDetails
                      bookedMeeting={bookedMeeting}
                      order={props.order}
                      updateBookedMeetingsCallback={handleUpdateBookedMeetings}
                    />
                  ) : (
                    <>
                      {bookedMeeting.multipassProductId ? (
                        <BookedMultipassDetails
                          key={bookedMeeting.id}
                          booking={bookedMeeting}
                          order={props.order}
                          updateBookedMeetingsCallback={handleUpdateBookedMeetings}
                        />
                      ) : (
                        <BookedMeetingDetails
                          key={bookedMeeting.id}
                          bookedMeeting={bookedMeeting}
                          order={props.order}
                          updateBookedMeetingsCallback={handleUpdateBookedMeetings}
                        />
                      )}
                    </>
                  ),
                )}
              </>
            </dl>
            <dl className="mt-2 px-8">
              <div className="flex justify-end">
                <Button
                  className="relative mt-4 mb-2 bg-gray-50 border bg-gradient-to-b from-gray-50 to-gray-100 text-gray-800"
                  variant="ghost"
                  onClick={() => {
                    pushModal('InputRebateCodeModal', {
                      orderId: props.order.id,
                      guestId: props.order.guestId ?? undefined,
                      isAuthenticated: isAuthenticated,
                      loginWithRedirect: loginWithRedirect,
                    })
                  }}
                >
                  {translate('pages.public.booking.summary.applyCoupon')}
                  <Tickets className="ml-2 h-4 w-4" />
                  {rebateCodes && rebateCodes.length > 0 && (
                    <div className="absolute -right-4 -top-2">
                      <NotificationBubble
                        notification={rebateCodes.length.toString()}
                        layoutId={'rebateCodeNotification'}
                      />
                    </div>
                  )}
                </Button>
              </div>
              <div
                className={cn(
                  'flex justify-between pt-2 pb-2',
                  props.order.priceRebateCalculation.partialOnlyRebate.totalPriceInMills > 0 &&
                    'border-b-2 border-dashed ',
                )}
              >
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  {translate('pages.public.booking.summary.price')}
                  {props.order.priceRebateCalculation.totalAfterRebate.taxesInMills > 0 && (
                    <div className="text-xs font-normal text-gray-500">inkl. MwSt.</div>
                  )}
                </dt>
                <div>
                  <dd className="mt-1 text-sm font-semibold text-gray-800">
                    <NumberFlow
                      value={
                        props.order.priceRebateCalculation.totalAfterRebate.totalPriceInMills / 1000
                      }
                      id={'order-price-in-mills'}
                      format={{
                        style: 'currency',
                        currency: 'EUR',
                      }}
                    />
                  </dd>
                </div>
              </div>
              {!isLoadingCoupons &&
                !isErrorCoupons &&
                (coupon || bookings.some((booking) => booking.rebateCodeId)) && (
                  <div className=" mb-3 gap-y-2 flex flex-col mt-2 pb-3">
                    <p className="text-sm font-semibold">Angewendete Rabatte</p>
                    {bookings.map((bookedMeeting) => (
                      <>
                        {bookedMeeting.rebateCodeId && (
                          <OrderSummaryRebateDisplay
                            orderId={props.order.id}
                            bookingId={bookedMeeting.id}
                            handleRemovalCallback={handleRemoveCouponFromBooking}
                          />
                        )}
                      </>
                    ))}
                    {coupon && (
                      <div>
                        <div className="flex justify-between items-center">
                          <dt className="text-sm text-muted-foreground leading-6 text-gray-900 flex gap-x-1 items-center max-w-[200px] ">
                            <Tickets className="h-4 w-4" />
                            <p className="truncate">{coupon.name}</p>
                          </dt>
                          <div>
                            <dd className="text-sm font-semibold">
                              {coupon.type === PublicRebateCodeEntity.type.PERMILLAGE && (
                                <>-{coupon.valueInMills / 10} %</>
                              )}
                              {coupon.type === PublicRebateCodeEntity.type.ABSOLUTE && (
                                <>
                                  -
                                  {translate('common.currency.EUR', {
                                    val: coupon.valueInMills / 1000,
                                  })}
                                </>
                              )}
                            </dd>
                          </div>
                        </div>
                        <div
                          className="flex gap-x-1 cursor-pointer hover:gap-x-2 transition-all hover:text-red-500 text-gray-800"
                          onClick={handleRemoveCouponFromOrder}
                        >
                          <Undo2 className="h-4 w-4 " />
                          <p className="text-xs">
                            {translate('pages.public.booking.summary.removeCoupon')}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </dl>
            <div className="mt-2 px-6 pb-6">
              {!checkoutLoading ? (
                <Button
                  disabled={!organization.activeCustomer || !atLeastOnePurchasableBooking}
                  className="bezel py-auto text-md relative h-10 w-full overflow-hidden rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 px-3 py-2 text-center font-semibold text-white shadow-sm ring-1 ring-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                  onClick={() => {
                    handleCheckout()
                  }}
                >
                  <motion.div
                    initial={{
                      rotate: 45,
                    }}
                    animate={{
                      rotate: 45,
                      x: [-200, 200],
                      opacity: [0.4, 0.8, 0.4],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 1.5,
                      repeatDelay: 0.5,
                    }}
                    className="z-100 absolute h-40 w-20 bg-white/0 bg-gradient-to-r from-white/0 via-white/30 to-white/0"
                  />
                  <span>{translate('pages.public.booking.summary.button')}</span>
                </Button>
              ) : (
                <Button
                  disabled
                  className="py-auto text-md h-10 w-full rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 px-3 py-2 text-center font-semibold text-white shadow-sm ring-1 ring-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                >
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {translate('pages.public.booking.summary.button-loading')}
                </Button>
              )}
              <div className="pb-2 pt-3 text-sm text-gray-900 sm:text-xs">
                {translate('pages.public.booking.summary.booking-mail-reminder')}{' '}
                <p className="inline-flex font-semibold">{props.email}</p>
              </div>
              <LegalDisclaimer order={props.order} />
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  )
}

function LegalDisclaimer(props: { order: OrderEntity }) {
  const { t: translate } = useTranslation()
  return (
    <div className="mt-2 text-xs text-gray-600">
      {translate('pages.public.booking.summary.legal-explainer.preface')}
      <ul className="ml-4 mt-1 list-disc space-y-1">
        {props.order.termsOfServiceId && (
          <li>
            <Link
              target="_blank"
              to={'/book/legal/tos/' + props.order.termsOfServiceId}
              className="text-indigo-600 underline"
            >
              {translate('pages.public.booking.summary.legal-explainer.terms')}
            </Link>
          </li>
        )}
        {props.order.revocationPolicyId && (
          <li>
            <Link
              target="_blank"
              to={'/book/legal/revocationPolicy/' + props.order.revocationPolicyId}
              className="text-indigo-600 underline"
            >
              {translate('pages.public.booking.summary.legal-explainer.revocation')}
            </Link>
          </li>
        )}
        {/*<li>
          <Link to="/book/legal/privacy-policy" className="text-indigo-600 underline">
            {translate('pages.public.booking.summary.legal-explainer.privacy')}
          </Link>
        </li>*/}
      </ul>
    </div>
  )
}

function BookedMeetingDetails(props: {
  bookedMeeting: PublicBookingWithPriceDetailsEntity
  order: OrderWithPriceDetailsEntity
  updateBookedMeetingsCallback: () => void
}) {
  const { meeting, isError, isLoading } = useGetMeetingPublic(props.bookedMeeting.meetingId)

  const { isAuthenticated } = useAuth0()

  const [countdownCompleted, setCountdownCompleted] = useState(false)

  const { multipasses: availableMultipassesForOrder, isLoading: isLoadingAvailableMultipasses } =
    useGetEndCustomerMultipassesForOrder(
      props.order.customerId && isAuthenticated ? props.order.id : undefined,
    )

  const orderExpiration = DateTime.fromISO(props.bookedMeeting.exclusiveUntil).toJSDate().getTime()

  const { t: translate } = useTranslation()

  const [isDeleting, setIsDeleting] = useState(false)

  if (isLoading || isError)
    return (
      <div className="mt-3 flex w-full flex-none gap-x-4 px-6" key={props.bookedMeeting.id}>
        <div className="-m-2 my-auto block h-min rounded-lg text-gray-500">
          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
        </div>
        <div className="group flex flex-1 items-center truncate rounded-lg bg-gray-50/50 px-2 transition-colors">
          <div className="flex flex-1 flex-col gap-y-1 truncate py-2 text-sm">
            <div className="font-medium text-gray-900">
              <Skeleton height={20} className="w-2/3" />
            </div>
            <div className="flex justify-between gap-x-2 text-gray-500">
              <div>
                <Skeleton height={20} width={100} />
              </div>

              <Skeleton height={20} width={60} />
            </div>
          </div>
        </div>
      </div>
    )

  const handleDeleteBooking = async () => {
    setIsDeleting(true)
    try {
      const deletedBooking = await minDelay(
        qoursesApi.paymentPublic.paymentPublicControllerDeleteOrderBooking(
          props.order.id,
          props.bookedMeeting.id,
        ),
        500,
      )
      removeMeetingIdFromLocalStorage(deletedBooking.meetingId)
      // notify parent to update the booked meetings again
      props.updateBookedMeetingsCallback()
    } catch (e) {
      console.error(e)
    } finally {
      setIsDeleting(false)
    }
  }

  const isWithdrawn = props.bookedMeeting.bookingStatus === BookingEntity.bookingStatus.WITHDRAWN

  return (
    <motion.div
      initial={{
        opacity: 0,
        translateY: 10,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      exit={{
        opacity: 0,
        translateY: 10,
      }}
      data-withdrawn={isWithdrawn}
      className="relative mt-3 flex w-full flex-none gap-x-4 px-6 data-[withdrawn=true]:opacity-40"
      key={props.bookedMeeting.id}
    >
      {!isWithdrawn ? (
        <button
          onClick={() => {
            handleDeleteBooking()
          }}
          className="absolute -top-1.5 right-[26px] -m-2 my-auto h-min rounded-lg text-gray-500 transition-all hover:text-indigo-600"
        >
          {isDeleting ? <Loader2 className="size-4 animate-spin" /> : <X className="size-4" />}
        </button>
      ) : (
        <div className="absolute -top-1.5 right-7 -m-2 my-auto h-min rounded-lg text-gray-500 transition-all hover:text-indigo-600">
          <X className="size-4" />
        </div>
      )}
      <div className="transition-color group flex flex-1 items-center truncate rounded-lg sm:bg-gray-50/50 p-3 sm:border sm:border-gray-100">
        <div className="flex flex-1 flex-col gap-y-1 truncate text-sm">
          {!isWithdrawn && (
            <div className="flex flex-col space-y-1.5">
              {!countdownCompleted ? (
                <Countdown
                  onComplete={() => {
                    setCountdownCompleted(true)
                  }}
                  date={orderExpiration}
                  zeroPadTime={2}
                  renderer={(props) => (
                    <div className="text-xs text-muted-foreground">
                      <span>{translate('pages.public.booking.summary.reserved')}</span>
                      <span className="ml-1 text-xs font-medium tabular-nums text-gray-500">
                        {props.minutes}
                        <span>:</span>
                        {props.seconds < 10 ? <span>0</span> : null}
                        {props.seconds}
                      </span>
                    </div>
                  )}
                />
              ) : (
                <p className="text-xs font-normal text-muted-foreground">
                  {translate('pages.public.booking.summary.reserved-expired')}
                </p>
              )}
            </div>
          )}
          <div
            data-withdrawn={isWithdrawn}
            className="font-medium text-gray-900 data-[withdrawn=true]:line-through"
          >
            {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATE_HUGE)}
          </div>
          <div
            data-withdrawn={isWithdrawn}
            className="flex justify-between gap-x-2 text-gray-500 data-[withdrawn=true]:line-through"
          >
            <div>
              {DateTime.fromISO(meeting.start).toLocaleString(DateTime.TIME_SIMPLE)}-
              {DateTime.fromISO(meeting.end).toLocaleString(DateTime.TIME_SIMPLE)}
            </div>
            <div className="font-medium text-gray-800">
              {props.bookedMeeting.priceRebateCalculation.partialOnlyRebate.totalPriceInMills >
                0 && (
                <span className="line-through">
                  {translate('common.currency.EUR', {
                    val:
                      props.bookedMeeting.priceRebateCalculation.totalBeforeRebate
                        .totalPriceInMills / 1000,
                    minimumFractionDigits: 2,
                  })}
                </span>
              )}
              <span className="ml-2">
                {translate('common.currency.EUR', {
                  val:
                    props.bookedMeeting.priceRebateCalculation.totalAfterRebate.totalPriceInMills /
                    1000,
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
          {props.bookedMeeting.rebateCodeId && (
            <div className="flex justify-end">
              <BookingRebateBadge booking={props.bookedMeeting} orderId={props.order.id} />
            </div>
          )}
          {!isLoadingAvailableMultipasses &&
            props.order.customerId &&
            availableMultipassesForOrder &&
            availableMultipassesForOrder.length > 0 &&
            isAuthenticated &&
            !isWithdrawn && (
              <div className="mt-2">
                <MultipassBadge booking={props.bookedMeeting} order={props.order} />
              </div>
            )}
          {isWithdrawn && (
            <div className="mt-2 flex whitespace-break-spaces text-xs text-gray-800 after:opacity-100">
              {' '}
              {translate('pages.public.booking.summary.booking-withdrawn-hint')}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

function BookingRebateBadge(props: {
  booking: PublicBookingWithPriceDetailsEntity
  orderId: string
}) {
  const {
    coupon,
    isLoading: isLoadingCoupon,
    isError: isErrorCoupon,
  } = useGetCouponOfBookingPublic(props.orderId, props.booking.id)

  if (isLoadingCoupon) {
    return (
      <div className="flex gap-x-2 items-center text-xs text-gray-800">
        <Skeleton height={20} width={100} />
      </div>
    )
  }

  if (isErrorCoupon || !coupon) {
    return null
  }

  return (
    <div className="flex">
      <Badge variant={'indigoBezel'} className="flex gap-x-1">
        <Tickets className="h-4 w-4" />
        {coupon.name}
      </Badge>
    </div>
  )
}

function OrderSummaryRebateDisplay(props: {
  bookingId: string
  orderId: string
  handleRemovalCallback: (bookingId: string, couponName: string) => void
}) {
  const {
    coupon,
    isLoading: isLoadingCoupon,
    isError: isErrorCoupon,
  } = useGetCouponOfBookingPublic(props.orderId, props.bookingId)

  const { t: translate } = useTranslation()

  if (isLoadingCoupon) {
    return (
      <div className="flex gap-x-2 items-center text-xs text-gray-800">
        <Skeleton height={20} width={100} />
      </div>
    )
  }

  if (isErrorCoupon || !coupon) {
    return null
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <dt className="text-sm text-muted-foreground leading-6 text-gray-900 flex gap-x-1 items-center max-w-[200px] ">
          <Tickets className="h-4 w-4" />
          <p className="truncate">{coupon.name}</p>
        </dt>
        <div>
          <dd className="text-sm font-semibold">
            {coupon.type === PublicRebateCodeEntity.type.PERMILLAGE && (
              <>-{coupon.valueInMills / 10} %</>
            )}
            {coupon.type === PublicRebateCodeEntity.type.ABSOLUTE && (
              <>-{translate('common.currency.EUR', { val: coupon.valueInMills / 1000 })}</>
            )}
          </dd>
        </div>
      </div>
      <div
        className="flex gap-x-1 cursor-pointer hover:gap-x-2 transition-all hover:text-red-500 text-gray-800"
        onClick={() => {
          props.handleRemovalCallback(props.bookingId, coupon.name)
        }}
      >
        <Undo2 className="h-4 w-4 " />
        <p className="text-xs">{translate('pages.public.booking.summary.removeCoupon')}</p>
      </div>
    </div>
  )
}

function BookedCourseGroupDetails(props: {
  bookedMeeting: PublicBookingWithPriceDetailsEntity
  order: OrderWithPriceDetailsEntity
  updateBookedMeetingsCallback: () => void
}) {
  const {
    courseGroup,
    isLoading: loadingCourseGroup,
    isError: errorCourseGroup,
  } = useGetCourseGroupPublic(props.bookedMeeting.courseGroupId)

  const [countdownCompleted, setCountdownCompleted] = useState(false)

  const orderExpiration = DateTime.fromISO(props.bookedMeeting.exclusiveUntil).toJSDate().getTime()

  const { t: translate } = useTranslation()
  const [isDeleting, setIsDeleting] = useState(false)

  if (loadingCourseGroup || errorCourseGroup) {
    return (
      <div className="mt-3 flex w-full flex-none gap-x-4 px-6" key={props.bookedMeeting.id}>
        <div className="-m-2 my-auto block h-min rounded-lg text-gray-500">
          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
        </div>
        <div className="group flex flex-1 items-center truncate rounded-lg bg-gray-50/50 px-2 transition-colors">
          <div className="flex flex-1 flex-col gap-y-1 truncate py-2 text-sm">
            <div className="font-medium text-gray-900">
              <Skeleton height={20} className="w-2/3" />
            </div>
            <div className="flex justify-between gap-x-2 text-gray-500">
              <div>
                <Skeleton height={20} width={100} />
              </div>

              <Skeleton height={20} width={60} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleDeleteBooking = async () => {
    setIsDeleting(true)
    try {
      const deletedBooking = await minDelay(
        qoursesApi.paymentPublic.paymentPublicControllerDeleteOrderBooking(
          props.order.id,
          props.bookedMeeting.id,
        ),
        1000,
      )
      removeMeetingIdFromLocalStorage(deletedBooking.meetingId)
      // notify parent to update the booked meetings again
      props.updateBookedMeetingsCallback()
    } catch (e) {
      console.error(e)
    } finally {
      setIsDeleting(false)
    }
  }

  const isWithdrawn = props.bookedMeeting.bookingStatus === BookingEntity.bookingStatus.WITHDRAWN

  return (
    <div
      data-withdrawn={isWithdrawn}
      className="relative mt-3 flex w-full flex-none gap-x-4 px-6 data-[withdrawn=true]:opacity-40"
      key={props.bookedMeeting.id}
    >
      {!isWithdrawn ? (
        <button
          onClick={() => {
            handleDeleteBooking()
          }}
          className="absolute -top-1.5 right-7 -m-2 my-auto h-min rounded-lg text-gray-500 transition-all hover:text-indigo-600"
        >
          {isDeleting ? <Loader2 className="size-4 animate-spin" /> : <X className="size-4" />}
        </button>
      ) : (
        <div className="absolute -top-1.5 right-7 -m-2 my-auto h-min rounded-lg text-gray-500 transition-all hover:text-indigo-600">
          <X className="size-4" />
        </div>
      )}
      <div className="transition-color group flex flex-1 items-center truncate rounded-lg sm:bg-gray-50/50 p-3 sm:border sm:border-gray-100">
        <div className="flex flex-1 flex-col gap-y-1 truncate py-2 text-sm">
          {!isWithdrawn && (
            <div className="flex flex-col space-y-1.5">
              {!countdownCompleted ? (
                <Countdown
                  onComplete={() => {
                    setCountdownCompleted(true)
                  }}
                  date={orderExpiration}
                  zeroPadTime={2}
                  renderer={(props) => (
                    <div className="text-xs text-muted-foreground">
                      <span>{translate('pages.public.booking.summary.reserved')}</span>
                      <span className="ml-1 text-xs font-medium tabular-nums text-gray-500">
                        {props.minutes}
                        <span>:</span>
                        {props.seconds < 10 ? <span>0</span> : null}
                        {props.seconds}
                      </span>
                    </div>
                  )}
                />
              ) : (
                <p className="text-xs font-normal text-muted-foreground">
                  {translate('pages.public.booking.summary.reserved-expired')}
                </p>
              )}
            </div>
          )}
          {isWithdrawn && (
            <div className="mt-2 flex whitespace-break-spaces text-xs text-gray-800 after:opacity-100">
              {translate('pages.public.booking.summary.booking-withdrawn-hint')}
            </div>
          )}
          <div
            data-withdrawn={isWithdrawn}
            className="font-medium text-gray-900 data-[withdrawn=true]:line-through"
            translate="no"
          >
            {courseGroup.name}
          </div>
          <div
            data-withdrawn={isWithdrawn}
            className="flex justify-between gap-x-2 text-gray-500 data-[withdrawn=true]:line-through"
          >
            <div className="mb-2 flex items-center text-xs text-gray-600">
              <p>{translate('pages.public.booking.courseGroups.dialog.trigger')}</p>
            </div>
            <div className="font-medium text-gray-800">
              {props.bookedMeeting.priceRebateCalculation.partialOnlyRebate.totalPriceInMills >
                0 && (
                <span className="line-through">
                  {translate('common.currency.EUR', {
                    val:
                      props.bookedMeeting.priceRebateCalculation.totalBeforeRebate
                        .totalPriceInMills / 1000,
                    minimumFractionDigits: 2,
                  })}
                </span>
              )}
              <span className="ml-2">
                {translate('common.currency.EUR', {
                  val:
                    props.bookedMeeting.priceRebateCalculation.totalAfterRebate.totalPriceInMills /
                    1000,
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
          {props.bookedMeeting.rebateCodeId && (
            <div className="flex justify-end">
              <BookingRebateBadge booking={props.bookedMeeting} orderId={props.order.id} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function BookedMultipassDetails(props: {
  booking: PublicBookingWithPriceDetailsEntity
  order: OrderWithPriceDetailsEntity
  updateBookedMeetingsCallback: () => void
}) {
  const { t: translate } = useTranslation()

  const { multipassProduct, isLoading, isError } = useGetMultipassProductPublic(
    props.booking.multipassProductId,
  )

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteBooking = async () => {
    setIsDeleting(true)
    try {
      const deletedBooking = await minDelay(
        qoursesApi.paymentPublic.paymentPublicControllerDeleteOrderBooking(
          props.order.id,
          props.booking.id,
        ),
        500,
      )
      removeMeetingIdFromLocalStorage(deletedBooking.meetingId)
      // notify parent to update the booked meetings again
      props.updateBookedMeetingsCallback()
    } catch (e) {
      console.error(e)
    } finally {
      setIsDeleting(false)
    }
  }

  if (isLoading || isError) {
    return (
      <div className="mt-3 flex w-full flex-none gap-x-4 px-6" key={props.booking.id}>
        <div className="-m-2 my-auto block h-min rounded-lg text-gray-500">
          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
        </div>
        <div className="group flex flex-1 items-center truncate rounded-lg bg-gray-50/50 px-2 transition-colors">
          <div className="flex flex-1 flex-col gap-y-1 truncate py-2 text-sm">
            <div className="font-medium text-gray-900">
              <Skeleton height={20} className="w-2/3" />
            </div>
            <div className="flex justify-between gap-x-2 text-gray-500">
              <div>
                <Skeleton height={20} width={100} />
              </div>
              <Skeleton height={20} width={60} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isWithdrawn = props.booking.bookingStatus === BookingEntity.bookingStatus.WITHDRAWN

  return (
    <div
      data-withdrawn={isWithdrawn}
      className="group relative mt-3 flex w-full flex-none gap-x-4 px-6 data-[withdrawn=true]:opacity-40"
      key={props.booking.id}
    >
      <button
        onClick={() => {
          handleDeleteBooking()
        }}
        className="absolute -top-1.5 right-7 -m-2 my-auto h-min rounded-lg text-gray-500 transition-all hover:text-indigo-600 "
      >
        {isDeleting ? <Loader2 className="size-4 animate-spin" /> : <X className="size-4" />}
      </button>
      <div className="transition-color group flex flex-1 items-center truncate rounded-lg bg-gray-50/50 px-2">
        <div className="flex flex-1 flex-col gap-y-1 truncate py-2 text-sm">
          <div className="flex items-center text-xs ">
            <Ticket className="bezel mr-1 h-5 w-5 rounded-full bg-indigo-500 p-1 text-white" />
            {translate('pages.public.booking.summary.multipass-type')}
          </div>
          <div
            className="font-medium text-gray-900 group-data-[withdrawn=true]:line-through"
            translate="no"
          >
            {multipassProduct.name}
          </div>
          <div className="flex justify-between gap-x-2 text-gray-500 group-data-[withdrawn=true]:line-through">
            <div className="flex text-xs">
              {translate('pages.public.booking.summary.multipass-usages', {
                count: multipassProduct.maxUsages,
              })}
            </div>
            <div className="font-medium text-gray-800">
              {props.booking.priceRebateCalculation.partialOnlyRebate.totalPriceInMills > 0 && (
                <span className="line-through">
                  {translate('common.currency.EUR', {
                    val:
                      props.booking.priceRebateCalculation.totalBeforeRebate.totalPriceInMills /
                      1000,
                    minimumFractionDigits: 2,
                  })}
                </span>
              )}
              <span className="ml-2">
                {translate('common.currency.EUR', {
                  val:
                    props.booking.priceRebateCalculation.totalAfterRebate.totalPriceInMills / 1000,
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
          {isWithdrawn && (
            <div className="mt-2 flex whitespace-break-spaces text-xs text-gray-800 after:opacity-100">
              {' '}
              {translate('pages.public.booking.summary.booking-withdrawn-hint')}
            </div>
          )}
          {props.booking.rebateCodeId && (
            <div className="flex justify-end">
              <BookingRebateBadge booking={props.booking} orderId={props.order.id} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function MultipassBadge(props: {
  booking: PublicBookingWithPriceDetailsEntity
  order: OrderWithPriceDetailsEntity
}) {
  const { t: translate } = useTranslation()
  const [selectedMultipass, setSelectedMultipass] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const queryClient = useQueryClient()

  useEffect(() => {
    try {
      if (selectedMultipass && props.booking.multipassId !== selectedMultipass.id) {
        setLoading(true)
        minDelay(
          qoursesApi.paymentCustomer.paymentCustomerControllerPatchBooking(props.booking.id, {
            multipassId: selectedMultipass.id,
          }),
          1000,
        ).then(() => {
          queryClient.invalidateQueries(['public', 'order']).then(() => {
            setLoading(false)
          })
        })
      }
      if (selectedMultipass === null) {
        minDelay(
          qoursesApi.paymentCustomer.paymentCustomerControllerPatchBooking(props.booking.id, {
            multipassId: null,
          }),
          100,
        ).then(() => {
          queryClient.invalidateQueries(['public', 'order'])
          setLoading(false)
        })
      }
    } catch (e) {
      console.error(e)
    }
  }, [selectedMultipass])

  return (
    <div className="flex-1">
      <div className="flex font-semibold text-xs mb-1">
        <Ticket className="mr-1 size-4 text-indigo-600" />
        {translate('pages.public.booking.summary.used-multipass')}
      </div>
      <CustomerOrderMultipassSelection
        selectedMultipass={selectedMultipass}
        setSelectedMultipass={setSelectedMultipass}
        preselectedMultipassId={props.booking.multipassId}
        disabled={false}
        loading={loading}
        orderId={props.order.id}
      />
    </div>
  )
}
