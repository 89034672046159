import useGetLocationMediaGalleryItemsPublic from '@/hooks/public/useGetLocationMediaGalleryItemsPublic.tsx'
import useGetMediaImagePublic from '@/hooks/public/useGetMediaImagePublic.tsx'
import useGetUpcomingMeetingInstructorsPublic from '@/hooks/public/useGetUpcomingMeetingInstructorsPublic.tsx'
import { getImageUrl } from '@/utils.tsx'
import { MapIcon } from '@heroicons/react/24/outline'
import Avatar from 'boring-avatars'
import { motion } from 'framer-motion'
import { BookOpen, Contact2, MapPinIcon } from 'lucide-react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import LocationMapPreview from '@/components/LocationMapPreview.tsx'
import {
  PublicCourseWithPriceDetailsEntity,
  PublicLocationEntity,
  PublicMediaGalleryItemEntity,
} from '../../../../../qourses-api-client'
import useReverseGeocoding from '../../../../hooks/locations/useReverseGeocoding.tsx'
import useGetLocationPublic from '../../../../hooks/public/useGetLocationPublic.tsx'

export function CourseModalDetails(props: {
  course: PublicCourseWithPriceDetailsEntity
  displaySettings: string | null
}) {
  const course = props.course
  const { isLoading: instructorLoading } = useGetUpcomingMeetingInstructorsPublic(course.id)
  const { location, isLoading: locationLoading } = useGetLocationPublic(course.locationId)

  const { geocodeResult, isLoading: geocodeResultLoading } = useReverseGeocoding(
    parseFloat(location.latitude),
    parseFloat(location.longitude),
  )

  useEffect(() => {
    // nothing
  }, [locationLoading])

  if (instructorLoading || locationLoading) {
    return <div></div>
  }

  return (
    <div className="flex gap-y-12 flex-col">
      <CourseDescription course={course} />

      <CourseLocation
        location={location}
        geocodeResultLoading={geocodeResultLoading}
        geocodeResult={geocodeResult}
      />

      <CourseInstructor course={course} />
    </div>
  )
}

function CourseDescription(props: { course: PublicCourseWithPriceDetailsEntity }) {
  const { t: translate } = useTranslation()

  return (
    <div>
      <h2 className="inline-flex items-center text-lg font-semibold leading-6 text-gray-800">
        <BookOpen className="mr-2 h-5 w-5" strokeWidth={2.4} />
        {translate('pages.public.booking.course-details')}
      </h2>
      <div className="flex flex-col gap-x-3 rounded-lg p-4 text-[14px] font-normal leading-normal text-gray-800 sm:flex-row">
        <div className="mt-1 flex">
          <svg
            className="mb-2 h-6 w-6 text-gray-400 dark:text-indigo-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
        <div className="flex whitespace-break-spaces text-base leading-relaxed">
          {props.course.description}
        </div>
      </div>
    </div>
  )
}

function CourseLocation(props: {
  location: PublicLocationEntity
  geocodeResultLoading: boolean
  geocodeResult: any
}) {
  const { t: translate } = useTranslation()

  const { mediaGalleryItems } = useGetLocationMediaGalleryItemsPublic(props.location.id)

  // Function to create Google Maps URL from coordinates and address
  const getGoogleMapsUrl = () => {
    const { latitude, longitude } = props.location
    const address = props.geocodeResult?.features?.[0]?.place_name || ''

    // Use coordinates and encode the address for the URL
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&query_place_id=${encodeURIComponent(address)}`
  }

  const longitudeFloat = parseFloat(props.location.longitude)
  const latitudeFloat = parseFloat(props.location.latitude)

  console.log('props.geocodeResult', props.geocodeResult)

  return (
    <div>
      <h2 className="inline-flex items-center text-lg font-semibold leading-6 text-gray-800">
        <MapIcon className="mr-2 h-5 w-5" strokeWidth={2.4} />
        {translate('pages.public.booking.course-location')}
      </h2>
      <div className="rounded-lg p-2">
        <div className="rounded-sm">
          <LocationMapPreview lat={latitudeFloat} lon={longitudeFloat} />
        </div>
        {props.geocodeResultLoading ? (
          <Skeleton width={300} />
        ) : (
          <p className="mt-3 flex items-center text-sm font-normal text-muted-foreground">
            <a
              href={getGoogleMapsUrl()}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-500 hover:underline flex items-center"
            >
              <MapPinIcon className="h-4 w-4 mr-1" />
              {props.geocodeResult.features[0].place_name}
            </a>
          </p>
        )}
      </div>
      <LocationGallery mediaGalleryItems={mediaGalleryItems} />
    </div>
  )
}

function CourseInstructor(props: { course: PublicCourseWithPriceDetailsEntity }) {
  const { t: translate } = useTranslation()
  const { instructors, isLoading } = useGetUpcomingMeetingInstructorsPublic(props.course.id)

  function InstructorMediaImage(props: { mediaImageId: string }) {
    const { mediaImage, isLoading } = useGetMediaImagePublic(props.mediaImageId)

    if (isLoading) {
      return <Skeleton height={40} width={40} circle />
    }

    return (
      <img
        src={getImageUrl(mediaImage?.cloudflareImageId)}
        className="size-[40px] rounded-full object-cover"
      />
    )
  }

  return (
    <div>
      <h2 className="inline-flex items-center text-lg font-semibold leading-6 text-gray-800">
        <Contact2 className="mr-2 h-5 w-5" strokeWidth={2.2} />
        {translate('pages.public.booking.course-instructor', {
          count: instructors.length,
        })}
      </h2>
      <div className="mt-4">
        {isLoading ? (
          <div className="mt-4">
            <Skeleton height={20} />
          </div>
        ) : (
          instructors.map((instructor) => (
            <div className="mb-4 flex items-center ring-inset" key={instructor.id}>
              <div className="mr-4 rounded-full flex-shrink-0">
                <>
                  {instructor?.avatarMediaImageId ? (
                    <InstructorMediaImage mediaImageId={instructor.avatarMediaImageId} />
                  ) : (
                    <Avatar
                      size={40}
                      name={instructor.id + instructor.pronouns}
                      variant="beam"
                      colors={['#fcafa5', '#f0d4dc', '#5f75da', '#b681e0', '#ee79ac']}
                    />
                  )}
                </>
              </div>
              <div className="flex-row">
                <div className="text-base font-medium">{instructor.firstName}</div>
                <div className="text-sm text-muted-foreground">{instructor.bio}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

function LocationGallery(props: { mediaGalleryItems: PublicMediaGalleryItemEntity[] }) {
  const { mediaGalleryItems } = props

  // Early return if no images
  if (!mediaGalleryItems || mediaGalleryItems.length === 0) {
    return null
  }

  // Different layouts based on number of images
  switch (mediaGalleryItems.length) {
    case 1:
      return (
        <div className="mt-4">
          <LocationMediaImage mediaGalleryItem={mediaGalleryItems[0]} isSingle={true} />
        </div>
      )

    case 2:
      return (
        <div className="grid grid-cols-2 gap-2 mt-4">
          {mediaGalleryItems.map((item, index) => (
            <LocationMediaImage key={item.id || index} mediaGalleryItem={item} />
          ))}
        </div>
      )

    case 3:
      return (
        <div className="grid grid-cols-3 gap-2 mt-4">
          <LocationMediaImage mediaGalleryItem={mediaGalleryItems[0]} />
          <LocationMediaImage mediaGalleryItem={mediaGalleryItems[1]} />
          <LocationMediaImage mediaGalleryItem={mediaGalleryItems[2]} />
        </div>
      )

    case 4:
    default:
      return (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-4 place-items-center">
          {mediaGalleryItems.map((item, index) => (
            <LocationMediaImage key={item.id || index} mediaGalleryItem={item} index={index} />
          ))}
        </div>
      )
  }
}

function LocationMediaImage(props: {
  mediaGalleryItem: PublicMediaGalleryItemEntity
  isSingle?: boolean
  index?: number
}) {
  const { mediaGalleryItem, isSingle = false } = props
  const { mediaImage, isLoading } = useGetMediaImagePublic(mediaGalleryItem.mediaImageId)
  const [isLoaded, setIsLoaded] = useState(false)

  if (isLoading) {
    return null
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0,
        rotate: props.index !== undefined ? (props.index % 2 === 0 ? 3 : -3) : 0,
      }}
      animate={{
        opacity: 1,
        scale: isLoaded ? 1 : 0,
        transition: {
          delay: Math.random() * 0.5,
        },
      }}
      className="bg-white p-2 pb-8 sm:pb-10 ring-1 ring-gray-200 rounded-lg shadow-xl w-fit"
    >
      <img
        onLoad={() => setIsLoaded(true)}
        src={getImageUrl(mediaImage.cloudflareImageId)}
        alt={mediaImage.altText}
        className={`ring-1 ring-gray-300 object-cover rounded-lg ${isSingle ? 'h-48' : 'h-[120px] sm:h-[130px]'} ${isLoaded ? 'bg-gray-100' : 'bg-gray-200'}`}
      />
    </motion.div>
  )
}
