import { motion } from 'framer-motion'
import useMeasure from 'react-use-measure'

export function ResizablePanel({ children }: any) {
  const [ref, bounds] = useMeasure()

  return (
    <motion.div animate={{ height: bounds.height > 0 ? bounds.height : (null as never) }}>
      <div ref={ref}>{children}</div>
    </motion.div>
  )
}
