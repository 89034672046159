import Dynamic from '@/components/modals/dynamic'
import { CourseModalDetails } from '@/pages/public/booking/components/CourseModalDetails.tsx'
import { DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog'
import { ScrollArea } from '@/shadcn/components/ui/scroll-area.tsx'
import { useTranslation } from 'react-i18next'
import { PublicCourseWithPriceDetailsEntity } from '../../../qourses-api-client'

interface BookingPageCourseDetailsModalProps {
  course: PublicCourseWithPriceDetailsEntity
}

export default function BookingPageCourseDetailsModal({
  course,
}: BookingPageCourseDetailsModalProps) {
  const { t: translate } = useTranslation()

  return (
    <Dynamic.Content className="p-4 sm:p-8 max-w-2xl lg:max-w-screen max-h-[85vh] h-full">
      <DialogHeader className="mb-6">
        <DialogTitle className="mt-3 text-2xl mb-2">
          {translate('pages.public.booking.course-details-modal.title')}
        </DialogTitle>
      </DialogHeader>
      <ScrollArea className="p-2 h-auto" type={'always'}>
        <CourseModalDetails course={course} displaySettings={'location,details,instructor'} />
      </ScrollArea>
    </Dynamic.Content>
  )
}
