import { qoursesApi } from '@/api/qourses.tsx'
import useGetCourseMeetingsPublic from '@/hooks/public/useGetCourseMeetingsPublic.tsx'
import useGetMediaImagePublic from '@/hooks/public/useGetMediaImagePublic.tsx'
import useGetMultipassProductsPublic from '@/hooks/public/useGetMultipassProductsPublic.tsx'
import useGetTermsOfServicePublic from '@/hooks/public/useGetTermsOfServicePublic.tsx'
import useGetUpcomingMeetingInstructorsPublic from '@/hooks/public/useGetUpcomingMeetingInstructorsPublic.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { getImageUrl } from '@/utils.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import Avatar from 'boring-avatars'
import { ChevronRight, MessageSquareX, Ticket } from 'lucide-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PublicCourseWithPriceDetailsEntity } from '../../../../../qourses-api-client'

export function CourseBrief(props: {
  course: PublicCourseWithPriceDetailsEntity
  organizationId: string
}) {
  const { isAuthenticated } = useAuth0()

  const [multipassBriefRendered, setMultipassBriefRendered] = useState(false)
  const [cancellationBriefRendered, setCancellationBriefRendered] = useState(false)

  const atLeastOneChildRendered = multipassBriefRendered || cancellationBriefRendered

  return (
    <div>
      <div className="py-2">
        <InstructorBrief course={props.course} renderCallback={setMultipassBriefRendered} />
      </div>
      <div
        className={cn(
          'flex flex-col gap-y-6',
          atLeastOneChildRendered && 'border-y border-gray-300 py-8 mb-8 mt-4',
        )}
      >
        <MultipassBrief
          isAuthenticated={isAuthenticated}
          course={props.course}
          organizationId={props.organizationId}
          renderCallback={setMultipassBriefRendered}
        />
        <CancellationBrief course={props.course} renderCallback={setCancellationBriefRendered} />
      </div>
    </div>
  )
}

function MultipassBrief(props: {
  isAuthenticated: boolean
  course: PublicCourseWithPriceDetailsEntity
  organizationId: string
  renderCallback?: Dispatch<SetStateAction<boolean>>
}) {
  const { t: translate } = useTranslation()

  const {
    multipassProducts,
    isLoading: isLoadingMultipassProducts,
    isError: isErrorMultipassProducts,
  } = useGetMultipassProductsPublic(props.organizationId)

  const {
    meetings,
    isLoading: isLoadingMeetings,
    isError: isErrorMeetings,
  } = useGetCourseMeetingsPublic(props.course.id)

  const [multipassInfo, setMultipassInfo] = useState<{
    courseHasMultipassAvailable: boolean
    customerMultipassProductId: string | null
  }>({
    courseHasMultipassAvailable: false,
    customerMultipassProductId: null,
  })

  useEffect(() => {
    // If not authenticated or no multipass products, reset to default state
    if (!props.isAuthenticated || !multipassProducts || multipassProducts.length === 0) {
      setMultipassInfo({
        courseHasMultipassAvailable: false,
        customerMultipassProductId: null,
      })
      return
    }

    const fetchMultipassInfo = async () => {
      try {
        // Fetch eligible courses for each multipass product
        const eligibleCoursesPromises = multipassProducts.map(async (multipassProduct) => {
          const eligibleCourses =
            await qoursesApi.multipassPublic.multipassPublicControllerGetMultipassProductEligibleCourses(
              multipassProduct.id,
            )
          return eligibleCourses.some((c) => c.id === props.course.id)
        })

        // Wait for all promises to resolve
        const eligibleCoursesResults = await Promise.all(eligibleCoursesPromises)

        // Check if any multipass product has this course as eligible
        const courseHasMultipassAvailable = eligibleCoursesResults.some((result) => result)

        // Get customer's multipasses for this specific course
        const customerCourseMutipasses =
          await qoursesApi.courseCustomer.courseCustomerControllerGetCourseMultipasses(
            props.course.id,
          )

        setMultipassInfo({
          courseHasMultipassAvailable,
          customerMultipassProductId:
            customerCourseMutipasses.length > 0
              ? customerCourseMutipasses[0].multipassProductId
              : null,
        })
      } catch (error) {
        console.error('Error fetching course multipasses:', error)
        setMultipassInfo({
          courseHasMultipassAvailable: false,
          customerMultipassProductId: null,
        })
      }
    }

    fetchMultipassInfo()
  }, [props.isAuthenticated, multipassProducts, props.course.id, props.organizationId])

  if (isLoadingMeetings || isLoadingMultipassProducts) {
    return <Skeleton className="w-full h-16" />
  }

  if (isErrorMeetings || isErrorMultipassProducts) {
    return null
  }

  // If no multipass is available for this course, return null
  if (!multipassInfo.courseHasMultipassAvailable) {
    return null
  }

  if (!meetings || meetings.length === 0) {
    return null
  }

  props.renderCallback(true)

  return (
    <>
      {props.isAuthenticated && multipassInfo.customerMultipassProductId ? (
        <div className="flex gap-x-4">
          <div className="flex flex-shrink-0">
            <Ticket className="size-5" strokeWidth={2} />
          </div>
          <div className="flex flex-col gap-y-1">
            <h3 className="font-semibold text-base">
              {translate('pages.public.booking.courseBrief.multipass_available.title')}
            </h3>
            <p className="text-muted-foreground text-sm">
              {translate('pages.public.booking.courseBrief.multipass_available.subtitle')}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex gap-x-3">
          <div className="flex flex-shrink-0">
            <Ticket className="mr-2 size-6" strokeWidth={2} />
          </div>
          <div className="flex flex-col gap-y-1">
            <h3 className="font-semibold text-base">
              {translate('pages.public.booking.courseBrief.multipass_advertisement.title')}
            </h3>
            <p className="text-muted-foreground text-sm">
              {translate('pages.public.booking.courseBrief.multipass_advertisement.subtitle')}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

function CancellationBrief(props: {
  course: PublicCourseWithPriceDetailsEntity
  renderCallback?: Dispatch<SetStateAction<boolean>>
}) {
  const { termsOfService, isError, isLoading } = useGetTermsOfServicePublic(
    props.course.termsOfServiceId,
  )
  const { t: translate } = useTranslation()

  if (isLoading) {
    return <Skeleton className="w-full h-16" />
  }

  if (isError) {
    return null
  }

  if (!termsOfService.userCancellationEnabled || termsOfService.userCancellationDaysBefore < 0) {
    return null
  }

  props.renderCallback(true)

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-shrink-0">
        <MessageSquareX className="size-5" strokeWidth={2} />
      </div>
      <div className="flex flex-col gap-y-1">
        <h3 className="font-semibold text-base">
          {translate('pages.public.booking.courseBrief.cancellation.title', {
            days: termsOfService.userCancellationDaysBefore,
          })}
        </h3>
        <p className="text-muted-foreground text-sm">
          {translate('pages.public.booking.courseBrief.cancellation.subtitle', {
            days: termsOfService.userCancellationDaysBefore,
          })}
        </p>
        <div className="text-gray-600 hover:text-indigo-500 underline text-sm flex items-center">
          <Link target="_blank" to={'/book/legal/tos/' + termsOfService.id}>
            {translate('pages.public.booking.courseBrief.cancellation.tosLink')}
          </Link>
          <ChevronRight className="size-4 ml-1" />
        </div>
      </div>
    </div>
  )
}

function InstructorBrief(props: {
  course: PublicCourseWithPriceDetailsEntity
  renderCallback?: Dispatch<SetStateAction<boolean>>
}) {
  const { instructors, isLoading, isError } = useGetUpcomingMeetingInstructorsPublic(
    props.course.id,
  )

  const { t: translate } = useTranslation()

  function InstructorMediaImage(props: { mediaImageId: string }) {
    const { mediaImage, isLoading } = useGetMediaImagePublic(props.mediaImageId)

    if (isLoading) {
      return <Skeleton className="rounded-full size-[30px]" />
    }

    return (
      <img
        src={getImageUrl(mediaImage?.cloudflareImageId)}
        className="size-[40px] rounded-full object-cover"
      />
    )
  }

  if (isLoading) {
    return <Skeleton className="w-full h-16" />
  }

  if (isError) {
    return null
  }

  return (
    <div className="flex gap-x-5 items-center">
      <div className="flex flex-col flex-shrink-0">
        {instructors.length > 0 && instructors[0] ? (
          instructors[0].avatarMediaImageId ? (
            <InstructorMediaImage mediaImageId={instructors[0].avatarMediaImageId} />
          ) : (
            <Avatar
              size={40}
              name={instructors[0].id}
              variant="beam"
              colors={['#fcafa5', '#f0d4dc', '#5f75da', '#b681e0', '#ee79ac']}
            />
          )
        ) : (
          <Avatar
            size={40}
            name="generic-instructor"
            variant="beam"
            colors={['#fcafa5', '#f0d4dc', '#5f75da', '#b681e0', '#ee79ac']}
          />
        )}
      </div>
      <div className="flex flex-col gap-y-1">
        <div>
          <h3 className="font-semibold text-base">
            {instructors.length === 1
              ? translate('pages.public.booking.courseBrief.instructors.title_one', {
                  name: instructors[0].firstName,
                })
              : translate('pages.public.booking.courseBrief.instructors.title_multiple', {
                  names: instructors
                    .map((instructor) => instructor.firstName)
                    .reduce((acc, name, index, array) => {
                      if (index === 0) return name
                      if (index === array.length - 1) return `${acc} und ${name}`
                      return `${acc}, ${name}`
                    }, ''),
                })}
          </h3>
          <p className="text-muted-foreground text-sm">
            {translate('pages.public.booking.courseBrief.instructors.subtitle')}
          </p>
        </div>
      </div>
    </div>
  )
}
