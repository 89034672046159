import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { CustomerMultipassEntity } from '../../../qourses-api-client'

export default function useGetEndCustomerMultipasses(disabled = false): {
  multipasses: CustomerMultipassEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: multipasses,
    isLoading,
    isError,
  } = useQuery(
    GetEndCustomerMultipassesQueryKey(),
    async () => {
      return qoursesApi.multipassCustomer.multipassCustomerControllerGetMultipasses()
    },
    {
      enabled: !disabled,
    },
  )

  let isEmpty = true

  if (multipasses === undefined) {
    return { multipasses: [], isLoading, isEmpty, isError }
  }

  if (multipasses.length > 0) {
    isEmpty = false
  }

  return { multipasses, isLoading, isEmpty, isError }
}

export const GetEndCustomerMultipassesQueryKey = () => ['customer', 'multipasses']
