/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PublicOrganizationEntity = {
    id: string;
    name: string;
    companyLegalName: string;
    description: string | null;
    slug: string;
    activeCustomer: boolean;
    highlightedTag1Id: string | null;
    highlightedTag2Id: string | null;
    highlightedTag3Id: string | null;
    testMode: boolean;
    archived: boolean;
    plan: PublicOrganizationEntity.plan | null;
    logoMediaImageId: string | null;
};
export namespace PublicOrganizationEntity {
    export enum plan {
        MINI = 'MINI',
        CORE = 'CORE',
        PRO = 'PRO',
        ULTRA = 'ULTRA',
    }
}

