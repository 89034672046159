import { qoursesApi } from '@/api/qourses.tsx'
import useGetMultipassProductCoursesPublic from '@/hooks/public/useGetMultipassProductCoursesPublic.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import * as Sentry from '@sentry/react'
import { BadgeEuro, BadgeInfo, Ticket } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PublicMultipassProductWithBookingInfoEntity } from '../../../../../qourses-api-client'

export function MultipassRow(props: { multipass: PublicMultipassProductWithBookingInfoEntity }) {
  const multipass = props.multipass
  const { t: translate } = useTranslation()

  const { courses, isLoading: coursesLoading } = useGetMultipassProductCoursesPublic(multipass.id)
  const [bestSavings, setBestSavings] = useState<number>(0)

  useEffect(() => {
    const calculateBestSavings = async () => {
      if (coursesLoading || !courses.length) {
        setBestSavings(0)
        return
      }

      // Find the course with the cheapest meeting default price
      const mostExpensiveCourse = courses.reduce((prev, current) => {
        return prev.priceInMills > current.priceInMills ? prev : current
      })

      try {
        const mostExpensiveCoursePaymentProfile =
          await qoursesApi.paymentPublic.paymentPublicControllerGetStripePaymentProfile(
            mostExpensiveCourse.stripePaymentProfileId,
          )

        const priceWithoutMultipassInMills = mostExpensiveCourse.priceInMills * multipass.maxUsages
        let priceWithoutMultipassInMillsWithTaxesMaybe
        if (mostExpensiveCoursePaymentProfile.taxRateInPercent > 0) {
          priceWithoutMultipassInMillsWithTaxesMaybe =
            priceWithoutMultipassInMills *
            (1 + mostExpensiveCoursePaymentProfile.taxRateInPercent / 100)
        } else {
          priceWithoutMultipassInMillsWithTaxesMaybe = priceWithoutMultipassInMills
        }

        const multipassPrice = multipass.totalPriceInMills
        const savings = (priceWithoutMultipassInMillsWithTaxesMaybe - multipassPrice) / 1000

        setBestSavings(savings)
      } catch (error) {
        // Fail silently
        Sentry.captureException(error)
      }
    }

    calculateBestSavings()
  }, [courses, coursesLoading, multipass.maxUsages, multipass.totalPriceInMills])

  if (multipass.stock === 0) {
    return null
  }

  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-col justify-start sm:gap-y-1">
        <div>
          <p className="items-center pr-2 font-medium" translate="no">
            {multipass.name}
          </p>
        </div>
        <div className="my-2 flex items-center text-sm font-normal italic text-gray-700">
          <div className="rounded-lg bg-gray-50 p-3 ring-1 ring-gray-200">
            "{multipass.description}"
          </div>
        </div>
        <div>
          <div className="flex flex-wrap items-center">
            <div className="mr-1 whitespace-nowrap text-sm text-gray-800">
              {translate('pages.public.booking.multipasses.eligibleForCourses')}
              {coursesLoading ? '...' : ''}
            </div>
            {!coursesLoading &&
              courses.map((course, index) => (
                <div className="flex">
                  <p key={course.id} className="text-sm font-semibold text-gray-800">
                    {course.name}
                  </p>
                  <>
                    {index < courses.length - 2 ? (
                      <p className="mr-1 text-sm">, </p>
                    ) : index === courses.length - 2 ? (
                      <p className="ml-1 mr-1 text-sm"> und </p>
                    ) : null}
                  </>
                </div>
              ))}
          </div>
        </div>
        <div className="my-2 flex flex-wrap items-center gap-x-1.5 gap-y-2 whitespace-nowrap sm:my-1">
          <Badge variant="outline" className="ring-1 ring-gray-200">
            <BadgeEuro className="mr-1 h-4 w-4 text-indigo-600" aria-hidden="true" />
            {translate('common.currency.EUR', {
              val: multipass.totalPriceInMills / 1000,
              minimumFractionDigits: 2,
            })}
          </Badge>
          <Badge variant="outline" className="border border-gray-200 ring-1 ring-gray-300">
            <Ticket className="mr-1 h-4 w-4 text-indigo-600" aria-hidden="true" />

            {translate('pages.public.booking.multipasses.usages', {
              maxUsages: multipass.maxUsages,
            })}
          </Badge>
          <Badge variant="outline" className="border border-gray-200 ring-1 ring-gray-300">
            <BadgeInfo className="mr-1 h-4 w-4 text-indigo-600" aria-hidden="true" />
            {translate('pages.public.booking.multipasses.singleBookingHint')}
          </Badge>
        </div>
        {bestSavings > 5 && bestSavings < 500 && (
          <div className="flex flex-wrap items-center whitespace-nowrap text-xs font-normal text-gray-800">
            <p className="mr-1 text-sm text-muted-foreground">
              {translate('pages.public.booking.multipasses.saveUpTo', {
                savings: Math.floor(bestSavings),
                minimumFractionDigits: 0,
              })}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
