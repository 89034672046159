/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicMediaImageEntity } from '../models/PublicMediaImageEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MediaPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a Media Image by ID
     * @param mediaImageId
     * @returns PublicMediaImageEntity Media Image found
     * @throws ApiError
     */
    public mediaPublicControllerGetMediaImageById(
        mediaImageId: string,
    ): CancelablePromise<PublicMediaImageEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
            errors: {
                404: `Media Image not found`,
            },
        });
    }
}
