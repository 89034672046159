import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { PublicMediaGalleryItemEntity } from '../../../qourses-api-client'

export default function useGetCourseGroupMediaGalleryItemsPublic(courseId: string): {
  mediaGalleryItems: PublicMediaGalleryItemEntity[]
  isLoading: boolean
  isEmptyMediaImages: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: mediaGalleryItems,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(
    getCourseGroupMediaGalleryItemsQueryKey(courseId),
    async () => {
      return qoursesApi.courseGroupPublic.courseGroupPublicControllerGetCourseGroupMediaGalleryItems(
        courseId,
      )
    },
    {
      enabled: !!courseId,
    },
  )

  const isEmpty = !mediaGalleryItems || mediaGalleryItems.length === 0

  return {
    mediaGalleryItems: mediaGalleryItems || [],
    isLoading,
    isEmptyMediaImages: isEmpty,
    isError,
    refetch,
    isRefetching,
  }
}

export const getCourseGroupMediaGalleryItemsQueryKey = (courseId: string) => [
  'public',
  'galleryItems',
  'courseGroup',
  courseId,
]
