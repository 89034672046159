import { pushModal } from '@/components/modals'
import useGetCourseMediaGalleryItemsPublic from '@/hooks/public/useGetCourseMediaGalleryItemsPublic.tsx'
import useGetMediaImagePublic from '@/hooks/public/useGetMediaImagePublic.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { getImageUrl } from '@/utils.tsx'
import { motion } from 'framer-motion'
import { ArrowRight, X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PublicCourseWithPriceDetailsEntity,
  PublicMediaGalleryItemEntity,
} from '../../../../../qourses-api-client'

export function CourseContent(props: { course: PublicCourseWithPriceDetailsEntity }) {
  const { mediaGalleryItems } = useGetCourseMediaGalleryItemsPublic(props.course.id)
  const course = props.course

  const { t: translate } = useTranslation()

  return (
    <div>
      <div className="my-4">
        {/* Add the gallery component here */}
        {mediaGalleryItems && mediaGalleryItems.length > 0 && (
          <CourseGallery mediaGalleryItems={mediaGalleryItems} />
        )}
      </div>

      <p className="text-md text-gray-700" translate="no">
        {course.description.length > 100
          ? course.description.substring(0, 250) + '...'
          : course.description}
      </p>

      <div
        className="font-semibold text-gray-900 mt-4 underline flex items-center cursor-pointer hover:text-indigo-600"
        onClick={() => {
          pushModal('BookingPageCourseDetailsModal', {
            course: course,
          })
        }}
      >
        {translate('pages.public.booking.course-details-modal.button')}
        <ArrowRight className="ml-1 h-4 w-4" />
      </div>
    </div>
  )
}

function CourseGallery(props: { mediaGalleryItems: PublicMediaGalleryItemEntity[] }) {
  const { mediaGalleryItems } = props

  // Early return if no images
  if (!mediaGalleryItems || mediaGalleryItems.length === 0) {
    return null
  }

  // Different layouts based on number of images
  switch (mediaGalleryItems.length) {
    case 1:
      return (
        <div className="mt-4">
          <CourseContentMediaImage mediaGalleryItem={mediaGalleryItems[0]} isSingle={true} />
        </div>
      )

    case 2:
      return (
        <div className="grid grid-cols-2 gap-2 mt-4">
          {mediaGalleryItems.map((item, index) => (
            <CourseContentMediaImage key={item.id || index} mediaGalleryItem={item} />
          ))}
        </div>
      )

    case 3:
      return (
        <div className="grid grid-cols-2 gap-2 mt-4">
          <div className="col-span-2">
            <CourseContentMediaImage mediaGalleryItem={mediaGalleryItems[0]} />
          </div>
          <CourseContentMediaImage mediaGalleryItem={mediaGalleryItems[1]} />
          <CourseContentMediaImage mediaGalleryItem={mediaGalleryItems[2]} />
        </div>
      )

    case 4:
    default:
      return (
        <div className="grid grid-cols-2 gap-2 mt-4">
          {mediaGalleryItems.map((item, index) => (
            <CourseContentMediaImage key={item.id || index} mediaGalleryItem={item} />
          ))}
        </div>
      )
  }
}

function CourseContentMediaImage(props: {
  mediaGalleryItem: PublicMediaGalleryItemEntity
  isSingle?: boolean
}) {
  const { mediaGalleryItem, isSingle = false } = props
  const { mediaImage, isLoading } = useGetMediaImagePublic(mediaGalleryItem.mediaImageId)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  // Handle image click to toggle fullscreen
  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Stop the event from bubbling up
    e.preventDefault() // Prevent default behavior
    setIsFullScreen(true)
  }

  // Handle closing fullscreen view
  const handleCloseFullScreen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation()
    }
    setIsFullScreen(false)
  }

  if (isLoading) {
    return null
  }

  return (
    <div className="h-full w-full">
      <motion.img
        animate={{ opacity: isLoaded ? 1 : 0 }}
        transition={{
          opacity: { duration: 0.3 },
        }}
        onLoad={() => setIsLoaded(true)}
        src={getImageUrl(mediaImage.cloudflareImageId)}
        alt={mediaImage.altText}
        className={`hover:scale-[97%] transition-transform object-cover rounded-lg w-full aspect-square ${isSingle ? 'h-48' : 'h-[120px] sm:h-[200px]'} ${isLoaded ? 'bg-gray-100' : 'bg-gray-200'} cursor-pointer`}
        onClick={handleImageClick}
      />

      {/* Fullscreen Image Modal */}
      {isFullScreen && (
        <motion.div
          id="fullscreen-modal"
          key={mediaGalleryItem.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            opacity: { duration: 0.3 },
          }}
          className="fixed inset-0 z-[10000000] bg-black bg-opacity-90 flex items-center justify-center"
          onClick={handleCloseFullScreen}
        >
          <div className="relative max-w-screen-xl max-h-screen p-4">
            <Button
              variant="ghost"
              className="absolute top-5 right-5 text-white hover:bg-gray-100 z-10 p-2 rounded-xl"
              onClick={(e) => {
                e.stopPropagation()
                handleCloseFullScreen()
              }}
            >
              <X className="size-6" />
            </Button>
            <motion.img
              src={getImageUrl(mediaImage.cloudflareImageId)}
              alt={mediaImage.altText || 'Fullscreen view'}
              className="max-w-full max-h-[90vh] object-contain rounded-xl"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking the image itself
            />
          </div>
        </motion.div>
      )}
    </div>
  )
}
