import { useQuery } from '@tanstack/react-query'
import { PublicCourseEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetMultipassProductCoursesPublic(multipassProductId: string): {
  courses: PublicCourseEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: courses,
    isLoading,
    isError,
  } = useQuery(['public', 'multipassProducts', multipassProductId, 'courses'], async () => {
    return qoursesApi.multipassPublic.multipassPublicControllerGetMultipassProductEligibleCourses(
      multipassProductId,
    )
  })

  let isEmpty = true

  if (courses === undefined) {
    return { courses: [], isLoading, isEmpty, isError }
  }

  if (courses.length > 0) {
    isEmpty = false
  }

  return { courses, isLoading, isEmpty, isError }
}
