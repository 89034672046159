import Highlight from '@/components/Highlight.tsx'
import useGetCourseGroupMediaGalleryItemsPublic from '@/hooks/public/useGetCourseGroupMediaGalleryItemsPublic.tsx'
import useGetCourseGroupMeetingInstructors from '@/hooks/public/useGetCourseGroupMeetingInstructors.tsx'
import useGetCourseGroupMeetingsPublic from '@/hooks/public/useGetCourseGroupMeetingsPublic.tsx'
import useGetCourseGroupsPublic from '@/hooks/public/useGetCourseGroupsPublic.tsx'
import useGetMediaImagePublic from '@/hooks/public/useGetMediaImagePublic.tsx'
import useGetMeetingInstructorsPublic from '@/hooks/public/useGetMeetingInstructorsPublic.tsx'
import { meetingIdParam } from '@/pages/public/booking/SessionParams.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { classNames, getImageUrl } from '@/utils.tsx'
import NumberFlow from '@number-flow/react'
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { motion } from 'framer-motion'
import {
  CalendarFold,
  ChevronLeft,
  ChevronRight,
  GroupIcon,
  Lock,
  Ticket,
  UserRound,
  UsersRound,
} from 'lucide-react'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import {
  MeetingBookingInfoDto,
  OrderEntity,
  PublicCourseGroupWithBookingInfoEntity,
  PublicCourseWithPriceDetailsEntity,
  PublicMediaGalleryItemEntity,
  PublicMeetingWithBookingInfoEntity,
  PublicMultipassProductWithBookingInfoEntity,
} from '../../../../../qourses-api-client'
import EmptyState from '../../../../components/EmptyState.tsx'
import useGetCourseMeetingsPublic from '../../../../hooks/public/useGetCourseMeetingsPublic.tsx'

export enum BookableType {
  Meeting = 'meeting',
  CourseGroup = 'courseGroup',
  Multipass = 'multipass',
}

export type IdentifiableBookable = {
  bookable:
    | PublicMeetingWithBookingInfoEntity
    | PublicCourseGroupWithBookingInfoEntity
    | PublicMultipassProductWithBookingInfoEntity
    | null
  bookableType: BookableType.Meeting | BookableType.CourseGroup | BookableType.Multipass
}

// Type guard for PublicMeetingWithBookingInfoEntity
export function isPublicMeetingWithBookingInfoEntity(
  bookable: any,
  bookableType: BookableType,
): bookable is PublicMeetingWithBookingInfoEntity {
  return bookableType === BookableType.Meeting && bookable !== null
}

// Type guard for PublicCourseGroupWithBookingInfoEntity
export function isPublicCourseGroupWithBookingInfoEntity(
  bookable: any,
  bookableType: BookableType,
): bookable is PublicCourseGroupWithBookingInfoEntity {
  return bookableType === BookableType.CourseGroup && bookable !== null
}

// Type guard for Multipass
export function isMultipassWithBookingInfoEntity(
  bookable: any,
  bookableType: BookableType,
): bookable is PublicMultipassProductWithBookingInfoEntity {
  return bookableType === BookableType.Multipass && bookable !== null
}

export function CourseMeetings(props: {
  course: PublicCourseWithPriceDetailsEntity
  setSelectedMeeting: Dispatch<SetStateAction<IdentifiableBookable>>
  selectedMeeting: IdentifiableBookable
  order: OrderEntity | null
}) {
  const [searchParams] = useSearchParams()
  const { meetings, isLoading: meetingsLoading } = useGetCourseMeetingsPublic(props.course.id)

  const { courseGroups, isLoading: courseGroupsLoading } = useGetCourseGroupsPublic(props.course.id)

  const { t: translate } = useTranslation()

  const meetingId = searchParams.get(meetingIdParam)

  // Hydrate the selected meeting from the URL
  useEffect(() => {
    if (meetingId) {
      const matchedMeeting = meetings.filter((meeting) => meeting.id === meetingId)[0] ?? null

      if (matchedMeeting) {
        props.setSelectedMeeting({
          bookable: matchedMeeting,
          bookableType: BookableType.Meeting,
        })
      }
    }
  }, [meetingsLoading])

  // Hydrate the selected courseGroup from the URL
  useEffect(() => {
    if (meetingId) {
      const matchedCourseGroup =
        courseGroups.filter((courseGroup) => courseGroup.id === meetingId)[0] ?? null

      if (matchedCourseGroup) {
        props.setSelectedMeeting({
          bookable: matchedCourseGroup,
          bookableType: BookableType.CourseGroup,
        })
      }
    }
  }, [courseGroupsLoading])

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const data = useMemo(() => meetings, [meetings])

  const paginatedMeetings = useReactTable({
    columns: [],
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // Dont change pages when the data changes
    autoResetPageIndex: false,
    initialState: {
      pagination: {
        pageSize: isMobile ? 4 : 6,
      },
    },
  })

  if (meetingsLoading || courseGroupsLoading) {
    return (
      <div className="mb-5 mt-6 flex flex-1 gap-x-5">
        <div className="flex-1">
          <Skeleton className="h-[100px] w-full rounded-lg" />
        </div>
        <div className="flex-1">
          <Skeleton className="h-[100px] w-full rounded-lg" />
        </div>
      </div>
    )
  }

  if (meetings.length === 0 && courseGroups.length === 0) {
    return (
      <div className="mb-6 mt-6">
        <EmptyState
          title={translate('pages.public.booking.meeting-booking-info.empty-title')}
          subtitle={translate('pages.public.booking.meeting-booking-info.empty-subtitle')}
          isError={false}
        />
      </div>
    )
  }

  function hasFreeSpots(bookingInfo: MeetingBookingInfoDto, maxAttendees: number) {
    const freeSpots = maxAttendees - bookingInfo.claimed - bookingInfo.reserved - bookingInfo.booked

    return Math.round((freeSpots / maxAttendees) * 100) > 0
  }

  return (
    <div className="mb-8">
      {meetings.length > 0 && (
        <>
          <h2
            className="mb-8 flex flex-row items-center text-xl font-semibold leading-6 text-gray-900"
            translate="no"
          >
            <CalendarFold className="mr-2 size-5" strokeWidth={2.4} />
            {translate('pages.public.booking.meeting-booking-info.title', {
              course: props.course.name,
            })}{' '}
          </h2>
          <ul
            role="list"
            className="lg:grid-cols-auto grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6"
          >
            {paginatedMeetings
              .getRowModel()
              .rows.map((row) => row.original)
              .filter((meeting) => !meeting.cancelled)
              .map((meeting) => (
                <>
                  <motion.li
                    key={meeting.id}
                    className="col-span-1 flex cursor-pointer rounded-md opacity-100 shadow-sm"
                    initial={{ opacity: 0, translateY: -10 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    whileHover={{ scale: isMobile ? 1 : 1.05 }}
                    transition={{
                      type: 'spring',
                      bounce: 0.5,
                    }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => {
                      // If the meeting is booked out, can't select it!
                      // Probable future item here -> Waitinglist later
                      if (!hasFreeSpots(meeting.bookingInfo, props.course.maximumAttendees)) {
                        return
                      }

                      // If the meeting is already selected, deselect it
                      if (
                        props.selectedMeeting &&
                        meeting.id === props.selectedMeeting.bookable.id
                      ) {
                        props.setSelectedMeeting(null)
                      } else {
                        props.setSelectedMeeting({
                          bookable: meeting,
                          bookableType: BookableType.Meeting,
                        })
                      }
                    }}
                  >
                    <div
                      className={
                        classNames(
                          meeting?.id === props.selectedMeeting?.bookable.id
                            ? 'ring-2 ring-indigo-500'
                            : 'justify-start',
                          'group flex flex-1 items-center truncate rounded-md border border-gray-200 bg-violet-100/20 transition-colors',
                        ) +
                        //DON'T FORGET A SPACE HERE AT THE START OF THE CLASSNAME OR IT WILL BREAK
                        classNames(
                          hasFreeSpots(
                            meeting.bookingInfo,
                            // Does the meeting have a maximumAttendees set? If not, use the course maximumAttendees
                            meeting.maximumAttendees ?? props.course.maximumAttendees,
                          ) === false
                            ? 'disabled pointer-events-none cursor-not-allowed opacity-60'
                            : '',
                        )
                      }
                    >
                      <div className="ml-3 overflow-hidden rounded-lg bg-red-500 text-center shadow-inner ring-2 ring-gray-200">
                        <p className="px-2 py-0.5 text-xs font-semibold text-white">
                          {DateTime.fromISO(meeting.start).monthShort}
                        </p>
                        <div className="rounded-t-md bg-white px-1 text-gray-800">
                          {DateTime.fromISO(meeting.start).day}
                        </div>
                      </div>
                      <div className="flex-1 flex-wrap items-center truncate px-4 py-2 text-sm">
                        <div>
                          <div className="flex justify-between font-normal text-gray-900">
                            {translate('common.time.rangeShort', {
                              from: DateTime.fromISO(meeting.start).toLocaleString(
                                DateTime.TIME_SIMPLE,
                              ),
                              to: DateTime.fromISO(meeting.end).toLocaleString(
                                DateTime.TIME_SIMPLE,
                              ),
                            })}
                            {meeting.totalSavingsInMills > 0 ? (
                              <div className="relative">
                                <div className="flex items-center text-sm font-semibold">
                                  <Ticket className="bezel mr-1 h-5 w-5 rounded-full bg-indigo-500 p-1 text-white" />
                                  {translate('common.currency.EUR', {
                                    val: meeting.discountedTotalPriceInMills / 1000,
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                                <div className="absolute right-0.5 text-xs line-through text-muted-foreground">
                                  {translate('common.currency.EUR', {
                                    val: meeting.totalPriceInMills / 1000,
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            ) : (
                              <div className="flex text-sm font-semibold">
                                {translate('common.currency.EUR', {
                                  val: meeting.totalPriceInMills / 1000,
                                  minimumFractionDigits: 2,
                                })}
                              </div>
                            )}
                          </div>
                          <div className="mt-1 text-xs">
                            <MeetingInstructors meetingId={meeting.id} />
                          </div>
                        </div>
                        <div className="mt-2 flex flex-wrap items-center justify-start gap-x-2 gap-y-2">
                          <AttendanceBadge
                            meeting={{
                              bookable: meeting,
                              bookableType: BookableType.Meeting,
                            }}
                            course={props.course}
                            order={props.order}
                          />
                        </div>
                      </div>
                    </div>
                  </motion.li>
                </>
              ))}
          </ul>
          <div className="space-y-2 pb-4 pt-6">
            <div className="flex items-center justify-between sm:justify-end gap-x-2 flex-wrap gap-y-2">
              <Button
                variant="outline"
                size="sm"
                className="bg-white w-full sm:w-auto h-11 sm:h-9"
                onClick={() => {
                  props.setSelectedMeeting(null)
                  paginatedMeetings.previousPage()
                }}
                disabled={!paginatedMeetings.getCanPreviousPage()}
              >
                <ChevronLeft className="mr-1 size-4" />
                {translate('common.meetingPagination.previous')}
              </Button>
              <Button
                variant="outline"
                className="w-full sm:w-auto h-11 sm:h-9"
                size="sm"
                onClick={() => {
                  props.setSelectedMeeting(null)
                  paginatedMeetings.nextPage()
                }}
                disabled={!paginatedMeetings.getCanNextPage()}
              >
                {translate('common.meetingPagination.next')}
                <ChevronRight className="ml-1 size-4" />
              </Button>
            </div>
            <div className="text-right text-xs text-muted-foreground">
              {translate('common.pagination.pages', {
                current: paginatedMeetings.getState().pagination.pageIndex + 1,
                total: paginatedMeetings.getPageCount(),
              })}
            </div>
          </div>
        </>
      )}
      {courseGroups.length > 0 && (
        <>
          <h2
            className="mb-8 flex flex-row items-center text-xl font-semibold leading-6 text-gray-900"
            translate="no"
          >
            <GroupIcon className="mr-2 size-5" strokeWidth={2.4} />
            {translate('pages.public.booking.courseGroups.dialog.courseGroups', {
              course: props.course.name,
            })}{' '}
          </h2>
          <ul role="list" className="mt-5 grid grid-cols-1 gap-5">
            {courseGroups.map((courseGroup) => (
              <>
                <motion.li
                  key={courseGroup.id}
                  layout={true}
                  layoutId={courseGroup.id}
                  className="col-span-1 flex cursor-pointer rounded-md shadow-sm"
                  initial={{ opacity: 0, translateY: -10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  whileHover={{ scale: isMobile ? 1 : 1.02 }}
                  transition={{
                    type: 'spring',
                    bounce: 0.5,
                  }}
                  whileTap={{ scale: 0.99 }}
                  onClick={() => {
                    // If the meeting is booked out, can't select it!
                    // Probable future item here -> Waitinglist later
                    if (
                      !hasFreeSpots(
                        courseGroup.bookingInfo,
                        courseGroup.maximumAttendees ?? props.course.maximumAttendees,
                      )
                    ) {
                      return
                    }

                    // If the meeting is already selected, deselect it
                    if (
                      props.selectedMeeting &&
                      courseGroup.id === props.selectedMeeting.bookable.id
                    ) {
                      props.setSelectedMeeting(null)
                    } else {
                      props.setSelectedMeeting({
                        bookable: courseGroup,
                        bookableType: BookableType.CourseGroup,
                      })
                    }
                  }}
                >
                  <CourseGroupCard
                    course={props.course}
                    courseGroup={courseGroup}
                    isSelected={courseGroup?.id === props.selectedMeeting?.bookable.id}
                    attendanceBadge={
                      <AttendanceBadge
                        meeting={{
                          bookable: courseGroup,
                          bookableType: BookableType.CourseGroup,
                        }}
                        course={props.course}
                        order={props.order}
                      />
                    }
                  />
                </motion.li>
                {courseGroup?.id === props.selectedMeeting?.bookable.id && (
                  <CourseGroupGallery courseGroupId={courseGroup.id} />
                )}
              </>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

function CourseGroupCard(props: {
  course: PublicCourseWithPriceDetailsEntity
  courseGroup: PublicCourseGroupWithBookingInfoEntity
  isSelected: boolean
  attendanceBadge: JSX.Element
}) {
  const courseGroup = props.courseGroup
  const { t: translate } = useTranslation()

  const { courseGroupMeetings, isLoading } = useGetCourseGroupMeetingsPublic(courseGroup.id)

  const { instructors } = useGetCourseGroupMeetingInstructors(courseGroup.id)

  function hasMeetingFreeSpots(
    courseGroup: PublicCourseGroupWithBookingInfoEntity,
    maxSpaces: number,
  ) {
    const freeSpots =
      maxSpaces -
      courseGroup.bookingInfo.claimed -
      courseGroup.bookingInfo.reserved -
      courseGroup.bookingInfo.booked

    return Math.round((freeSpots / maxSpaces) * 100) > 0
  }

  return (
    <div
      className={
        classNames(
          props.isSelected ? 'ring-2 ring-indigo-500' : 'justify-start gap-x-10',
          'group flex flex-1 items-center truncate rounded-md border border-gray-200 bg-violet-100/20 transition-colors',
        ) +
        //DON'T FORGET A SPACE HERE AT THE START OF THE CLASSNAME OR IT WILL BREAK
        classNames(
          hasMeetingFreeSpots(
            courseGroup,
            props.courseGroup.maximumAttendees ?? props.course.maximumAttendees,
          ) === false
            ? 'cursor-not-allowed opacity-60'
            : '',
        )
      }
    >
      <div className="text-md flex-1 truncate px-4 py-4">
        <div className="font-medium text-gray-900">
          <div className="sm:flex sm:justify-between">
            <div className="flex flex-wrap gap-x-2 whitespace-break-spaces">
              <p>{courseGroup.name}</p>
              <p className="text-muted-foreground">({courseGroupMeetings.length} Termine)</p>
            </div>
            <div className="mb-2 sm:mb-0">
              {translate('common.currency.EUR', {
                val: courseGroup.totalPriceInMills / 1000,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="text-sm text-muted-foreground">
            Mit{' '}
            {instructors.length === 1
              ? instructors[0].firstName
              : instructors
                  .map((instructor) => instructor.firstName)
                  .reduce((acc, name, index, array) => {
                    if (index === 0) return name
                    if (index === array.length - 1) return `${acc} und ${name}`
                    return `${acc}, ${name}`
                  }, '')}
          </div>
        </div>
        {isLoading ? (
          <></>
        ) : (
          <div className="flex max-w-md flex-wrap">
            {courseGroupMeetings
              .filter((meeting) => !meeting.cancelled)
              .map((meeting, index) => (
                <>
                  <div className="mr-1 mt-0.5 text-xs text-gray-500">
                    {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATE_SHORT)} (
                    {DateTime.fromISO(meeting.start).toLocaleString(DateTime.TIME_SIMPLE)}-
                    {DateTime.fromISO(meeting.end).toLocaleString(DateTime.TIME_SIMPLE)})
                    {index < courseGroupMeetings.length - 1 && ','}
                  </div>
                </>
              ))}
          </div>
        )}
        <div className="mt-2 flex flex-wrap items-center justify-start gap-x-2 gap-y-2">
          {props.attendanceBadge}
        </div>
      </div>
    </div>
  )
}

function CourseGroupGallery(props: { courseGroupId: string }) {
  const { mediaGalleryItems } = useGetCourseGroupMediaGalleryItemsPublic(props.courseGroupId)

  if (!mediaGalleryItems || mediaGalleryItems.length === 0) {
    return null
  }

  // Apply different layouts based on the number of images
  switch (mediaGalleryItems.length) {
    case 1:
      return (
        <div className="mt-4">
          <CourseGroupMediaImage mediaGalleryItem={mediaGalleryItems[0]} isSingle={true} />
        </div>
      )

    case 2:
      return (
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-4">
          {mediaGalleryItems.map((item, index) => (
            <CourseGroupMediaImage key={item.id || index} mediaGalleryItem={item} />
          ))}
        </div>
      )

    case 3:
      return (
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-4">
          <CourseGroupMediaImage mediaGalleryItem={mediaGalleryItems[0]} />
          <CourseGroupMediaImage mediaGalleryItem={mediaGalleryItems[1]} />
          <CourseGroupMediaImage mediaGalleryItem={mediaGalleryItems[2]} />
        </div>
      )

    case 4:
    default:
      return (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-4 place-items-center">
          {mediaGalleryItems.map((item, index) => (
            <CourseGroupMediaImage key={item.id || index} mediaGalleryItem={item} index={index} />
          ))}
        </div>
      )
  }
}

function CourseGroupMediaImage(props: {
  mediaGalleryItem: PublicMediaGalleryItemEntity
  isSingle?: boolean
  index?: number
}) {
  const { mediaGalleryItem, isSingle = false } = props
  const { mediaImage, isLoading } = useGetMediaImagePublic(mediaGalleryItem.mediaImageId)
  const [isLoaded, setIsLoaded] = useState(false)

  if (isLoading) {
    return null
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0,
        rotate: props.index !== undefined ? (props.index % 2 === 0 ? 3 : -3) : 0,
      }}
      animate={{
        opacity: 1,
        scale: isLoaded ? 1 : 0,
        transition: {
          delay: Math.random() * 0.5,
        },
      }}
      className="bg-white p-2 pb-8 sm:pb-10 ring-1 ring-gray-200 rounded-lg shadow-xl w-fit"
    >
      <img
        onLoad={() => setIsLoaded(true)}
        src={getImageUrl(mediaImage.cloudflareImageId)}
        alt={mediaImage.altText}
        className={`ring-1 ring-gray-300 object-cover rounded-lg ${isSingle ? 'h-48' : 'h-[120px] sm:h-[130px]'} ${isLoaded ? 'bg-gray-100' : 'bg-gray-200'}`}
      />
    </motion.div>
  )
}

function AttendanceBadge(props: {
  meeting: IdentifiableBookable
  course: PublicCourseWithPriceDetailsEntity
  order: OrderEntity | null
}) {
  const course = props.course
  const meeting = props.meeting

  const { t: translate } = useTranslation()

  const [highlight, setHighlight] = useState(false)

  let freeSpots

  if (isPublicMeetingWithBookingInfoEntity(meeting, BookableType.Meeting)) {
    freeSpots =
      course.maximumAttendees -
      meeting.bookable.bookingInfo.claimed -
      meeting.bookable.bookingInfo.reserved -
      meeting.bookable.bookingInfo.booked
  }

  if (isPublicCourseGroupWithBookingInfoEntity(meeting, BookableType.CourseGroup)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const maximumAttendees = meeting.bookable.maximumAttendees ?? course.maximumAttendees

    freeSpots =
      maximumAttendees -
      meeting.bookable.bookingInfo.claimed -
      meeting.bookable.bookingInfo.reserved -
      meeting.bookable.bookingInfo.booked
  }

  const freeSpotsPercentage = Math.round((freeSpots / course.maximumAttendees) * 100)

  freeSpots = Math.max(0, freeSpots)

  return (
    <>
      <Highlight
        trigger={freeSpots}
        duration={200}
        highlightCallback={(highlighting) => setHighlight(highlighting)}
        className="group flex items-center gap-x-1.5"
      >
        <motion.div
          animate={{
            scale: highlight ? 1.1 : 1,
          }}
          transition={{
            type: 'spring',
            bounce: 0.5,
          }}
        >
          <Badge
            variant="outline"
            className="gap-x-1 bg-white transition duration-1000 group-data-[highlight=on]:bg-white group-data-[highlight=on]:duration-150"
          >
            {match(freeSpotsPercentage)
              .with(P.number.between(90, 100), () => (
                <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .with(P.number.between(60, 89), () => (
                <svg className="h-1.5 w-1.5 fill-lime-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .with(P.number.between(60, 89), () => (
                <svg className="h-1.5 w-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .with(P.number.between(1, 59), () => (
                <svg className="h-1.5 w-1.5 fill-orange-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .otherwise((): never => null as never)}
            {freeSpots == 0 ? (
              <Lock className="h-4 w-4 text-gray-400" />
            ) : (
              <>
                <div className="flex items-center">
                  <NumberFlow
                    value={freeSpots}
                    id={meeting.bookable.id + '-freeSpots'}
                    isolate={true}
                    className="mx-1"
                  />
                  {translate('pages.public.booking.badge.availability', {
                    count: freeSpots < 0 ? 0 : freeSpots,
                    claimed: freeSpots,
                  })}
                </div>
              </>
            )}
          </Badge>
        </motion.div>
      </Highlight>
      {meeting.bookable.bookingInfo.claimed > 0 && (
        <Badge
          variant="outline"
          className="bg-gray-300 bg-gradient-to-b from-gray-200 to-gray-100 border border-gray-200 text-gray-600 font-semibold"
        >
          <NumberFlow
            value={meeting.bookable.bookingInfo.claimed}
            id={meeting.bookable.id}
            isolate={true}
            className="mx-1"
          />
          {translate('pages.public.booking.meeting-booking-info.reserved-amount', {
            count: meeting.bookable.bookingInfo.claimed,
          })}
        </Badge>
      )}
    </>
  )
}

function MeetingInstructors(props: { meetingId: string }) {
  const { instructors, isLoading, isError } = useGetMeetingInstructorsPublic(props.meetingId)

  if (isLoading) {
    return <Skeleton className="h-5 w-20 rounded-md" />
  }

  if (isError) {
    return <></>
  }

  if (instructors.length === 0) {
    return <></>
  }

  return (
    <div className="flex">
      {instructors.length > 1 ? (
        <UsersRound className="mr-1 size-4 text-gray-500" />
      ) : (
        <UserRound className="mr-1 size-4 text-gray-500" />
      )}
      <div className="flex flex-wrap gap-x-1">
        Mit:
        <p className="font-medium">
          {instructors
            .map((instructor) => `${instructor.firstName} ${instructor.lastName}`)
            .join(', ')}
        </p>
      </div>
    </div>
  )
}
