import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { CustomerMultipassEntity } from '../../../qourses-api-client'

export default function useGetEndCustomerMultipassesForOrder(orderId: string): {
  multipasses: CustomerMultipassEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: multipasses,
    isLoading,
    isError,
  } = useQuery(
    GetEndCustomerMultipassesForOrderQueryKey(orderId),
    async () => {
      if (orderId === undefined) {
        return
      }
      return qoursesApi.paymentCustomer.paymentCustomerControllerGetUseableMultipassesForOrder(
        orderId,
      )
    },
    {
      enabled: !!orderId,
    },
  )

  let isEmpty = true

  if (multipasses === undefined) {
    return { multipasses: [], isLoading, isEmpty, isError }
  }

  if (multipasses.length > 0) {
    isEmpty = false
  }

  return { multipasses, isLoading, isEmpty, isError }
}

export const GetEndCustomerMultipassesForOrderQueryKey = (orderId: string) => [
  'customer',
  'multipasses',
  orderId,
]
