import { Button } from '@/shadcn/components/ui/button'
import { cn } from '@/shadcn/lib/utils.ts'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronUp, Loader2, Save, Trash2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

export function ChangeIndicator(props: {
  changed: boolean
  saveCallback: any
  discardCallback: () => void
  submitting: boolean
  editing: boolean
}) {
  const { t: translate } = useTranslation()
  const [anchoredBottom, setAnchoredBottom] = useState(true)
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null)

  const { changed, saveCallback, discardCallback, submitting, editing } = props

  useEffect(() => {
    const root = document.createElement('div')
    root.className = 'absolute m-auto left-0 right-0'
    document.body.appendChild(root)
    setPortalRoot(root)

    return () => {
      document.body.removeChild(root)
    }
  }, [])

  if (!portalRoot) return null

  const content = (
    <AnimatePresence>
      <div className={cn('fixed inset-x-0 mx-auto', anchoredBottom ? 'bottom-4' : 'top-4')}>
        <AnimatePresence>
          {changed && (
            <motion.div
              className="flex justify-center"
              layout
              drag={true}
              dragSnapToOrigin={true}
              layoutId="change-warning"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                type: 'spring',
                stiffness: 500,
                damping: 30,
                opacity: { delay: 0.2 },
              }}
            >
              <motion.div
                className="bezel flex items-center rounded-2xl bg-gray-700 text-sm text-gray-100 shadow-lg"
                initial={{ width: 'auto', height: 'auto' }}
                animate={{ width: 'auto', height: 'auto' }}
              >
                <div className="flex gap-x-2 p-3">
                  <motion.div
                    className="bezel flex cursor-pointer items-center rounded-lg bg-gray-500 p-2"
                    onClick={() => setAnchoredBottom(!anchoredBottom)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={{ rotate: anchoredBottom ? 0 : 180 }}
                    >
                      <ChevronUp className="size-4 text-gray-200" />
                    </motion.div>
                  </motion.div>
                  <div className="flex items-center gap-x-2">
                    <Button variant="destructive" className="rounded-lg" onClick={discardCallback}>
                      <Trash2 className="mr-2 h-4 w-4" />
                      {translate('pages.form-details.change-discard')}
                    </Button>
                    <Button
                      disabled={!changed || !editing || submitting}
                      onClick={saveCallback}
                      className="rounded-lg"
                      variant="indigo"
                    >
                      {!submitting ? (
                        <>
                          <Save className="mr-2 h-4 w-4" />
                          {translate('pages.form-details.save-changes.button')}
                        </>
                      ) : (
                        <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          {translate('common.loading')}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </AnimatePresence>
  )

  return createPortal(content, portalRoot)
}
