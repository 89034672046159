import { useQuery } from '@tanstack/react-query'
import { PublicCourseWithPriceDetailsEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCoursesPublic(
  organizationId: string,
  tagId: string,
): {
  courses: PublicCourseWithPriceDetailsEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: courses,
    isLoading,
    isError,
  } = useQuery(['public', 'courses', tagId], async () => {
    return qoursesApi.coursePublic.coursePublicControllerGetCourses(organizationId, tagId)
  })

  let isEmpty = true

  if (courses === undefined) {
    return { courses: [], isLoading, isEmpty, isError }
  }

  if (courses.length > 0) {
    isEmpty = false
  }

  return { courses, isLoading, isEmpty, isError }
}
