/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGalleryItemDto } from '../models/CreateGalleryItemDto';
import type { MediaGalleryItemEntity } from '../models/MediaGalleryItemEntity';
import type { MediaImageEntity } from '../models/MediaImageEntity';
import type { UpdateGalleryItemDto } from '../models/UpdateGalleryItemDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MediaService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Media Images
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity All Media Images
     * @throws ApiError
     */
    public mediaControllerGetMediaImages(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<MediaImageEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/media/image',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create a new Media Image
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image has been created
     * @throws ApiError
     */
    public mediaControllerCreateMediaImage(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/media/image',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get a Media Image
     * @param mediaImageId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image found
     * @throws ApiError
     */
    public mediaControllerGetMediaImage(
        mediaImageId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Delete a Media Image
     * @param mediaImageId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image has been deleted
     * @throws ApiError
     */
    public mediaControllerDeleteMediaImage(
        mediaImageId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Check the upload status of a Media Image
     * @param mediaImageId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image has checked
     * @throws ApiError
     */
    public mediaControllerCheckUploadMediaImage(
        mediaImageId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/media/image/{mediaImageId}/checkUpload',
            path: {
                'mediaImageId': mediaImageId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Archive a Media Image
     * @param mediaImageId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image has been archived
     * @throws ApiError
     */
    public mediaControllerArchiveMediaImage(
        mediaImageId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/media/image/{mediaImageId}/archive',
            path: {
                'mediaImageId': mediaImageId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Restore a Media Image
     * @param mediaImageId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image has been restored
     * @throws ApiError
     */
    public mediaControllerRestoreMediaImage(
        mediaImageId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/media/image/{mediaImageId}/restore',
            path: {
                'mediaImageId': mediaImageId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Acknowledge Media Image Uploader Review
     * @param mediaImageId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MediaImageEntity Media Image has been acknowledged
     * @throws ApiError
     */
    public mediaControllerAcknowledgeMediaImageUploaderReview(
        mediaImageId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/media/image/{mediaImageId}/acknowledgeUploaderReview',
            path: {
                'mediaImageId': mediaImageId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create a new Gallery Item
     * @param requestBody
     * @returns MediaGalleryItemEntity Gallery Item has been created
     * @throws ApiError
     */
    public mediaControllerCreateGalleryItem(
        requestBody: CreateGalleryItemDto,
    ): CancelablePromise<MediaGalleryItemEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/media/gallery/item',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update a Gallery Item
     * @param galleryItemId
     * @param requestBody
     * @returns MediaGalleryItemEntity Gallery Item has been updated
     * @throws ApiError
     */
    public mediaControllerUpdateGalleryItem(
        galleryItemId: string,
        requestBody: UpdateGalleryItemDto,
    ): CancelablePromise<MediaGalleryItemEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/media/gallery/item/{galleryItemId}',
            path: {
                'galleryItemId': galleryItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a Gallery Item
     * @param galleryItemId
     * @returns MediaGalleryItemEntity Gallery Item has been deleted
     * @throws ApiError
     */
    public mediaControllerDeleteGalleryItem(
        galleryItemId: string,
    ): CancelablePromise<MediaGalleryItemEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/media/gallery/item/{galleryItemId}',
            path: {
                'galleryItemId': galleryItemId,
            },
        });
    }
}
