/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormEntity } from '../models/FormEntity';
import type { FormQuestionEntity } from '../models/FormQuestionEntity';
import type { FormQuestionSubmissionEntity } from '../models/FormQuestionSubmissionEntity';
import type { FormSubmissionEntity } from '../models/FormSubmissionEntity';
import type { PatchFormDto } from '../models/PatchFormDto';
import type { PatchFormQuestionDto } from '../models/PatchFormQuestionDto';
import type { PostFormDto } from '../models/PostFormDto';
import type { PostFormQuestionDto } from '../models/PostFormQuestionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SurveyService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Form
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormEntity Form has been returned
     * @throws ApiError
     */
    public surveyControllerCreateForm(
        requestBody: PostFormDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<FormEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/survey/form',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Form has not been found`,
            },
        });
    }
    /**
     * Get all Forms
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormEntity Forms have been returned
     * @throws ApiError
     */
    public surveyControllerGetAllForms(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<FormEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/survey/form',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get a Form
     * @param formId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormEntity Form has been returned
     * @throws ApiError
     */
    public surveyControllerGetForm(
        formId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<FormEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/survey/form/{formId}',
            path: {
                'formId': formId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Form has not been found`,
            },
        });
    }
    /**
     * Update a Form
     * @param formId
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormEntity Form has been returned
     * @throws ApiError
     */
    public surveyControllerUpdateForm(
        formId: string,
        requestBody: PatchFormDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<FormEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/survey/form/{formId}',
            path: {
                'formId': formId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Form has not been found`,
            },
        });
    }
    /**
     * Get Form Questions of Form
     * @param formId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormQuestionEntity Form Questions have been returned
     * @throws ApiError
     */
    public surveyControllerGetFormQuestions(
        formId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<FormQuestionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/survey/form/{formId}/formQuestions',
            path: {
                'formId': formId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get Form Questions of Form
     * @param formSubmissionId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormQuestionEntity Form Submission Questions have been returned
     * @throws ApiError
     */
    public surveyControllerGetFormSubmissionQuestions(
        formSubmissionId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<FormQuestionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/survey/formSubmission/{formSubmissionId}/formQuestions',
            path: {
                'formSubmissionId': formSubmissionId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get Form Submissions of Form
     * @param formId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormSubmissionEntity Form Submissions have been returned
     * @throws ApiError
     */
    public surveyControllerGetFormSubmissions(
        formId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<FormSubmissionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/survey/form/{formId}/formSubmissions',
            path: {
                'formId': formId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create a Form Question
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormQuestionEntity Forms Question has been created
     * @throws ApiError
     */
    public surveyControllerCreateFormQuestion(
        requestBody: PostFormQuestionDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<FormQuestionEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/survey/formQuestion',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Forms has not been found`,
            },
        });
    }
    /**
     * Update a Form Question
     * @param formQuestionId
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormQuestionEntity Form Question has been returned
     * @throws ApiError
     */
    public surveyControllerUpdateFormQuestion(
        formQuestionId: string,
        requestBody: PatchFormQuestionDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<FormQuestionEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/survey/formQuestion/{formQuestionId}',
            path: {
                'formQuestionId': formQuestionId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Form Question has not been found`,
            },
        });
    }
    /**
     * Delete a Form Question
     * @param formQuestionId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormQuestionEntity Form Question has been deleted
     * @throws ApiError
     */
    public surveyControllerDeleteFormQuestion(
        formQuestionId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<FormQuestionEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/survey/formQuestion/{formQuestionId}',
            path: {
                'formQuestionId': formQuestionId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Form Question has not been found`,
            },
        });
    }
    /**
     * Get Form Question Submissions of Form Submission
     * @param formSubmissionId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns FormQuestionSubmissionEntity Form Question Submissions have been returned
     * @throws ApiError
     */
    public surveyControllerGetFormQuestionSubmissionsOfFormSubmission(
        formSubmissionId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<FormQuestionSubmissionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/survey/formSubmission/{formSubmissionId}/formQuestionSubmissions',
            path: {
                'formSubmissionId': formSubmissionId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
}
