/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicLocationEntity } from '../models/PublicLocationEntity';
import type { PublicMediaGalleryItemEntity } from '../models/PublicMediaGalleryItemEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LocationPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a Location
     * @param locationId
     * @returns PublicLocationEntity Location has been returned
     * @throws ApiError
     */
    public locationPublicControllerGetLocation(
        locationId: string,
    ): CancelablePromise<PublicLocationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/location/{locationId}',
            path: {
                'locationId': locationId,
            },
            errors: {
                404: `Location has not been found`,
            },
        });
    }
    /**
     * Get all Media Gallery Items of a Location
     * @param locationId
     * @returns PublicMediaGalleryItemEntity Media Gallery Items have been returned
     * @throws ApiError
     */
    public locationPublicControllerGetLocationMediaGalleryItems(
        locationId: string,
    ): CancelablePromise<Array<PublicMediaGalleryItemEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/location/{locationId}/mediaGalleryItems',
            path: {
                'locationId': locationId,
            },
            errors: {
                404: `Location has not been found`,
            },
        });
    }
}
