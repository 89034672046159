import { useQuery } from '@tanstack/react-query'
import { TermsOfServiceEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetTermsOfServicePublic(termsOfServiceId: string): {
  termsOfService: TermsOfServiceEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: termsOfService,
    isLoading,
    isError,
  } = useQuery(['public', 'termsOfService', termsOfServiceId], async () => {
    return qoursesApi.legalPublic.legalPublicControllerGetTermsOfServiceById(termsOfServiceId)
  })

  if (termsOfService === undefined) {
    return { termsOfService: {} as TermsOfServiceEntity, isLoading, isError }
  }

  return { termsOfService, isLoading, isError }
}
