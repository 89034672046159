/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MediaImageEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    type: MediaImageEntity.type;
    status: MediaImageEntity.status;
    organizationId: string | null;
    locationId: string | null;
    instructorId: string | null;
    customerId: string | null;
    cloudflareImageId: string;
    imageUploadUrl: string;
    altText: string | null;
    visualImpressionTimeSeconds: number | null;
    nsfw: boolean | null;
    qualityScore: number | null;
    moderationNotes: string | null;
    improvementNotes: string | null;
    archived: boolean;
};
export namespace MediaImageEntity {
    export enum type {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        CUSTOMER = 'CUSTOMER',
    }
    export enum status {
        DRAFT = 'DRAFT',
        UPLOADED = 'UPLOADED',
        PROCESSING = 'PROCESSING',
        APPROVED = 'APPROVED',
        UPLOADER_REVIEW = 'UPLOADER_REVIEW',
        PLATFORM_REVIEW = 'PLATFORM_REVIEW',
        REJECTED = 'REJECTED',
    }
}

