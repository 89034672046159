/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaImageEntity } from '../models/MediaImageEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MediaCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Media Images
     * @returns MediaImageEntity All Media Images
     * @throws ApiError
     */
    public mediaCustomerControllerGetMediaImages(): CancelablePromise<Array<MediaImageEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/media/image',
        });
    }
    /**
     * Create a new Media Image
     * @returns MediaImageEntity Media Image has been created
     * @throws ApiError
     */
    public mediaCustomerControllerCreateMediaImage(): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/media/image',
        });
    }
    /**
     * Get a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image found
     * @throws ApiError
     */
    public mediaCustomerControllerGetMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
    /**
     * Delete a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image has been deleted
     * @throws ApiError
     */
    public mediaCustomerControllerDeleteMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/customer/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
    /**
     * Check the upload status of a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image has checked
     * @throws ApiError
     */
    public mediaCustomerControllerCheckUploadMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/media/image/{mediaImageId}/checkUpload',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
    /**
     * Archive a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image has been archived
     * @throws ApiError
     */
    public mediaCustomerControllerArchiveMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/media/image/{mediaImageId}/archive',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
    /**
     * Restore a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image has been restored
     * @throws ApiError
     */
    public mediaCustomerControllerRestoreMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/media/image/{mediaImageId}/restore',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
}
