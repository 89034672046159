import { OrganizationLegalNameOrInstructorLegalName } from '@/components/Resolvers.tsx'
import useGetCurrentOrganizationPaymentProfile from '@/hooks/onboarding/useGetCurrentOrganizationPaymentProfile.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { BadgePercent } from 'lucide-react'
import { useEffect, useState } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { useTranslation } from 'react-i18next'
import { StripePaymentProfileEntity } from '../../../qourses-api-client'

export default function PriceInput(props: {
  setPriceInMills: (value: number) => void
  priceInMills: number
  title: string
  subtitle: string
  setTaxIncluded: (value: boolean) => void
  taxIncluded: boolean
  disabled?: boolean
  overridePaymentProfile?: StripePaymentProfileEntity
  disableTaxSwitch?: boolean // New prop to disable the tax switch
  overrideParentSettingsHint?: boolean
}) {
  const { t: translate, i18n } = useTranslation()
  const locale = i18n.language.includes('de') ? 'de-DE' : 'en-US'

  const [value, setValue] = useState<string | undefined>('')

  const { paymentProfile } = useGetCurrentOrganizationPaymentProfile()

  // We may override the payment profile with one via props if we want to use a different one
  const taxRate = props.overridePaymentProfile
    ? props.overridePaymentProfile.taxRateInPercent
    : paymentProfile?.taxRateInPercent
  const hasTax = taxRate > 0

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (value, _name, values) => {
    setValue(value)
    const mills = Math.round(values.float * 1000)
    props.setPriceInMills(mills)
  }

  useEffect(() => {
    if (props.priceInMills > 0) {
      const value = parseFloat((props.priceInMills / 1000).toFixed(2)).toString()
      setValue(value)
    }
  }, [props.priceInMills])

  useEffect(() => {
    if (props.taxIncluded) {
      props.setTaxIncluded(true)
    } else {
      props.setTaxIncluded(false)
    }
  }, [props.taxIncluded])

  const calculatePriceWithTax = (price: number) => price * (1 + taxRate / 100)
  const calculatePriceWithoutTax = (price: number) => price / (1 + taxRate / 100)

  return (
    <>
      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
        {props.title}
        {props.overrideParentSettingsHint && (
          <p className="text-xs text-muted-foreground font-normal italic ">
            {translate('pages.create-courseGroup.byPassesCourseSettingsHint')}
          </p>
        )}
      </label>
      <div className="flex items-center gap-x-2 mb-2">
        <div className="relative mt-2 w-3/4 rounded-md sm:w-2/4">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">€</span>
          </div>
          <CurrencyInput
            id="coursePriceCents"
            name="coursePriceCents"
            intlConfig={{
              locale: locale,
            }}
            disabled={props.disabled ?? false}
            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-600 disabled:opacity-50 disabled:ring-gray-300 sm:leading-6"
            decimalsLimit={2}
            decimalScale={2}
            value={value}
            onValueChange={handleOnValueChange}
            allowNegativeValue={false}
            disableAbbreviations={true}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-sm text-gray-500" id="price-currency">
              {translate('inputs.price.inputLabel')}
            </span>
          </div>
        </div>
        {hasTax && !props.disableTaxSwitch && (
          <Button
            disabled={props.disabled}
            variant={'outline'}
            className="mt-2 bg-gray-200"
            onClick={(e) => {
              e.preventDefault()
              props.setTaxIncluded(!props.taxIncluded)
            }}
          >
            {props.taxIncluded
              ? translate('inputs.price.taxSwitch.button.include')
              : translate('inputs.price.taxSwitch.button.exclude')}
            <BadgePercent className="ml-1 size-4" />
          </Button>
        )}
      </div>
      {props.priceInMills > 0 && hasTax && !props.disableTaxSwitch && (
        <div className="p-1 text-xs text-muted-foreground">
          {props.taxIncluded
            ? translate('inputs.price.taxSwitch.hint.exclude', {
                price: calculatePriceWithoutTax(props.priceInMills / 1000).toFixed(2),
              })
            : translate('inputs.price.taxSwitch.hint.include', {
                price: calculatePriceWithTax(props.priceInMills / 1000).toFixed(2),
                taxRate: taxRate,
              })}
        </div>
      )}
      {!hasTax && (
        <div className="p-1 text-xs text-muted-foreground">
          {translate('inputs.price.taxSwitch.hint.noTax')}
        </div>
      )}
      {props.overridePaymentProfile && (
        <p className="pl-1 text-xs font-normal text-muted-foreground">
          Es werden die Zahlungseinstellungen von{' '}
          <span className="font-semibold">
            <OrganizationLegalNameOrInstructorLegalName
              stripePaymentProfile={props.overridePaymentProfile}
            />
          </span>{' '}
          verwendet
        </p>
      )}
      <p className="mt-2 text-sm leading-6 text-gray-600">{props.subtitle}</p>
    </>
  )
}
