import { qoursesApiInstructor } from '@/api/qourses-instructor.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useGetCustomerInstructor from '@/hooks/instructor/useGetCustomerInstructor.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { Input } from '@/shadcn/components/ui/input.tsx'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { Textarea } from '@/shadcn/components/ui/textarea.tsx'
import { ToastVariant, minDelay, sendNotification } from '@/utils.tsx'
import { Loader2, Signature } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function UpdateInstructorProfileModal({ forceUpdate }: { forceUpdate?: boolean }) {
  const { instructor, isLoading } = useGetCustomerInstructor()

  const [isLoadingSubmit, setLoadingSubmit] = useState(false)

  const [bio, setBio] = useState('')
  const [handle, setHandle] = useState('')

  const { t: translate } = useTranslation()

  useEffect(() => {
    setBio(instructor?.bio || '')
    setHandle(instructor?.handle || '')
  }, [instructor, isLoading])

  const handleUpdateInstructorProfile = async () => {
    try {
      setLoadingSubmit(true)
      if (instructor.handle === handle) {
        await minDelay(
          qoursesApiInstructor.profileCustomer.profileCustomerControllerPatchProfileInstructor({
            bio: bio,
          }),
          1000,
        )
      } else {
        await minDelay(
          qoursesApiInstructor.profileCustomer.profileCustomerControllerPatchProfileInstructor({
            bio: bio,
            handle: handle,
          }),
          1000,
        )
      }
      sendNotification(
        translate('modals.updateInstructorProfileModal.notification.title'),
        translate('modals.updateInstructorProfileModal.notification.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (e) {
      // Probably because the handle already exists
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.status === 400) {
        sendNotification(
          translate('modals.updateInstructorProfileModal.errorStateHandle.title'),
          translate('modals.updateInstructorProfileModal.errorStateHandle.subtitle'),
          ToastVariant.Error,
        )
      } else {
        sendNotification(
          translate('modals.updateInstructorProfileModal.errorState.title'),
          translate('modals.updateInstructorProfileModal.errorState.subtitle'),
          ToastVariant.Error,
        )
      }
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <Dynamic.Content className="p-6 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {forceUpdate
            ? translate('modals.updateInstructorProfileModal.titleForce')
            : translate('modals.updateInstructorProfileModal.title')}
        </DialogTitle>
        <DialogDescription>
          {forceUpdate
            ? translate('modals.updateInstructorProfileModal.subtitleForce')
            : translate('modals.updateInstructorProfileModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div className="mb-8 mt-4 space-y-8">
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="handle" className="mb-1">
            {translate('modals.updateInstructorProfileModal.handle.label')}
          </Label>
          <Input
            id="handle"
            value={handle}
            placeholder={translate('modals.updateInstructorProfileModal.handle.placeholder')}
            className="col-span-3"
            onChange={(e) => {
              // remove all spaces from input
              setHandle(e.target.value.replace(/\s+/g, ''))
            }}
          />
          <p className="text-xs text-muted-foreground">
            {translate('modals.updateInstructorProfileModal.handle.subtitle')}
          </p>
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="name" className="mb-1">
            {translate('modals.updateInstructorProfileModal.bio.label')}
          </Label>
          <Textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder={translate('modals.updateInstructorProfileModal.bio.placeholder')}
          />
          <p className="text-xs text-muted-foreground">
            {translate('modals.updateInstructorProfileModal.bio.subtitle')}
          </p>
        </div>
      </div>
      <Button
        variant="indigo"
        disabled={isLoadingSubmit || !bio || !handle || handle.includes(' ')}
        onClick={handleUpdateInstructorProfile}
      >
        {isLoadingSubmit ? (
          <>
            {translate('common.loading')}
            <Loader2 className="ml-2 h-4 w-4 animate-spin" />
          </>
        ) : (
          <>
            {translate('modals.updateInstructorProfileModal.button')}
            <Signature className="ml-2 h-4 w-4" />
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
