import Pin from '@/components/MapPin.tsx'
import useDebounceFunction from '@/hooks/util/useDebounceFunction.tsx'
import { useEffect, useRef, useState } from 'react'
import Map, { FullscreenControl, Marker } from 'react-map-gl/mapbox'
import { MapRef } from 'react-map-gl/mapbox-legacy'

export default function LocationMapPreview(props: { lat: number; lon: number }) {
  const mapboxAccessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

  const mapRef = useRef<MapRef>()

  const [latitude, setLatitude] = useState(props.lat)
  const [longitude, setLongitude] = useState(props.lon)

  const [isMapLoaded, setIsMapLoaded] = useState(false)

  const [hasInteracted, setHasInteracted] = useState(false)

  const defaultZoom = 16
  const defaultBearing = 0
  const defaultPitch = 30

  useEffect(() => {
    setLatitude(props.lat)
    setLongitude(props.lon)
    if (isMapLoaded) {
      mapRef.current?.flyTo({
        center: [props.lon, props.lat],
        zoom: defaultZoom,
        bearing: defaultBearing,
        pitch: defaultPitch,
        duration: 2000,
      })
    }
  }, [props.lon, props.lat])

  function detectAmbientLightSituation() {
    const currentHour = new Date().getHours()

    // Time ranges for different light situations
    if (currentHour >= 6 && currentHour < 12) {
      return 'dawn' // Morning hours (6am to 11:59am)
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'day' // Afternoon hours (12pm to 5:59pm)
    } else if (currentHour >= 18 && currentHour < 21) {
      return 'dusk' // Evening hours (6pm to 8:59pm)
    } else {
      return 'night' // Night hours (9pm to 5:59am)
    }
  }

  const transitionBackToInitialView = () => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      zoom: defaultZoom,
      bearing: defaultBearing,
      pitch: defaultPitch,
      duration: 2000,
    })
  }

  const debouncedTransitionOnIdle = useDebounceFunction(transitionBackToInitialView, 2000)

  return (
    <Map
      reuseMaps={true}
      ref={mapRef}
      style={{
        borderRadius: '1rem',
      }}
      onIdle={() => {
        if (hasInteracted) {
          debouncedTransitionOnIdle()
          setHasInteracted(false)
        }
      }}
      mapboxAccessToken={mapboxAccessToken}
      pitch={defaultPitch}
      onDrag={() => {
        setHasInteracted(true)
      }}
      onLoad={(event) => {
        const mapContainer = event.target.getContainer()
        mapContainer.style.width = '100%'
        mapContainer.style.height = '300px'

        const map = event.target

        map.setConfigProperty('basemap', 'lightPreset', detectAmbientLightSituation())

        // map.addSource('transit', {
        //   type: 'vector',
        //   url: 'mapbox://mapbox.mapbox-streets-v8',
        // })
        //
        // map.addLayer({
        //   id: 'transit-bus',
        //   type: 'line',
        //   source: 'transit',
        //   'source-layer': 'transit',
        //   filter: ['==', ['get', 'class'], 'bus'],
        //   layout: {
        //     visibility: 'visible',
        //   },
        //   paint: {
        //     'line-color': '#12a560',
        //     'line-width': 1.5,
        //   },
        // })
        setIsMapLoaded(true)
      }}
      initialViewState={{
        longitude: longitude,
        latitude: latitude,
        zoom: defaultZoom,
        bearing: defaultBearing,
      }}
      mapStyle="mapbox://styles/mapbox/standard"
    >
      <FullscreenControl />
      <Marker
        key={longitude.toString() + latitude.toString()}
        anchor="bottom"
        offset={[0, 0]}
        longitude={longitude}
        latitude={latitude}
        draggable={false}
      >
        <Pin />
      </Marker>
    </Map>
  )
}
