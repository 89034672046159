import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PublicRebateCodeEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCouponOfOrderPublic(orderId: string): {
  coupon: PublicRebateCodeEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: coupon,
    isLoading,
    isError,
  } = useQuery(
    GetCouponOfOrderPublic(orderId ? orderId : ''),
    async () => {
      return qoursesApi.paymentPublic.paymentPublicControllerGetOrderRebateCode(orderId)
    },
    {
      retry: (failureCount, error: AxiosError) => {
        if (!error) return false
        if (
          error.status === 400 ||
          error.message === 'not executing useGetCouponOfOrderPublic because orderId is not defined'
        ) {
          return false
        }

        return failureCount < 3
      },
      enabled: orderId !== undefined && orderId !== null,
    },
  )

  if (coupon === undefined) {
    return { coupon: {} as PublicRebateCodeEntity, isLoading, isError }
  }

  return { coupon, isLoading, isError }
}

export const GetCouponOfOrderPublic = (orderId: string) => ['public', 'order', orderId, 'coupon']
