/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicMediaGalleryItemEntity } from '../models/PublicMediaGalleryItemEntity';
import type { PublicOrganizationEntity } from '../models/PublicOrganizationEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get an Organization
     * @param organizationId
     * @returns PublicOrganizationEntity Organization has been returned
     * @throws ApiError
     */
    public organizationPublicControllerGetPublicOrganization(
        organizationId: string,
    ): CancelablePromise<PublicOrganizationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/organization/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Get an Organization by its slug
     * @param organizationSlug
     * @returns PublicOrganizationEntity Organization has been returned
     * @throws ApiError
     */
    public organizationPublicControllerGetPublicOrganizationBySlug(
        organizationSlug: string,
    ): CancelablePromise<PublicOrganizationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/organization/slug/{organizationSlug}',
            path: {
                'organizationSlug': organizationSlug,
            },
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Get Organization Gallery Items
     * @param organizationId
     * @returns PublicMediaGalleryItemEntity Media Gallery Items have been returned
     * @throws ApiError
     */
    public organizationPublicControllerGetPublicOrganizationMediaGalleryItems(
        organizationId: string,
    ): CancelablePromise<Array<PublicMediaGalleryItemEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/organization/{organizationId}/gallery',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Get Organization JSON-LD
     * @param organizationId
     * @returns any Organization JSON-LD has been returned
     * @throws ApiError
     */
    public organizationPublicControllerGetPublicOrganizationJsonLd(
        organizationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/organization/{organizationId}/jsonld',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
}
