import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { PublicMediaGalleryItemEntity } from '../../../qourses-api-client'

export default function useGetLocationMediaGalleryItemsPublic(locationId: string): {
  mediaGalleryItems: PublicMediaGalleryItemEntity[]
  isLoading: boolean
  isEmptyMediaImages: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: mediaGalleryItems,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(
    getLocationMediaGalleryItemsQueryKey(locationId),
    async () => {
      return qoursesApi.locationPublic.locationPublicControllerGetLocationMediaGalleryItems(
        locationId,
      )
    },
    {
      enabled: !!locationId,
    },
  )

  const isEmpty = !mediaGalleryItems || mediaGalleryItems.length === 0

  return {
    mediaGalleryItems: mediaGalleryItems || [],
    isLoading,
    isEmptyMediaImages: isEmpty,
    isError,
    refetch,
    isRefetching,
  }
}

export const getLocationMediaGalleryItemsQueryKey = (locationId: string) => [
  'public',
  'galleryItems',
  'location',
  locationId,
]
