/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CourseEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    stripePaymentProfileId: string;
    locationId: string | null;
    description: string;
    maximumAttendees: number | null;
    publiclyVisible: boolean;
    priceInMills: number;
    termsOfServiceId: string | null;
    revocationPolicyId: string | null;
    autoSchedulingType: CourseEntity.autoSchedulingType;
    archived: boolean;
    formSubmissionRequiredByDaysBefore: number;
};
export namespace CourseEntity {
    export enum autoSchedulingType {
        OFF = 'OFF',
        REMINDER = 'REMINDER',
        COPILOT = 'COPILOT',
        AUTOMATIC = 'AUTOMATIC',
    }
}

