import useGetUserOrganizations from '@/hooks/organization/useGetUserOrganizations.tsx'
import { CustomerAppBanner } from '@/pages/public/booking/Bookings.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/shadcn/components/ui/collapsible.tsx'
import { APP_METADATA_CLAIM_NAMESPACE } from '@/SidebarLayoutOutlet.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { Switch } from '@headlessui/react'
import {
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronDown, ChevronUp, Loader2 } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { PostDismissHelpCardDto } from '../../qourses-api-client'
import { qoursesApi } from '../api/qourses.tsx'
import LoadingSpinner from '../components/LoadingSpinner.tsx'
import useGetUserCommunicationPreferences from '../hooks/notifications/useGetUserCommunicationPreferences.tsx'
import useGetUserNotificationPreferences from '../hooks/notifications/useGetUserNotificationPreferences.tsx'
import useGetUserOrganizationPreferences from '../hooks/notifications/useGetUserOrganizationPreferences.tsx'
import {
  ToastVariant,
  classNames,
  minDelay,
  sendGenericErrorNotification,
  sendNotification,
} from '../utils.tsx'
import { Title } from './courses/Title.tsx'

export default function UserSettings() {
  const { t: translate } = useTranslation()

  const { organizations, isLoading } = useGetUserOrganizations()

  return (
    <>
      <div className="mx-auto min-h-dvh bg-white px-4 pb-20 pt-14 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-xl">
          <CustomerAppBanner />
          <AnimatePresence>
            <div className="mt-8">
              <div className="grid grid-cols-1 gap-y-10 pb-12">
                <Title
                  title={translate('pages.personal-settings.communication.title')}
                  subtitle={translate('pages.personal-settings.communication.subtitle')}
                  icon={<ChatBubbleLeftRightIcon />}
                  complete={true}
                  hideCompletion={true}
                />
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8">
                  <CommunicationPreferences />
                </div>
                {!isLoading && (
                  <>
                    <Title
                      title={translate('pages.personal-settings.notification.title')}
                      subtitle={translate('pages.personal-settings.notification.subtitle')}
                      icon={<ChatBubbleBottomCenterTextIcon />}
                      complete={true}
                      hideCompletion={true}
                    />
                    <div className="grid grid-cols-1 gap-y-6 pb-12">
                      <NotificationPreferences />
                      {!isLoading &&
                        organizations &&
                        organizations.map((organization) => (
                          <OrganizationNotificationPreferences
                            key={organization.id}
                            organizationId={organization.id}
                            organizationName={organization.name}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

function CommunicationPreferences() {
  const { t: translate } = useTranslation()

  const { userCommunicationPreferences, isLoading, isError, refetch } =
    useGetUserCommunicationPreferences()

  const [isLoadingSwitchEmail, setIsLoadingSwitchEmail] = useState(false)
  const [isLoadingSwitchApn, setIsLoadingSwitchApn] = useState(false)
  const [isLoadingRestoreHelpCards, setIsLoadingRestoreHelpCards] = useState(false)

  const { user } = useAuth0()

  if (isLoading)
    return (
      <div className="col-span-full gap-y-5">
        <Skeleton className="h-20 w-full" />
        <Skeleton className="mt-4 h-20 w-full" />
      </div>
    )

  if (isError) return <div>error</div>

  if (!userCommunicationPreferences) {
    return null
  }

  const handleSwitchEmail = async () => {
    try {
      setIsLoadingSwitchEmail(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserCommunicationPreferences({
          emailEnabled: !userCommunicationPreferences.emailEnabled,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.communication.email.notification.title'),
        translate('pages.personal-settings.communication.email.notification.subtitle', {
          context: !userCommunicationPreferences.emailEnabled ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setIsLoadingSwitchEmail(false)
    }
  }

  const handleSwitchApn = async () => {
    try {
      setIsLoadingSwitchApn(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserCommunicationPreferences({
          apnEnabled: !userCommunicationPreferences.apnEnabled,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.communication.apn.notification.title'),
        translate('pages.personal-settings.communication.apn.notification.subtitle', {
          context: !userCommunicationPreferences.apnEnabled ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setIsLoadingSwitchApn(false)
    }
  }

  const anyHelpCardDismissed = Object.values(
    user[APP_METADATA_CLAIM_NAMESPACE]?.helpCards || {},
  ).some((dismissed) => dismissed === true)

  const reactivateHelpCards = async () => {
    try {
      setIsLoadingRestoreHelpCards(true)

      const helpCardTypes = Object.values(PostDismissHelpCardDto.helpCard)
      const dismissedCards = new Set<string>()

      // Check token metadata for dismissed cards
      const tokenDismissalStatuses = user[APP_METADATA_CLAIM_NAMESPACE]?.helpCards
      if (tokenDismissalStatuses) {
        helpCardTypes.forEach((cardType) => {
          if (tokenDismissalStatuses[cardType] === true) {
            dismissedCards.add(cardType)
          }
        })
      }

      // Check localStorage for dismissed cards
      helpCardTypes.forEach((cardType) => {
        const localStorageStatus = localStorage.getItem(`helpCard-status-${cardType}`)
        if (localStorageStatus) {
          const parsedStatus = JSON.parse(localStorageStatus)
          // Only consider localStorage if it's newer than token metadata
          if (
            parsedStatus.status === false &&
            (!tokenDismissalStatuses?.updatedAt ||
              new Date(parsedStatus.updatedAt) > new Date(tokenDismissalStatuses.updatedAt))
          ) {
            dismissedCards.delete(cardType)
          }
        }
      })

      // Clear all help card statuses from localStorage
      helpCardTypes.forEach((cardType) => {
        localStorage.removeItem(`helpCard-status-${cardType}`)
      })

      // Only make API calls for cards that are actually dismissed
      const resetPromises = Array.from(dismissedCards).map((cardType) =>
        qoursesApi.profileCustomer.profileCustomerControllerChangeHelpCardDismissalStatus({
          helpCard: cardType as PostDismissHelpCardDto.helpCard,
          status: false, // false means the card should be shown (not dismissed)
        }),
      )

      // Wait for all API calls to complete
      await Promise.all(resetPromises)

      setIsLoadingRestoreHelpCards(false)

      sendNotification(
        translate('pages.personal-settings.communication.helpCards.notification.title'),
        translate('pages.personal-settings.communication.helpCards.notification.subtitle'),
        ToastVariant.Success,
      )

      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification(e)
    }
  }

  return (
    <>
      <div className="col-span-full">
        <SwitchWithLabels
          title={translate('pages.personal-settings.communication.email.title')}
          subtitle={translate('pages.personal-settings.communication.email.subtitle')}
          enabled={userCommunicationPreferences.emailEnabled}
          disabled={false}
          onChange={handleSwitchEmail}
          loading={isLoadingSwitchEmail}
        />
      </div>
      <div className="col-span-full">
        <SwitchWithLabels
          title={translate('pages.personal-settings.communication.apn.title')}
          subtitle={translate('pages.personal-settings.communication.apn.subtitle')}
          enabled={userCommunicationPreferences.apnEnabled}
          disabled={false}
          onChange={handleSwitchApn}
          loading={isLoadingSwitchApn}
        />
      </div>
      <div className="col-span-full">
        <div className="flex items-start gap-y-4 justify-between flex-col sm:flex-row sm:items-center">
          <span className="flex flex-grow flex-col">
            <span className="text-sm font-medium leading-6 text-gray-900">
              {translate('pages.personal-settings.communication.helpCards.title')}
            </span>
            <span className="w-[90%] text-sm text-gray-500">
              {translate('pages.personal-settings.communication.helpCards.subtitle')}
            </span>
          </span>
          <Button
            variant={'indigo'}
            className="bezel"
            onClick={reactivateHelpCards}
            disabled={isLoadingRestoreHelpCards || !anyHelpCardDismissed}
          >
            {isLoadingRestoreHelpCards ? (
              <Loader2 className="mr-1 h-4 w-4 animate-spin" />
            ) : (
              translate('pages.personal-settings.communication.helpCards.button')
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

function NotificationPreferences() {
  const { t: translate } = useTranslation()

  const { userNotificationPreferences, isLoading, isError, refetch } =
    useGetUserNotificationPreferences()

  const [loadingSwitchBookingNotification, setLoadingSwitchBookingNotification] = useState(false)
  const [loadingSwitchProductNotification, setLoadingSwitchProductNotification] = useState(false)
  const [loadingSwitchOrderNotification, setLoadingSwitchOrderNotification] = useState(false)
  const [loadingSwitchMultipassNotification, setLoadingSwitchMultipassNotification] =
    useState(false)

  if (isLoading)
    return (
      <div className="col-span-full gap-y-5">
        <Skeleton className="h-20 w-full" />
      </div>
    )

  if (isError) return <div>error</div>

  if (!userNotificationPreferences) {
    return null
  }

  const handleSwitchProductUpdates = async () => {
    try {
      setLoadingSwitchProductNotification(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserNotificationPreferences({
          productUpdatesNotifications: !userNotificationPreferences.productUpdatesNotifications,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.notification.product-updates.notification.title'),
        translate('pages.personal-settings.notification.product-updates.notification.subtitle', {
          context: !userNotificationPreferences.productUpdatesNotifications ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setLoadingSwitchProductNotification(false)
    }
  }

  const handleSwitchBookingUpdates = async () => {
    try {
      setLoadingSwitchBookingNotification(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserNotificationPreferences({
          bookingUpdateNotifications: !userNotificationPreferences.bookingUpdateNotifications,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.notification.booking-updates.notification.title'),
        translate('pages.personal-settings.notification.booking-updates.notification.subtitle', {
          context: !userNotificationPreferences.bookingUpdateNotifications ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setLoadingSwitchBookingNotification(false)
    }
  }

  const handleSwitchOrderUpdates = async () => {
    try {
      setLoadingSwitchOrderNotification(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserNotificationPreferences({
          orderNotifications: !userNotificationPreferences.orderNotifications,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.notification.order-updates.notification.title'),
        translate('pages.personal-settings.notification.order-updates.notification.subtitle', {
          context: !userNotificationPreferences.orderNotifications ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setLoadingSwitchOrderNotification(false)
    }
  }

  const handleSwitchMultipassUpdates = async () => {
    try {
      setLoadingSwitchMultipassNotification(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserNotificationPreferences({
          multipassNotifications: !userNotificationPreferences.multipassNotifications,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.notification.multipass-updates.notification.title'),
        translate('pages.personal-settings.notification.multipass-updates.notification.subtitle', {
          context: !userNotificationPreferences.multipassNotifications ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setLoadingSwitchMultipassNotification(false)
    }
  }

  return (
    <div className="col-span-full gap-y-6 flex flex-col mb-4">
      <SwitchWithLabels
        title={translate('pages.personal-settings.notification.product-updates.title')}
        subtitle={translate('pages.personal-settings.notification.product-updates.subtitle')}
        enabled={userNotificationPreferences.productUpdatesNotifications}
        disabled={false}
        onChange={handleSwitchProductUpdates}
        loading={loadingSwitchProductNotification}
      />
      <SwitchWithLabels
        title={translate('pages.personal-settings.notification.booking-updates.title')}
        subtitle={translate('pages.personal-settings.notification.booking-updates.subtitle')}
        enabled={userNotificationPreferences.bookingUpdateNotifications}
        disabled={false}
        onChange={handleSwitchBookingUpdates}
        loading={loadingSwitchBookingNotification}
      />
      <SwitchWithLabels
        title={translate('pages.personal-settings.notification.order-updates.title')}
        subtitle={translate('pages.personal-settings.notification.order-updates.subtitle')}
        enabled={userNotificationPreferences.orderNotifications}
        disabled={false}
        onChange={handleSwitchOrderUpdates}
        loading={loadingSwitchOrderNotification}
      />
      <SwitchWithLabels
        title={translate('pages.personal-settings.notification.multipass-updates.title')}
        subtitle={translate('pages.personal-settings.notification.multipass-updates.subtitle')}
        enabled={userNotificationPreferences.multipassNotifications}
        disabled={false}
        onChange={handleSwitchMultipassUpdates}
        loading={loadingSwitchMultipassNotification}
      />
    </div>
  )
}

function OrganizationNotificationPreferences(props: {
  organizationId: string
  organizationName: string
}) {
  const { organizationUserNotificationPreferences, isLoading, isError, refetch } =
    useGetUserOrganizationPreferences(props.organizationId)

  const { t: translate } = useTranslation()

  const [openOrganizationNotificationPreferences, setOpenOrganizationNotificationPreferences] =
    useState(false)

  const [isLoadingRenamedNotifications, setIsLoadingRenamedNotifications] = useState(false)

  const [isLoadingUserAddedNotifications, setIsLoadingUserAddedNotifications] = useState(false)

  if (isLoading) {
    return <div>{translate('common.loading')}</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (!organizationUserNotificationPreferences) {
    return null
  }

  const handleSwitchOrganizationRenamedNotifications = async () => {
    try {
      setIsLoadingRenamedNotifications(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateOrganizationUserNotificationPreferences(
          props.organizationId,
          {
            organizationRenamedNotifications:
              !organizationUserNotificationPreferences.organizationRenamedNotifications,
          },
        ),
        1000,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setIsLoadingRenamedNotifications(false)
    }
  }

  const handleSwitchUserAddedNotifications = async () => {
    try {
      setIsLoadingUserAddedNotifications(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateOrganizationUserNotificationPreferences(
          props.organizationId,
          {
            userAddedToOrganizationNotifications:
              !organizationUserNotificationPreferences.userAddedToOrganizationNotifications,
          },
        ),
        1000,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification(e)
    } finally {
      setIsLoadingUserAddedNotifications(false)
    }
  }

  return (
    <div className="col-span-full">
      <Collapsible
        open={openOrganizationNotificationPreferences}
        onOpenChange={setOpenOrganizationNotificationPreferences}
        className="col-span-full space-y-2 rounded-lg bg-gray-50 p-5 ring-1 ring-gray-300"
      >
        <div className="flex items-center justify-between space-x-4">
          <div className="flex flex-col">
            <h4 className="text-md font-medium">{props.organizationName}</h4>
            <p className="text-xs text-gray-600">
              {translate('pages.personal-settings.notification.organization.title')}
            </p>
          </div>
          <CollapsibleTrigger asChild>
            <Button variant="secondary" className="w-9 bg-white p-0">
              {!openOrganizationNotificationPreferences ? (
                <ChevronDown className="h-4 w-4" />
              ) : (
                <ChevronUp className="h-4 w-4" />
              )}
            </Button>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent className="space-y-2 pt-4">
          <div className="mb-4">
            <SwitchWithLabels
              title={translate('pages.personal-settings.notification.organization.userAdded.title')}
              subtitle={translate(
                'pages.personal-settings.notification.organization.userAdded.subtitle',
              )}
              enabled={organizationUserNotificationPreferences.userAddedToOrganizationNotifications}
              disabled={false}
              onChange={handleSwitchUserAddedNotifications}
              loading={isLoadingUserAddedNotifications}
            />
          </div>
          <div className="mb-4">
            <SwitchWithLabels
              title={translate(
                'pages.personal-settings.notification.organization.organizationRenamed.title',
              )}
              subtitle={translate(
                'pages.personal-settings.notification.organization.organizationRenamed.subtitle',
              )}
              enabled={organizationUserNotificationPreferences.organizationRenamedNotifications}
              disabled={false}
              onChange={handleSwitchOrganizationRenamedNotifications}
              loading={isLoadingRenamedNotifications}
            />
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}

export function SwitchWithLabels(props: {
  title: string | React.ReactNode
  subtitle: string
  enabled: boolean
  disabled: boolean
  loading: boolean
  onChange: () => void
}) {
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          {props.title}
        </Switch.Label>
        <Switch.Description as="span" className="w-[90%] text-sm text-gray-500">
          {props.subtitle}
        </Switch.Description>
      </span>
      <Switch
        disabled={props.disabled}
        checked={props.enabled}
        onChange={props.onChange}
        className={classNames(
          props.enabled ? 'bg-indigo-600 disabled:bg-gray-600' : 'bg-gray-200 disabled:bg-gray-600',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <AnimatePresence>
            {props.loading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="h-5 w-5 border-2 border-transparent"
              >
                <LoadingSpinner className="" />
              </motion.div>
            )}
          </AnimatePresence>
        </span>
      </Switch>
    </Switch.Group>
  )
}
