/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateMediaImageDto = {
    status: UpdateMediaImageDto.status;
    moderationNotes: string;
    improvementNotes: string;
    nsfw: boolean;
    qualityScore: number;
};
export namespace UpdateMediaImageDto {
    export enum status {
        DRAFT = 'DRAFT',
        UPLOADED = 'UPLOADED',
        PROCESSING = 'PROCESSING',
        APPROVED = 'APPROVED',
        UPLOADER_REVIEW = 'UPLOADER_REVIEW',
        PLATFORM_REVIEW = 'PLATFORM_REVIEW',
        REJECTED = 'REJECTED',
    }
}

