import { useCallback, useEffect, useRef } from 'react'

/**
 * useDebounceFunction hook
 * This hook allows you to debounce any fast changing function calls. The debounced function will only
 * be called when the useDebounceFunction hook has not been called for the specified delay period.
 *
 * @param func - The function to be debounced.
 * @param delay - The delay in milliseconds for the debounce.
 * @returns A debounced version of the function that can be called.
 */
function useDebounceFunction<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  // Ref for the setTimeout handle
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  // Cleanup function to be called on unmount or delay change
  const cleanup = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  // The debounced function
  const debouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      // Clear existing timeouts
      cleanup()

      // Set a new timeout to call the function after the delay
      timeoutRef.current = setTimeout(() => {
        func(...args)
      }, delay)
    },
    [func, delay],
  ) // Only re-create if function or delay changes

  // Cleanup on unmount
  useEffect(() => {
    return cleanup
  }, [delay])

  return debouncedFunction
}

export default useDebounceFunction
