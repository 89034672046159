import useGetMediaImagePublic from '@/hooks/public/useGetMediaImagePublic.tsx'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { OrganizationEntity } from '../../../qourses-api-client'
import { qoursesApi, setActiveApiOrganization } from '../../api/qourses.tsx'
import { organizationStore } from '../../hydration/organization.tsx'

export default function useOrganization(): {
  organization: OrganizationEntity | undefined
  hasOrganization: boolean
  allOrganizations: OrganizationEntity[] | undefined
  handleOrganizationChange: (organization: OrganizationEntity) => void
  isFetchingOrganizations: boolean
  refetchOrganization: () => void
  organizationImageId: string | null
} {
  const queryClient = useQueryClient()
  const { updateActiveOrganization } = organizationStore((state) => state)

  const organization = queryClient.getQueryData(['activeOrganization']) as
    | OrganizationEntity
    | undefined
  const organizations = queryClient.getQueryData(['organizations']) as
    | OrganizationEntity[]
    | undefined
  const hasOrganization = !isEmpty(organization?.name)

  const { mediaImage } = useGetMediaImagePublic(organization?.logoMediaImageId)

  const refetchOrganization = async () => {
    const organization = await qoursesApi.organization.organizationControllerGetOrganizationBySlug()

    queryClient.setQueryData(['activeOrganization'], organization)
    updateActiveOrganization(organization)
    queryClient.removeQueries(['organizations'])
  }

  const isFetchingOrganizations = useIsFetching({ queryKey: ['organizations'] }) > 0
  const handleOrganizationChange = async (organization: OrganizationEntity) => {
    const currentOrg = queryClient.getQueryData(['activeOrganization']) as
      | OrganizationEntity
      | undefined

    if (currentOrg?.slug === organization.slug) {
      // Do nothing if the organization is already active
      return
    }

    setActiveApiOrganization(organization.slug)
    updateActiveOrganization(organization)

    await queryClient.clear()

    // This might be not the way one should update the query data. But not sure how to do it otherwise.
    queryClient.setQueryData(['activeOrganization'], organization)
  }

  return {
    organization,
    hasOrganization,
    allOrganizations: organizations,
    handleOrganizationChange,
    isFetchingOrganizations,
    refetchOrganization,
    organizationImageId: mediaImage?.cloudflareImageId ?? null,
  }
}
