/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationEntity } from '../models/OrganizationEntity';
import type { PostHighlightedTagsForOrganizationDto } from '../models/PostHighlightedTagsForOrganizationDto';
import type { PostOnboardingDto } from '../models/PostOnboardingDto';
import type { PostRenameOrganizationDto } from '../models/PostRenameOrganizationDto';
import type { PostSetOrganizationLogoMediaImageDto } from '../models/PostSetOrganizationLogoMediaImageDto';
import type { PostUpdateDescriptionOrganizationDto } from '../models/PostUpdateDescriptionOrganizationDto';
import type { UserEntity } from '../models/UserEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get an Organization
     * @returns OrganizationEntity Organization has been returned
     * @throws ApiError
     */
    public organizationControllerGetOrganizationBySlug(): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/organization',
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Get all users in an organization
     * @returns UserEntity All users in the organization have been returned
     * @throws ApiError
     */
    public organizationControllerGetUsersFromOrganization(): CancelablePromise<Array<UserEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/organization/users',
        });
    }
    /**
     * Rename an Organization
     * @param requestBody
     * @returns OrganizationEntity Organization has been renamed
     * @throws ApiError
     */
    public organizationControllerRenameOrganization(
        requestBody: PostRenameOrganizationDto,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/rename',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Set highlighted Tags for an Organization
     * @param requestBody
     * @returns OrganizationEntity Tags have been set
     * @throws ApiError
     */
    public organizationControllerSetHighlightedTagsForOrganization(
        requestBody: PostHighlightedTagsForOrganizationDto,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/highlightedTags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Update an Organization description
     * @param requestBody
     * @returns OrganizationEntity Organization description has been updated
     * @throws ApiError
     */
    public organizationControllerUpdateOrganizationDescription(
        requestBody: PostUpdateDescriptionOrganizationDto,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/description',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
    /**
     * Get all Organizations the user is part of
     * @returns OrganizationEntity Organizations has been returned
     * @throws ApiError
     */
    public organizationControllerGetOrganizationsForUser(): CancelablePromise<Array<OrganizationEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/organization/all',
        });
    }
    /**
     * Create a new organization as a result of the onboarding prompt
     * @param requestBody
     * @returns OrganizationEntity Organization has been created
     * @throws ApiError
     */
    public organizationControllerCreateFirstOrganization(
        requestBody: PostOnboardingDto,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/onboarding',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove a user from an organization
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public organizationControllerDeleteUserFromOrganization(
        userId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/organization/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Set the logo Media Image for an organization
     * @param requestBody
     * @returns OrganizationEntity The Logo Media Image has been set
     * @throws ApiError
     */
    public organizationControllerSetOrganizationLogoMediaImage(
        requestBody: PostSetOrganizationLogoMediaImageDto,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/logo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
