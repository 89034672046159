/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaImageEntity } from '../models/MediaImageEntity';
import type { UpdateMediaImageDto } from '../models/UpdateMediaImageDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MediaAdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Media Images
     * @returns MediaImageEntity All Media Images
     * @throws ApiError
     */
    public mediaAdminControllerGetMediaImages(): CancelablePromise<Array<MediaImageEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/media/image',
        });
    }
    /**
     * Get a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image found
     * @throws ApiError
     */
    public mediaAdminControllerGetMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
    /**
     * Update a Media Image
     * @param mediaImageId
     * @param requestBody
     * @returns MediaImageEntity Media Image updated
     * @throws ApiError
     */
    public mediaAdminControllerUpdateMediaImage(
        mediaImageId: string,
        requestBody: UpdateMediaImageDto,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/admin/media/image/{mediaImageId}',
            path: {
                'mediaImageId': mediaImageId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reject a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image has been rejected
     * @throws ApiError
     */
    public mediaAdminControllerRejectMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/media/image/{mediaImageId}/reject',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
    /**
     * Approve a Media Image
     * @param mediaImageId
     * @returns MediaImageEntity Media Image has been approved
     * @throws ApiError
     */
    public mediaAdminControllerApproveMediaImage(
        mediaImageId: string,
    ): CancelablePromise<MediaImageEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/media/image/{mediaImageId}/approve',
            path: {
                'mediaImageId': mediaImageId,
            },
        });
    }
}
