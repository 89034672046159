import { qoursesApi } from '@/api/qourses.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals, popModal } from '@/components/modals/index.tsx'
import useGetMediaImages from '@/hooks/mediaImages/useGetMediaImages.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ScrollArea } from '@/shadcn/components/ui/scroll-area.tsx'
import {
  getImageUrl,
  sendGenericErrorNotification,
  sendNotification,
  ToastVariant,
} from '@/utils.tsx'
import { Crisp } from 'crisp-sdk-web'
import { motion } from 'framer-motion'
import {
  GlobeLock,
  Loader2,
  ScanEye,
  SearchCheck,
  Sparkles,
  VenetianMask,
  WandSparkles,
} from 'lucide-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { match } from 'ts-pattern'
import { MediaImageEntity } from '../../../qourses-api-client'

export function MediaImageContentReviewModal({ mediaImage }: { mediaImage: MediaImageEntity }) {
  const { t: translate } = useTranslation()

  const { refetch } = useGetMediaImages()

  // refetch once on mount
  useEffect(() => {
    refetch()
  }, [])

  // round the 0-100 score to 0-10
  const roundedMediaScore = Math.round(mediaImage.qualityScore / 10)
  const missedScore = 10 - roundedMediaScore

  const moderationNotesThreshold = 6

  const handleApproveUploaderReview = async () => {
    try {
      await qoursesApi.media.mediaControllerAcknowledgeMediaImageUploaderReview(
        mediaImage.id,
        'false',
      )
      sendNotification(
        translate(
          'modals.mediaImageContentReviewModal.status.acknowledgeUploaderReview.notification-success.title',
        ),
        translate(
          'modals.mediaImageContentReviewModal.status.acknowledgeUploaderReview.notification-success.subtitle',
        ),
        ToastVariant.Success,
      )
      refetch()
      popModal('MediaImageContentReviewModal')
    } catch (e) {
      sendGenericErrorNotification(e)
    }
  }

  const handleSendImageSupportMessage = () => {
    popAllModals()
    Crisp.chat.show()
    Crisp.chat.open()
    Crisp.message.sendText(
      'Hallo! Wir haben bemerkt, dass du Hilfe mit der Bewertung eines Bildes benötigst. **Was genau ist dein Problem mit diesem Bild?** Die Bild-ID lautet: `' +
        mediaImage.id +
        '`',
    )
    Crisp.message.sendFile({
      name: mediaImage.id,
      url: getImageUrl(mediaImage.cloudflareImageId),
      type: 'image/jpg',
    })
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8 max-h-[85vh] h-full sm:h-auto">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2 flex items-center justify-center sm:justify-start">
          <Sparkles className="size-4 mr-2" />
          {translate('modals.mediaImageContentReviewModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('modals.mediaImageContentReviewModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <ScrollArea className="p-2 h-auto" type={'always'}>
        <div className="flex gap-y-8 flex-col my-6 sm:my-3">
          <div>
            <div className="flex font-semibold text-base gap-x-2 items-center flex-wrap">
              {translate('modals.mediaImageContentReviewModal.status.title')}
              {match(mediaImage.status)
                .with(MediaImageEntity.status.APPROVED, () => (
                  <Badge variant="indigoBezel">
                    {translate(
                      'modals.mediaImageContentReviewModal.status.type.' + mediaImage.status,
                    )}
                  </Badge>
                ))
                .with(MediaImageEntity.status.REJECTED, () => (
                  <Badge variant="destructive" className="bezel flex gap-x-1">
                    {translate(
                      'modals.mediaImageContentReviewModal.status.type.' + mediaImage.status,
                    )}
                    <GlobeLock className="size-4" />
                  </Badge>
                ))
                .with(MediaImageEntity.status.PROCESSING, () => (
                  <Badge variant="secondaryBezel" className="flex gap-x-1">
                    {translate(
                      'modals.mediaImageContentReviewModal.status.type.' + mediaImage.status,
                    )}
                    <Loader2 className="size-4 animate-spin" />
                  </Badge>
                ))
                .with(MediaImageEntity.status.UPLOADER_REVIEW, () => (
                  <Badge variant="secondaryBezel" className="flex gap-x-1">
                    {translate(
                      'modals.mediaImageContentReviewModal.status.type.' + mediaImage.status,
                    )}
                    <ScanEye className="size-4 animate-pulse" />
                  </Badge>
                ))
                .with(MediaImageEntity.status.PLATFORM_REVIEW, () => (
                  <Badge variant="secondaryBezel" className="flex gap-x-1">
                    {translate(
                      'modals.mediaImageContentReviewModal.status.type.' + mediaImage.status,
                    )}
                    <ScanEye className="size-4 animate-pulse" />
                  </Badge>
                ))
                .otherwise((): never => null as never)}
              {mediaImage.nsfw && (
                <Badge variant="destructive" className="bezel">
                  {translate('modals.mediaImageContentReviewModal.status.nsfw')}
                  <VenetianMask className="size-4 ml-1" />
                </Badge>
              )}
            </div>
            {mediaImage.status === MediaImageEntity.status.UPLOADER_REVIEW && (
              <Button onClick={handleApproveUploaderReview} variant="indigo" className="my-2">
                {translate(
                  'modals.mediaImageContentReviewModal.status.acknowledgeUploaderReview.button',
                )}
                <SearchCheck className="size-4 ml-1" />
              </Button>
            )}
            <p className="text-muted-foreground text-xs mt-2">
              {translate('modals.mediaImageContentReviewModal.status.subtitle')}
            </p>
            {mediaImage.status !== MediaImageEntity.status.APPROVED && (
              <div
                className="text-muted-foreground font-semibold text-xs mt-2 hover:text-indigo-500 cursor-pointer hover:underline"
                onClick={handleSendImageSupportMessage}
              >
                {translate('modals.mediaImageContentReviewModal.status.supportHint')}
              </div>
            )}
          </div>
          <div>
            <div className="font-semibold text-base">
              {translate('modals.mediaImageContentReviewModal.qualityScore.title')}
            </div>
            <div className="py-2.5">
              <div className="flex gap-x-1">
                {[...Array(roundedMediaScore).keys()].map((i) => (
                  <motion.img
                    initial={{
                      opacity: 0.5,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                    }}
                    transition={{
                      delay: 0.2 + i * 0.05,
                      type: 'spring',
                      bounce: 0.5,
                    }}
                    key={mediaImage.id + i}
                    src="/star.png"
                    className="size-5 saturate-[110%]"
                  />
                ))}
                {[...Array(missedScore).keys()].map((i) => (
                  <motion.img
                    initial={{
                      opacity: 0.1,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 0.2,
                      scale: 1,
                    }}
                    transition={{
                      delay: 0.2 + roundedMediaScore * 0.05 + i * 0.05,
                      type: 'spring',
                      bounce: 0.5,
                    }}
                    key={mediaImage.id + i}
                    src="/star.png"
                    className="size-5 opacity-30"
                  />
                ))}
              </div>
              <p className="text-xs text-muted-foreground mt-1">{roundedMediaScore} / 10</p>
            </div>
            <p className="text-muted-foreground text-xs">
              {translate('modals.mediaImageContentReviewModal.qualityScore.subtitle')}
            </p>
          </div>
          {roundedMediaScore < moderationNotesThreshold ? (
            <div>
              <p className="font-semibold text-base flex items-center gap-x-2">
                {translate('modals.mediaImageContentReviewModal.moderationNote.title')}
                <WandSparkles className="size-4" />
              </p>
              <div className="text-sm mt-2 italic text-gray-700 p-4 shadow-inner ring-1 ring-gray-200 bg-gray-100 rounded-lg">
                {mediaImage.moderationNotes}
              </div>
              <p className="text-muted-foreground text-xs mt-2">
                {translate('modals.mediaImageContentReviewModal.moderationNote.subtitle')}
              </p>
            </div>
          ) : (
            <div>
              <p className="font-semibold text-base">
                {translate('modals.mediaImageContentReviewModal.altText.title')}
              </p>
              <div className="text-sm mt-2 italic text-gray-700 p-4 shadow-inner ring-1 ring-gray-200 bg-gray-100 rounded-lg">
                {mediaImage.altText}
              </div>
              <p className="text-muted-foreground text-xs mt-2">
                {translate('modals.mediaImageContentReviewModal.altText.subtitle')}
              </p>
            </div>
          )}
        </div>
      </ScrollArea>
    </Dynamic.Content>
  )
}
