import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PublicRebateCodeEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetCouponOfBookingPublic(
  orderId: string,
  bookingId: string,
): {
  coupon: PublicRebateCodeEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: coupon,
    isLoading,
    isError,
  } = useQuery(
    GetCouponOfBookingPublic(orderId ? orderId : '', bookingId ? bookingId : ''),
    async () => {
      return qoursesApi.paymentPublic.paymentPublicControllerGetBookingRebateCode(
        orderId,
        bookingId,
      )
    },
    {
      retry: (failureCount, error: AxiosError) => {
        if (!error) return false
        if (
          error.status === 400 ||
          error.message ===
            'not executing useGetCouponOfBookingPublic because bookingId is not defined'
        ) {
          return false
        }

        return failureCount < 3
      },
      enabled: bookingId !== undefined && bookingId !== null,
    },
  )

  if (coupon === undefined) {
    return { coupon: {} as PublicRebateCodeEntity, isLoading, isError }
  }

  return { coupon, isLoading, isError }
}

export const GetCouponOfBookingPublic = (orderId: string, bookingId: string) => [
  'public',
  'order',
  orderId,
  bookingId,
  'coupon',
]
