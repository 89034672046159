import { APP_METADATA_CLAIM_NAMESPACE } from '@/SidebarLayoutOutlet.tsx'
import { qoursesApi, setActiveApiOrganization } from '@/api/qourses.tsx'
import {
  AnimatedCheckCircleIcon,
  AnimatedCheckExclamationCircle,
} from '@/components/AnimatedCheckIcon.tsx'
import { pushModal } from '@/components/modals'
import useGetCourseGroupMeetingsPublic from '@/hooks/public/useGetCourseGroupMeetingsPublic.tsx'
import { GetCourseGroupsPublicQueryKey } from '@/hooks/public/useGetCourseGroupsPublic.tsx'
import { GetCourseMeetingsPublicQueryKey } from '@/hooks/public/useGetCourseMeetingsPublic.tsx'
import useGetMediaImagePublic from '@/hooks/public/useGetMediaImagePublic.tsx'
import useGetMediaGalleryItemsPublic, {
  getMediaGalleryItemsQueryKey,
} from '@/hooks/public/useGetMediaImagesPublic.tsx'
import useGetMultipassProductsPublic from '@/hooks/public/useGetMultipassProductsPublic.tsx'
import { GetOrderBookingsPublicQueryKey } from '@/hooks/public/useGetOrderBookingsPublic.tsx'
import { GetOrderPublicQueryKey } from '@/hooks/public/useGetOrderPublic.tsx'
import useGetTermsOfServicePublic from '@/hooks/public/useGetTermsOfServicePublic.tsx'
import { ResizablePanel } from '@/pages/courses/components/ResizablePanel.tsx'
import {
  addMeetingIdToLocalStorage,
  addOrderToLocalStorage,
  removeMeetingIdsFromLocalStorage,
  removeOrderFromLocalStorage,
} from '@/pages/public/booking/BookingUtilFunctions.tsx'
import {
  courseIdParam,
  customerIdParam,
  demoParam,
  emailParam,
  imageEditModeParam,
  meetingIdParam,
  multipassProductIdParam,
  orderIdParam,
  orderLocalStorageKey,
  organizationIdParam,
  tagIdParam,
} from '@/pages/public/booking/SessionParams.tsx'
import { CourseBrief } from '@/pages/public/booking/components/CourseBrief.tsx'
import { CourseContent } from '@/pages/public/booking/components/CourseContent.tsx'
import { MultipassRow } from '@/pages/public/booking/components/MultipassRow.tsx'
import { OrganizationTags } from '@/pages/public/booking/components/OrganizationTags.tsx'
import { Alert, AlertDescription, AlertTitle } from '@/shadcn/components/ui/alert.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/shadcn/components/ui/collapsible.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import {
  classNames,
  getImageUrl,
  minDelay,
  sendGenericErrorNotification,
  sendNotification,
  ToastVariant,
} from '@/utils.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Turnstile } from '@marsidev/react-turnstile'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import {
  ArrowLeft,
  ArrowRight,
  BookDashed,
  Construction,
  ExternalLink,
  ImageUpscale,
  Info,
  Loader2,
  NotebookText,
  ShoppingCart,
  Ticket,
  Undo,
  Undo2,
  Unlink,
  User,
  X,
} from 'lucide-react'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { NavLink, useSearchParams } from 'react-router-dom'
import {
  OrderEntity,
  PublicCourseGroupWithBookingInfoEntity,
  PublicCourseWithPriceDetailsEntity,
  PublicMediaGalleryItemEntity,
  PublicMeetingWithBookingInfoEntity,
  PublicMultipassProductWithBookingInfoEntity,
} from '../../../../qourses-api-client'
import useGetCoursesPublic from '../../../hooks/public/useGetCoursesPublic.tsx'
import useGetOrganizationPublic from '../../../hooks/public/useGetOrganizationPublic.tsx'
import {
  BookableType,
  CourseMeetings,
  IdentifiableBookable,
  isPublicCourseGroupWithBookingInfoEntity,
  isPublicMeetingWithBookingInfoEntity,
} from './components/CourseMeetings.tsx'
import { CourseRow } from './components/CourseRow.tsx'
import { OrderSummary } from './components/OrderSummary.tsx'

type MediaGalleryItemPosition = 'hero' | 'second' | 'third' | 'fourth' | 'fifth'

export default function Bookings() {
  const timeInMinutesUntilOrderExpires = 720

  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  const turnstileRef = useRef()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedCourse, setSelectedCourse] = useState<PublicCourseWithPriceDetailsEntity | null>(
    null,
  )
  const [selectedMultipass, setSelectedMultipass] =
    useState<PublicMultipassProductWithBookingInfoEntity | null>(null)

  const [identifiableBookable, setSelectedIdentifiableBookable] =
    useState<IdentifiableBookable>(null)

  const [turnstileChallenge, setTurnstileChallenge] = useState<string | null>(null)
  //const [turnstileError, setTurnstileError] = useState<boolean | null>(null)
  const turnstileSiteId = import.meta.env.VITE_TURNSTILE_SITE_ID

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    isLoading: isLoadingAuthentication,
  } = useAuth0()

  const [email, setEmail] = useState<string | null>(null)
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)

  const [order, setOrder] = useState<OrderEntity | null>(null)
  const [orderLoading, setOrderLoading] = useState<boolean>(false)

  const [organizationCollapsed, setOrganizationCollapsed] = useState<boolean>(true)

  const [courseCollapsibleOpen, setCourseCollapsibleOpen] = useState<boolean>(true)
  const [multipassCollapsibleOpen, setMultipassCollapsibleOpen] = useState<boolean>(true)

  const [showMultipasses, setShowMultipasses] = useState<boolean>(false)

  const organizationId = searchParams.get(organizationIdParam)
  const courseId = searchParams.get(courseIdParam)
  const multipassProductId = searchParams.get(multipassProductIdParam)
  //const meetingId = searchParams.get(meetingIdParam)
  const orderId = searchParams.get(orderIdParam)
  const guestEmailParam = searchParams.get(emailParam)
  const tagId = searchParams.get(tagIdParam)
  const customerId = searchParams.get(customerIdParam)
  const demoMode = !!searchParams.get(demoParam)

  const imageEditMode = searchParams.get(imageEditModeParam)

  // TODO: Do we really want to redirect there? Maybe show a message?
  if (!organizationId) {
    window.location.href = 'https://www.qours.es'
  }

  const [selectedTag, setSelectedTag] = useState('')

  const {
    courses,
    isLoading: isLoadingCourses,
    isError: coursesError,
  } = useGetCoursesPublic(organizationId!, selectedTag)

  const {
    organization,
    isLoading: isLoadingOrganization,
    isError: organizationError,
  } = useGetOrganizationPublic(organizationId!)

  const { mediaImage: organizationLogoMediaImage } = useGetMediaImagePublic(
    organization?.logoMediaImageId,
  )

  const { mediaGalleryItems, refetch: refetchMediaGalleryItems } =
    useGetMediaGalleryItemsPublic(organizationId)

  const getHighlightedMediaGalleryWeight = (mediaGalleryItemPosition: MediaGalleryItemPosition) => {
    if (mediaGalleryItemPosition === 'hero') {
      return 0
    }
    if (mediaGalleryItemPosition === 'second') {
      return 100000
    }
    if (mediaGalleryItemPosition === 'third') {
      return 200000
    }
    if (mediaGalleryItemPosition === 'fourth') {
      return 300000
    }
    if (mediaGalleryItemPosition === 'fifth') {
      return 400000
    }
  }

  const highlightedMediaGalleryItems = useMemo(() => {
    return mediaGalleryItems.reduce<typeof mediaGalleryItems>((acc, item) => {
      const allowedWeights = [
        getHighlightedMediaGalleryWeight('hero'),
        getHighlightedMediaGalleryWeight('second'),
        getHighlightedMediaGalleryWeight('third'),
        getHighlightedMediaGalleryWeight('fourth'),
        getHighlightedMediaGalleryWeight('fifth'),
      ]

      const weight = item.weight
      if (allowedWeights.includes(weight) && !acc.some((i) => i.weight === weight)) {
        acc.push(item)
      }
      return acc
    }, [])
  }, [mediaGalleryItems])

  const getHighlightedMediaGalleryItem = (mediaGalleryItemPosition: MediaGalleryItemPosition) => {
    if (mediaGalleryItemPosition === 'hero') {
      return highlightedMediaGalleryItems.find(
        (item) => item.weight === getHighlightedMediaGalleryWeight('hero'),
      )
    }
    if (mediaGalleryItemPosition === 'second') {
      return highlightedMediaGalleryItems.find(
        (item) => item.weight === getHighlightedMediaGalleryWeight('second'),
      )
    }
    if (mediaGalleryItemPosition === 'third') {
      return highlightedMediaGalleryItems.find(
        (item) => item.weight === getHighlightedMediaGalleryWeight('third'),
      )
    }
    if (mediaGalleryItemPosition === 'fourth') {
      return highlightedMediaGalleryItems.find(
        (item) => item.weight === getHighlightedMediaGalleryWeight('fourth'),
      )
    }
    if (mediaGalleryItemPosition === 'fifth') {
      return highlightedMediaGalleryItems.find(
        (item) => item.weight === getHighlightedMediaGalleryWeight('fifth'),
      )
    }
  }

  const has5Images = highlightedMediaGalleryItems.length === 5

  const highlightedImageIds = useMemo(
    () => ({
      heroImageId: getHighlightedMediaGalleryItem('hero')?.mediaImageId,
      secondImageId: getHighlightedMediaGalleryItem('second')?.mediaImageId,
      thirdImageId: getHighlightedMediaGalleryItem('third')?.mediaImageId,
      fourthImageId: getHighlightedMediaGalleryItem('fourth')?.mediaImageId,
      fifthImageId: getHighlightedMediaGalleryItem('fifth')?.mediaImageId,
      imageEditMode,
    }),
    [highlightedMediaGalleryItems, imageEditMode],
  )

  const {
    multipassProducts,
    isLoading: isLoadingMultipasses,
    isError: isErrorMultipasses,
  } = useGetMultipassProductsPublic(organizationId!)

  const [showTurnstile, setShowTurnstile] = useState<boolean>(false)
  const [blockAddToCart, setBlockAddToCart] = useState<boolean>(false)

  useEffect(() => {
    // === Use Effect for Order Hydration via Path Params ===
    // Do we have a customerId in the link?
    if (customerId !== null && customerId !== undefined) {
      // A customerId in the link requires that the user is authenticated
      if (isAuthenticated) {
        // The customerId in the link matches the current users customerId?
        if (user[APP_METADATA_CLAIM_NAMESPACE].customerId === customerId) {
          // Do we have an orderId in the link? -> Hydrate the order
          if (orderId !== null && orderId !== undefined) {
            qoursesApi.paymentPublic
              .paymentPublicControllerGetOrder(orderId)
              .then((order) => {
                // Only hydrate if we have an order with at least one booking
                if (
                  order.orderStatus === OrderEntity.orderStatus.PENDING ||
                  order.orderStatus === OrderEntity.orderStatus.CHECKOUT
                ) {
                  qoursesApi.paymentPublic
                    .paymentPublicControllerGetOrderBookings(order.id)
                    .then((bookings) => {
                      if (bookings && bookings.length > 0) {
                        sendNotification(
                          translate(
                            'pages.public.booking.OrderHydrationNotifications.customer.title',
                          ),
                          translate(
                            'pages.public.booking.OrderHydrationNotifications.customer.subtitle',
                            {
                              email: guestEmailParam,
                            },
                          ),
                          ToastVariant.Success,
                          6000,
                        )
                        addOrderToLocalStorage(order)
                        setOrder(order)
                      } else {
                        setSearchParams((searchParams) => {
                          searchParams.delete(orderIdParam)
                          return searchParams
                        })
                        removeOrderFromLocalStorage()
                        removeMeetingIdsFromLocalStorage()
                      }
                    })
                } else {
                  setSearchParams((searchParams) => {
                    searchParams.delete(orderIdParam)
                    return searchParams
                  })
                  removeOrderFromLocalStorage()
                  removeMeetingIdsFromLocalStorage()
                }
              })
              .catch((e) => {
                console.error(e)
                sendNotification(
                  translate(
                    'pages.public.booking.OrderHydrationNotifications.orderRetrievalFailed.title',
                  ),
                  translate(
                    'pages.public.booking.OrderHydrationNotifications.orderRetrievalFailed.subtitle',
                  ),
                  ToastVariant.Error,
                  6000,
                )
              })
          }
          // The customerIdParam in the link does not match the current users customerId
          // -> Redirect to the login page
        } else {
          sendNotification(
            translate('pages.public.booking.OrderHydrationNotifications.customerWrongLogin.title'),
            translate(
              'pages.public.booking.OrderHydrationNotifications.customerWrongLogin.subtitle',
              {
                email: guestEmailParam,
              },
            ),
            ToastVariant.Success,
            6000,
          )
          setTimeout(() => {
            loginWithRedirect({
              appState: {
                returnTo: `${window.location.pathname}${window.location.search}`,
              },
            })
          }, 4000)
        }
        // We are not authenticated, but we should be because the link contains a customerId
        // -> Redirect to the login page
      } else {
        sendNotification(
          translate('pages.public.booking.OrderHydrationNotifications.customerNotLoggedIn.title'),
          translate(
            'pages.public.booking.OrderHydrationNotifications.customerNotLoggedIn.subtitle',
            {
              email: guestEmailParam,
            },
          ),
          ToastVariant.Success,
          5000,
        )
        setTimeout(() => {
          loginWithRedirect({
            appState: {
              returnTo: `${window.location.pathname}${window.location.search}`,
            },
          })
        }, 4000)
      }
    } else {
      // We don't have a customerId in the link, so we check:
      // Do we have an orderId AND email in the link? Indicating this is a guest order
      if (orderId !== null && orderId !== undefined && guestEmailParam !== null) {
        qoursesApi.paymentPublic
          .paymentPublicControllerGetOrder(orderId)
          .then((order) => {
            if (
              order.orderStatus === OrderEntity.orderStatus.PENDING ||
              order.orderStatus === OrderEntity.orderStatus.CHECKOUT
            ) {
              sendNotification(
                translate('pages.public.booking.OrderHydrationNotifications.guest.title'),
                translate('pages.public.booking.OrderHydrationNotifications.guest.subtitle', {
                  email: guestEmailParam,
                }),
                ToastVariant.Success,
                6000,
              )
              addOrderToLocalStorage(order)
              setOrder(order)
              setEmailConfirmed(true)
              setEmail(guestEmailParam)
            } else {
              setSearchParams((searchParams) => {
                searchParams.delete(orderIdParam)
                return searchParams
              })
              removeOrderFromLocalStorage()
              removeMeetingIdsFromLocalStorage()
            }
          })
          .catch((e) => {
            console.error(e)
            sendNotification(
              translate(
                'pages.public.booking.OrderHydrationNotifications.orderRetrievalFailed.title',
              ),
              translate(
                'pages.public.booking.OrderHydrationNotifications.orderRetrievalFailed.subtitle',
              ),
              ToastVariant.Error,
              6000,
            )
          })
      }
    }
    // 1: We are not authenticated
    // 2: have an hydrated order
    // 3: order was created with an authenticated account,
    // -> delete the localStorage order
    if (!isAuthenticated && !isLoadingAuthentication) {
      const orderLocalStorage = localStorage.getItem(orderLocalStorageKey)

      if (!orderLocalStorage) {
        return
      }

      const parsedOrder = JSON.parse(orderLocalStorage) as OrderEntity
      if (parsedOrder) {
        if (parsedOrder.customerId) {
          removeOrderFromLocalStorage()
          removeMeetingIdsFromLocalStorage()
        }
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!isLoadingCourses && courseId) {
      setSelectedCourse(courses.filter((course) => course.id === courseId)[0] ?? null)
    }
  }, [isLoadingCourses])

  const randomCourseName = useMemo(() => {
    if (courses.length > 0) {
      return courses[Math.floor(Math.random() * courses.length)]?.name
    }
    return ''
  }, [courses])

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  useEffect(() => {
    // Hydrate the order from local storage if it exists
    const localStorageOrder = localStorage.getItem(orderLocalStorageKey)

    if (localStorageOrder === null || localStorageOrder === undefined) {
      return
    }

    const parsedOrder = JSON.parse(localStorageOrder) as OrderEntity

    // This determines if the order is still valid
    // If the order is invalid
    //    -> remove it from the local storage
    //    -> remove all meetingIds from the local storage
    // If the order is valid
    //    Is it in WAITING_FOR_BANK_TRANSFER?
    //       -> Redirect to the success page
    //    Is it in CHECKOUT or PENDING?
    //       -> Set the order state
    //    Is it in any other state?
    //       -> Remove the order from the local storage
    if (parsedOrder !== null) {
      const orderAgeInMinutes = Math.round(
        DateTime.fromISO(parsedOrder.createdAt).diffNow('minutes').minutes * -1,
      )
      if (orderAgeInMinutes > timeInMinutesUntilOrderExpires) {
        removeOrderFromLocalStorage()
        removeMeetingIdsFromLocalStorage()
      } else {
        // We have an order in the local storage, get the latest state from the api and update it accordingly
        qoursesApi.paymentPublic.paymentPublicControllerGetOrder(parsedOrder.id).then((order) => {
          if (order.id === parsedOrder.id) {
            if (order.orderStatus === OrderEntity.orderStatus.WAITING_FOR_BANK_TRANSFER) {
              const successUrl = import.meta.env.VITE_STRIPE_PAYMENT_SUCCESS_REDIRECT_PATH
              // Go to success page
              window.location.href = successUrl + '?o=' + order.id
            }
            if (
              order.orderStatus === OrderEntity.orderStatus.CHECKOUT ||
              order.orderStatus === OrderEntity.orderStatus.PENDING
            ) {
              setOrder(order)
              addOrderToLocalStorage(order)
            } else {
              // The order is not in a state where we can continue
              removeOrderFromLocalStorage()
              removeMeetingIdsFromLocalStorage()
            }
          }
        })
        //If the hydrated order is about a multipass, we need to show the multipasses
        if (parsedOrder.multipassProductId) {
          setShowMultipasses(true)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!isLoadingMultipasses && multipassProductId) {
      const hydratedMultipass = multipassProducts.filter(
        (multipass) => multipass.id === multipassProductId,
      )[0]

      if (hydratedMultipass) {
        setShowMultipasses(true)
        setSelectedMultipass(hydratedMultipass)
        setSelectedIdentifiableBookable({
          bookable: hydratedMultipass,
          bookableType: BookableType.Multipass,
        })
      }
    }
  }, [isLoadingMultipasses])

  // Hydrate the selected course from the url once we loaded the courses
  useEffect(() => {
    if (selectedCourse) {
      setSearchParams((searchParams) => {
        searchParams.set(courseIdParam, selectedCourse.id)
        return searchParams
      })
    }
  }, [selectedCourse])

  // Hydrate the selectedMeeting from the url once the child comp calls the setter again
  useEffect(() => {
    // Meeting is there, so lets update the params!
    if (identifiableBookable) {
      setSearchParams((searchParams) => {
        searchParams.set(meetingIdParam, identifiableBookable.bookable.id)
        return searchParams
      })
    }
    // This is an additional if instead of an else because:
    // Deselecting a meeting should remove the current selectedMeeting as well
    // BUT if we render for the first time we don't want to delete the search param as selectedMeeting is still empty
    if (!identifiableBookable && selectedCourse) {
      setSearchParams((searchParams) => {
        searchParams.delete(meetingIdParam)
        return searchParams
      })
    }
  }, [identifiableBookable])

  useEffect(() => {
    if (selectedCourse) {
      setSelectedIdentifiableBookable(null)
    }
  }, [selectedCourse, order])

  useEffect(() => {
    if (!isLoadingOrganization) {
      document.title = organization?.name + ' | Qourses'
    }
  }, [isLoadingOrganization])

  // If we are authenticated and have an email, set it as the default email for the order
  // This skips the turnstile challenge
  useEffect(() => {
    if (user && isAuthenticated && user.email) {
      setEmailConfirmed(true)
      setEmail(user.email)
    }
  }, [user, isAuthenticated])

  // We have an order? So we collapse the course selection panel
  useEffect(() => {
    if (selectedCourse) {
      setCourseCollapsibleOpen(false)
      setSelectedTag('')
    }
  }, [selectedCourse])

  useEffect(() => {
    if (selectedMultipass) {
      setMultipassCollapsibleOpen(false)
    }
  }, [selectedMultipass])

  //Opened the course collapsible? kill the order and the course
  useEffect(() => {
    if (courseCollapsibleOpen && selectedCourse) {
      setSearchParams((searchParams) => {
        searchParams.delete(courseIdParam)
        return searchParams
      })
      setOrder(null)
      //TODO: Remove c param from url?
      setSelectedCourse(null)
      setSelectedTag('')
      setSelectedIdentifiableBookable(null)

      //TODO: Call an endpoint to cancel an order here
      removeMeetingIdsFromLocalStorage()
      removeOrderFromLocalStorage()
    }
  }, [courseCollapsibleOpen])

  //Opened the multipass collapsible? kill the order and the multipass
  useEffect(() => {
    if (multipassCollapsibleOpen && selectedMultipass) {
      setSearchParams((searchParams) => {
        searchParams.delete(multipassProductIdParam)
        return searchParams
      })
      setOrder(null)
      setSelectedMultipass(null)
      setSelectedIdentifiableBookable(null)
      removeMeetingIdsFromLocalStorage()
      removeOrderFromLocalStorage()
    }
  }, [multipassCollapsibleOpen])

  // handle resets if user moves away from multipass view
  useEffect(() => {
    if (!showMultipasses && !isLoadingMultipasses) {
      setSelectedIdentifiableBookable(null)
      setSelectedMultipass(null)
      setOrder(null)
      removeMeetingIdsFromLocalStorage()
      removeOrderFromLocalStorage()
      setMultipassCollapsibleOpen(true)
    }
  }, [showMultipasses])

  useEffect(() => {
    if (courses) {
      const course = courses.filter((course) => course.id === courseId)[0]
      if (course) {
        setSelectedCourse(course)
      } else {
        setSelectedCourse(null)
        setCourseCollapsibleOpen(true)
        setSelectedIdentifiableBookable(null)
      }
    }
  }, [courseId])

  // Detect if we are in image edit mode and show a notification for the user & set the active organization
  useEffect(() => {
    if (imageEditMode && isAuthenticated && !isLoadingOrganization && organization) {
      if (imageEditMode === 'true') {
        setActiveApiOrganization(organization.slug)
        sendNotification(
          translate('pages.public.booking.imageEditModeNotifications.modeActive.title'),
          translate('pages.public.booking.imageEditModeNotifications.modeActive.subtitle'),
          ToastVariant.Info,
        )
      }
    }
  }, [imageEditMode, isAuthenticated, isLoadingOrganization])

  const descriptionHeightRef = useRef<HTMLDivElement>(null)

  let statusURL = import.meta.env.VITE_API_URL
  // cut 'api' from the url and replace with 'status'
  if (statusURL.includes('api')) {
    statusURL = statusURL.replace('api', 'status')
  } else {
    statusURL = 'https://status.qourses.space'
  }

  if (coursesError || organizationError || isErrorMultipasses) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
      >
        <div className="text-center">
          <p className="text-3xl font-semibold text-indigo-600">🔥🔥🔥🚿🧑‍🚒 {''} 🚒🚒</p>
          <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {translate('pages.error.title')}
          </h1>
          <p className="mt-6 max-w-md text-base leading-7 text-gray-600">
            {translate('pages.error.subtitle')}
          </p>
          <div className="mt-5 flex items-center justify-center gap-x-6">
            <iframe
              src={statusURL + '/badge?theme=light'}
              className="items-center rounded-lg bg-gray-50 ring-1 ring-gray-200"
              width="182"
              height="30"
              scrolling="no"
              frameBorder="0"
            />
          </div>
        </div>
      </motion.div>
    )
  }

  async function handleBookIdentifiableBookable(identifiableBookable: IdentifiableBookable) {
    // User did not create an order yet? Create one ->
    // Otherwise we can just add the booking to the order
    try {
      setOrderLoading(true)
      if (!order && identifiableBookable) {
        if (!email) {
          throw new Error('Email is not set')
        }
        if (!turnstileChallenge && !emailConfirmed) {
          throw new Error('Turnstile token is not set')
        }

        const guest = await qoursesApi.customerPublic.customerPublicControllerCreateGuest({
          email: email,
        })

        // Create the order with the selected course or multipass
        let order
        if (selectedCourse) {
          order = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateOrder({
              guestId: guest.id,
              courseId: selectedCourse.id,
              turnstileChallenge: turnstileChallenge ?? undefined,
              multipassProductId: null,
            }),
            500,
          )
        }

        if (selectedMultipass) {
          order = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateOrder({
              guestId: guest.id,
              courseId: null,
              turnstileChallenge: turnstileChallenge ?? undefined,
              multipassProductId: selectedMultipass.id,
            }),
            500,
          )
        }

        setOrder(order)
        addOrderToLocalStorage(order)

        if (identifiableBookable.bookableType === BookableType.Meeting) {
          const booking = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateBooking(order!.id, {
              meetingId: identifiableBookable.bookable.id,
            }),
            300,
          )
          addMeetingIdToLocalStorage(booking.meetingId)
        }

        if (identifiableBookable.bookableType === BookableType.Multipass) {
          const booking = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateBooking(order!.id, {
              multipassProductId: identifiableBookable.bookable.id,
            }),
            300,
          )
          addMeetingIdToLocalStorage(booking.id)
        }

        if (identifiableBookable.bookableType === BookableType.CourseGroup) {
          const booking = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateBooking(order!.id, {
              courseGroupId: identifiableBookable.bookable.id,
            }),
            300,
          )
          addMeetingIdToLocalStorage(booking.courseGroupId)
        }

        // Invalidate the query via the query client so the child components triggers a refetch of the
        // order bookings
        await queryClient.invalidateQueries(GetOrderBookingsPublicQueryKey(order?.id))
        await queryClient.invalidateQueries(GetOrderPublicQueryKey(order?.id))
        if (selectedCourse) {
          await queryClient.invalidateQueries(GetCourseMeetingsPublicQueryKey(selectedCourse.id))
          await queryClient.invalidateQueries(GetCourseGroupsPublicQueryKey(selectedCourse.id))
        }
      } else {
        if (identifiableBookable.bookableType === BookableType.Meeting) {
          const booking = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateBooking(order!.id, {
              meetingId: identifiableBookable.bookable.id,
            }),
            500,
          )
          addMeetingIdToLocalStorage(booking.meetingId)
        }

        if (identifiableBookable.bookableType === BookableType.CourseGroup) {
          const booking = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateBooking(order!.id, {
              courseGroupId: identifiableBookable.bookable.id,
            }),
            500,
          )
          addMeetingIdToLocalStorage(booking.courseGroupId)
        }

        if (identifiableBookable.bookableType === BookableType.Multipass) {
          const booking = await minDelay(
            qoursesApi.paymentPublic.paymentPublicControllerCreateBooking(order!.id, {
              multipassProductId: identifiableBookable.bookable.id,
            }),
            300,
          )
          addMeetingIdToLocalStorage(booking.id)
        }

        // Invalidate the query via the query client so the child components triggers a refetch of the
        // order bookings
        await queryClient.invalidateQueries(GetOrderBookingsPublicQueryKey(order?.id))
        await queryClient.invalidateQueries(GetOrderPublicQueryKey(order?.id))
        if (selectedCourse) {
          await queryClient.invalidateQueries(GetCourseMeetingsPublicQueryKey(selectedCourse.id))
          await queryClient.invalidateQueries(GetCourseGroupsPublicQueryKey(selectedCourse.id))
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      //setSelectedIdentifiableBookable(null)
      setOrderLoading(false)
    }
  }

  const readyToBook = (identifiableBookable || selectedMultipass) && !orderLoading

  function BookingButton() {
    return (
      <>
        <div className="flex flex-shrink flex-wrap mt-4">
          <Button
            variant="indigo"
            disabled={emailError || orderLoading || !email || blockAddToCart || !readyToBook}
            className={`bezel w-full py-6 sm:w-2/4 sm:py-2 bezel ${showTurnstile ? 'mt-4' : ''}`}
            onClick={(e) => {
              e.preventDefault()
              if (!emailConfirmed && !emailError) {
                setEmailConfirmed(true)
              }
              if (identifiableBookable) {
                handleBookIdentifiableBookable(identifiableBookable)
              }
            }}
          >
            {!orderLoading ? (
              <>
                <ShoppingCart className="mr-2 size-3 shrink-0" />
                {readyToBook ? (
                  <span>{translate('pages.public.booking.meeting-booking-info.button')}</span>
                ) : (
                  <span>
                    {translate('pages.public.booking.meeting-booking-info.buttonDisabled')}
                  </span>
                )}
              </>
            ) : (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                <span>{translate('pages.public.booking.summary.button-loading')}</span>
              </>
            )}
          </Button>
        </div>
        {identifiableBookable && selectedCourse && selectedCourse.termsOfServiceId && (
          <div>
            {isPublicMeetingWithBookingInfoEntity(
              identifiableBookable.bookable,
              identifiableBookable.bookableType,
            ) && (
              <TermsOfServiceCancellationMeetings
                selectedMeeting={identifiableBookable.bookable}
                termsOfServiceId={selectedCourse.termsOfServiceId}
              />
            )}
            {isPublicCourseGroupWithBookingInfoEntity(
              identifiableBookable.bookable,
              identifiableBookable.bookableType,
            ) && (
              <TermsOfServiceCancellationCourseGroup
                selectedMeeting={identifiableBookable.bookable}
                termsOfServiceId={selectedCourse.termsOfServiceId}
              />
            )}
          </div>
        )}
      </>
    )
  }

  const updateWeightedOrganizationGalleryImage = async (
    id: string,
    mediaGalleryItemPosition: MediaGalleryItemPosition,
  ) => {
    try {
      if (getHighlightedMediaGalleryItem(mediaGalleryItemPosition)) {
        if (getHighlightedMediaGalleryItem(mediaGalleryItemPosition).mediaImageId !== id) {
          await qoursesApi.media.mediaControllerUpdateGalleryItem(
            getHighlightedMediaGalleryItem(mediaGalleryItemPosition).id,
            {
              mediaImageId: id,
              weight: getHighlightedMediaGalleryWeight(mediaGalleryItemPosition),
            },
          )
        }
      } else {
        await qoursesApi.media.mediaControllerCreateGalleryItem({
          mediaImageId: id,
          weight: getHighlightedMediaGalleryWeight(mediaGalleryItemPosition),
          organization: true,
        })
      }
      refetchMediaGalleryItems()
      sendNotification(
        translate('pages.public.booking.imageEditModeNotifications.imageEdited.title'),
        translate('pages.public.booking.imageEditModeNotifications.imageEdited.subtitle'),
        ToastVariant.Success,
      )
    } catch (e) {
      sendGenericErrorNotification(e)
    }
  }

  const organizationNotActive = !isLoadingOrganization && organization.activeCustomer === false

  const MAX_COLLAPSED_HEIGHT = 100 // Maximum height for collapsed state in pixels

  const handleOpenMediaImagePickerModal = (mediaGalleryItemPosition: MediaGalleryItemPosition) => {
    pushModal('MediaImagePickerModal', {
      selectedImagesCallback: (selectedMediaImages) => {
        updateWeightedOrganizationGalleryImage(selectedMediaImages[0].id, mediaGalleryItemPosition)
      },
      multiple: false,
    })
  }

  return (
    <div className="min-h-screen bg-white" translate="no">
      <div className="mx-auto max-w-6xl px-2 py-5 sm:px-6 sm:py-10 lg:px-8">
        <CustomerAppBanner />
        {/* Image Grid */}
        {!isMobile && (highlightedMediaGalleryItems?.length === 5 || imageEditMode) && (
          <AnimatePresence>
            <div className="mt-4 mb-6 lg:col-span-3">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-2 rounded-xl">
                {/* Main large image - spans full width on small mobile, 2 columns on larger mobile, 2 cols/rows on tablet+ */}
                <div
                  data-editable={!!imageEditMode}
                  className="col-span-2 row-span-1 sm:row-span-2 md:col-span-2 md:row-span-2 relative sm:aspect-square data-[editable=true]:hover:scale-[98%] data-[editable=true]:hover:brightness-[90%] transition data-[editable=true]:cursor-pointer"
                  onClick={() => {
                    pushModal('MediaImagePickerModal', {
                      selectedImagesCallback: (selectedMediaImages) => {
                        updateWeightedOrganizationGalleryImage(selectedMediaImages[0].id, 'hero')
                      },
                      multiple: false,
                    })
                  }}
                >
                  <OrganizationMediaImage
                    mediaGalleryItemPosition={'hero'}
                    mediaGalleryItem={getHighlightedMediaGalleryItem('hero')}
                    mediaImageId={highlightedImageIds.heroImageId}
                    imageEditMode={!!imageEditMode}
                  />
                </div>
                {/* Secondary images - visible on medium screens and up, with mobile fallback options */}
                <div
                  className="hidden sm:block aspect-square data-[editable=true]:hover:scale-[98%] data-[editable=true]:hover:brightness-[90%] transition data-[editable=true]:cursor-pointer"
                  data-editable={!!imageEditMode}
                  onClick={() => {
                    pushModal('MediaImagePickerModal', {
                      selectedImagesCallback: (selectedMediaImages) => {
                        updateWeightedOrganizationGalleryImage(selectedMediaImages[0].id, 'second')
                      },
                      multiple: false,
                    })
                  }}
                >
                  <OrganizationMediaImage
                    mediaGalleryItemPosition={'second'}
                    mediaGalleryItem={getHighlightedMediaGalleryItem('second')}
                    mediaImageId={highlightedImageIds.secondImageId}
                    imageEditMode={!!imageEditMode}
                  />
                </div>

                <div
                  className="hidden sm:block aspect-square data-[editable=true]:hover:scale-[98%] data-[editable=true]:hover:brightness-[90%] transition data-[editable=true]:cursor-pointer"
                  data-editable={!!imageEditMode}
                  onClick={() => {
                    pushModal('MediaImagePickerModal', {
                      selectedImagesCallback: (selectedMediaImages) => {
                        updateWeightedOrganizationGalleryImage(selectedMediaImages[0].id, 'third')
                      },
                      multiple: false,
                    })
                  }}
                >
                  <OrganizationMediaImage
                    mediaGalleryItemPosition={'third'}
                    mediaGalleryItem={getHighlightedMediaGalleryItem('third')}
                    mediaImageId={highlightedImageIds.thirdImageId}
                    imageEditMode={!!imageEditMode}
                  />
                </div>

                {/* Bottom right images */}
                <div
                  className="hidden sm:block aspect-square data-[editable=true]:hover:scale-[98%] data-[editable=true]:hover:brightness-[90%] transition data-[editable=true]:cursor-pointer"
                  data-editable={!!imageEditMode}
                  onClick={() => {
                    pushModal('MediaImagePickerModal', {
                      selectedImagesCallback: (selectedMediaImages) => {
                        updateWeightedOrganizationGalleryImage(selectedMediaImages[0].id, 'fourth')
                      },
                      multiple: false,
                    })
                  }}
                >
                  <OrganizationMediaImage
                    mediaGalleryItemPosition={'fourth'}
                    mediaGalleryItem={getHighlightedMediaGalleryItem('fourth')}
                    mediaImageId={highlightedImageIds.fourthImageId}
                    imageEditMode={!!imageEditMode}
                  />
                </div>
                <div
                  className="hidden sm:block aspect-square data-[editable=true]:hover:scale-[98%] data-[editable=true]:hover:brightness-[90%] transition data-[editable=true]:cursor-pointer"
                  data-editable={!!imageEditMode}
                  onClick={() => {
                    pushModal('MediaImagePickerModal', {
                      selectedImagesCallback: (selectedMediaImages) => {
                        updateWeightedOrganizationGalleryImage(selectedMediaImages[0].id, 'fifth')
                      },
                      multiple: false,
                    })
                  }}
                >
                  <OrganizationMediaImage
                    mediaGalleryItemPosition={'fifth'}
                    mediaGalleryItem={getHighlightedMediaGalleryItem('fifth')}
                    mediaImageId={highlightedImageIds.fifthImageId}
                    imageEditMode={!!imageEditMode}
                  />
                </div>
                {imageEditMode && highlightedMediaGalleryItems.length < 5 && (
                  <div className="col-span-full flex items-center gap-x-2 mt-2">
                    <Info className="size-5 text-gray-400 flex-shrink-0" />
                    <p className="text-muted-foreground text-xs sm:text-sm font-semibold flex-1 min-w-0">
                      {translate('pages.public.booking.imageGallery.imageEditModeAmountHint')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </AnimatePresence>
        )}
        {isMobile && (has5Images || imageEditMode) && (
          <div>
            <MobileImageGallery
              organizationMediaImages={[
                <OrganizationMediaImage
                  key="hero"
                  mediaGalleryItemPosition={'hero'}
                  mediaGalleryItem={getHighlightedMediaGalleryItem('hero')}
                  mediaImageId={highlightedImageIds.heroImageId}
                  imageEditMode={!!imageEditMode}
                  editImageCallback={() => {
                    handleOpenMediaImagePickerModal('hero')
                  }}
                />,
                <OrganizationMediaImage
                  key="second"
                  mediaGalleryItemPosition={'second'}
                  mediaGalleryItem={getHighlightedMediaGalleryItem('second')}
                  mediaImageId={highlightedImageIds.secondImageId}
                  imageEditMode={!!imageEditMode}
                  editImageCallback={() => {
                    handleOpenMediaImagePickerModal('second')
                  }}
                />,
                <OrganizationMediaImage
                  key="third"
                  mediaGalleryItemPosition={'third'}
                  mediaGalleryItem={getHighlightedMediaGalleryItem('third')}
                  mediaImageId={highlightedImageIds.thirdImageId}
                  imageEditMode={!!imageEditMode}
                  editImageCallback={() => {
                    handleOpenMediaImagePickerModal('third')
                  }}
                />,
                <OrganizationMediaImage
                  key="fourth"
                  mediaGalleryItemPosition={'fourth'}
                  mediaGalleryItem={getHighlightedMediaGalleryItem('fourth')}
                  mediaImageId={highlightedImageIds.fourthImageId}
                  imageEditMode={!!imageEditMode}
                  editImageCallback={() => {
                    handleOpenMediaImagePickerModal('fourth')
                  }}
                />,
                <OrganizationMediaImage
                  key="fifth"
                  mediaGalleryItemPosition={'fifth'}
                  mediaGalleryItem={getHighlightedMediaGalleryItem('fifth')}
                  mediaImageId={highlightedImageIds.fifthImageId}
                  imageEditMode={!!imageEditMode}
                  editImageCallback={() => {
                    handleOpenMediaImagePickerModal('fifth')
                  }}
                />,
              ]}
            />
            {imageEditMode && highlightedMediaGalleryItems.length < 5 && (
              <div className="col-span-full flex items-center gap-x-2 mt-2">
                <Info className="size-5 text-gray-400 flex-shrink-0" />
                <p className="text-muted-foreground text-xs sm:text-sm font-semibold flex-1 min-w-0">
                  {translate('pages.public.booking.imageGallery.imageEditModeAmountHint')}
                </p>
              </div>
            )}
          </div>
        )}
        <div className="relative mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-4 sm:gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {/*Organization Container*/}
          <div className="row-span-1 row-end-2 overflow-hidden lg:col-span-2 lg:row-span-1 lg:row-end-2">
            {organizationNotActive && (
              <Alert className="rounded-lg border border-dashed border-black bg-yellow-400 bg-gradient-to-b from-yellow-300 from-10% to-yellow-400 ring-0">
                <Construction className="size-5" />
                <AlertTitle>
                  {translate('pages.public.booking.orgNotActiveCustomer.title')}
                </AlertTitle>
                <AlertDescription>
                  {translate('pages.public.booking.orgNotActiveCustomer.subtitle')}
                </AlertDescription>
              </Alert>
            )}
            <div className="relative items-center px-4 pt-5 sm:px-6 sm:pt-6">
              {/* Gradient for collapsing the description */}
              <motion.div
                animate={{ opacity: !organizationCollapsed ? 0 : 1 }}
                className="pointer-events-none absolute bottom-0 left-0 right-0 z-10 h-[150%] bg-gradient-to-t from-white to-transparent to-30%"
              />
              <div className="space-y-3">
                <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-4">
                  {organization?.logoMediaImageId && (
                    <>
                      {/* Logo container with responsive sizing */}
                      <div className="size-20 rounded-full bg-gray-200 flex-shrink-0 overflow-hidden border-2 border-gray-100 shadow-sm self-center sm:self-start">
                        {isLoadingOrganization || !organizationLogoMediaImage ? (
                          <Skeleton className="w-full h-full rounded-full" />
                        ) : organizationId ? (
                          <img
                            src={getImageUrl(organizationLogoMediaImage.cloudflareImageId)}
                            alt={`${organization.name} Logo`}
                            className="w-full h-full object-cover rounded-full"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-primary-100 text-primary-800 font-bold text-xl">
                            {organization.name ? organization.name.charAt(0) : '?'}
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {/* Organization name and details container with full width */}
                  <div className="flex-1 min-w-0">
                    <h1
                      className="font-merriweather text-3xl sm:text-4xl font-semibold"
                      translate="no"
                    >
                      {isLoadingOrganization ? (
                        <Skeleton className="h-[35px] w-full max-w-[200px] bg-gray-200" />
                      ) : (
                        organization.name
                      )}
                    </h1>

                    {organization.companyLegalName && (
                      <div
                        className="flex items-center font-sans text-sm font-normal text-gray-600 mt-1 truncate"
                        translate="no"
                      >
                        <span className="truncate mr-2">{organization.companyLegalName}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="flex-shrink-0 lucide lucide-badge-check stroke-blue-900"
                        >
                          <path
                            fill="#60a5fa"
                            stroke="#60a5fa"
                            d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"
                          />
                          <path stroke="#fff" d="m9 12 2 2 4-4" />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>

                {isLoadingOrganization ? (
                  <motion.div className="relative h-[100px] whitespace-break-spaces border-t pt-4 text-base font-normal text-gray-700 sm:border-0 sm:pt-0 sm:text-sm">
                    <div className="text-md mt-1 flex flex-col gap-x-3 rounded-lg p-2 font-normal leading-normal text-gray-800 sm:flex-row">
                      <div className="mt-1 flex">
                        <svg
                          className="mb-2 h-5 w-5 text-gray-400 dark:text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 14"
                        >
                          <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                        </svg>
                      </div>
                      <div className="flex h-full flex-col gap-y-2 pb-16 sm:pb-4">
                        <Skeleton className="h-[20px] w-[300px] bg-gray-100 sm:w-[450px]" />
                        <Skeleton className="h-[20px] w-[250px] bg-gray-100 sm:w-[400px]" />
                        <Skeleton className="h-[20px] w-[300px] bg-gray-100" />
                        <Skeleton className="h-[20px] w-[350px] bg-gray-100" />
                        <Skeleton className="h-[20px] w-[350px] bg-gray-100" />
                        <Skeleton className="h-[20px] w-[350px] bg-gray-100" />
                        <Skeleton className="h-[20px] w-[350px] bg-gray-100" />
                        <Skeleton className="h-[20px] w-[350px] bg-gray-100" />
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  organization.description && (
                    <motion.div
                      initial={{
                        height: Math.min(
                          organization.description.length * 0.4,
                          MAX_COLLAPSED_HEIGHT,
                        ),
                      }}
                      animate={{
                        height: organizationCollapsed
                          ? Math.min(organization.description.length * 0.4, MAX_COLLAPSED_HEIGHT)
                          : descriptionHeightRef.current?.offsetHeight || 'auto',
                      }}
                      className="relative whitespace-break-spaces border-t pt-4 text-[15px] font-normal text-gray-700 sm:border-0 sm:pt-0"
                    >
                      <div className="mt-1 flex flex-col gap-x-3 rounded-lg p-2 font-normal leading-normal text-gray-800 sm:flex-row">
                        <div className="mt-1 flex">
                          <svg
                            className="mb-2 h-5 w-5 text-gray-400 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 14"
                          >
                            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                          </svg>
                        </div>
                        <div className="flex h-full pb-16 sm:pb-4" ref={descriptionHeightRef}>
                          {organization.description}
                        </div>
                      </div>
                    </motion.div>
                  )
                )}

                <div className="relative bottom-2 z-50 mx-auto w-[50px] cursor-pointer items-center rounded-t-lg">
                  <motion.div
                    animate={{ rotate: organizationCollapsed ? 0 : 180 }}
                    whileHover={{ scale: isMobile ? 1 : 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setOrganizationCollapsed(!organizationCollapsed)}
                  >
                    <ChevronDownIcon
                      className={classNames(
                        'className="h-6 mx-auto w-6 stroke-[4px] transition-colors',
                        organizationCollapsed ? 'text-gray-800' : 'text-gray-300',
                      )}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          {email && (
            <>
              {order ? (
                <OrderSummary
                  orderId={order.id}
                  courseOrMultipass={selectedCourse}
                  email={email}
                  demoMode={demoMode}
                />
              ) : (
                <div
                  id={'order-summary-empty'}
                  className="hidden sm:block bottom-0 top-10 z-10 row-end-4 -m-4 p-4 sm:m-0 sm:p-0 lg:sticky lg:col-start-3 lg:row-end-3"
                >
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="rounded-lg bg-white shadow-xl ring-2 ring-gray-200 p-6 flex flex-col items-center justify-center"
                  >
                    <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4 shadow-inner">
                      <ShoppingCart className="h-8 w-8 text-gray-400" />
                    </div>

                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {translate('pages.public.booking.summary.empty.title')}
                    </h3>

                    <p className="text-sm text-gray-600 text-center mb-6">
                      {translate('pages.public.booking.summary.empty.subtitle')}
                    </p>

                    <Button
                      disabled
                      className="bezel py-auto text-md relative h-10 w-full overflow-hidden rounded-md bg-indigo-600 bg-gradient-to-b from-indigo-500 to-indigo-600 px-3 py-2 text-center font-semibold text-white shadow-sm ring-1 ring-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                    >
                      <ArrowLeft className="mr-2 h-4 w-4" />
                      <span>{translate('pages.public.booking.summary.empty.button')}</span>
                    </Button>
                    {randomCourseName && (
                      <div className="w-full border-t border-gray-200 mt-6 pt-4">
                        <p className="text-xs text-gray-500 text-center">
                          {translate('pages.public.booking.summary.empty.help', {
                            course: randomCourseName,
                          })}
                        </p>
                      </div>
                    )}
                  </motion.div>
                </div>
              )}
            </>
          )}

          {/* Selection Panel */}
          <div className="row-span-1 row-end-3 rounded-lg bg-white px-5 py-5 sm:mx-0 sm:p-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-4 xl:px-8 xl:pb-10 xl:pt-8">
            <ResizablePanel>
              <div className="mb-6 flex flex-wrap gap-x-3 gap-y-2">
                <h2 className="flex flex-row items-center text-xl font-semibold leading-6 text-gray-900">
                  {showMultipasses ? (
                    <Ticket className="mr-2 size-5" strokeWidth={2.4} />
                  ) : (
                    <NotebookText className="mr-2 size-5" strokeWidth={2.4} />
                  )}
                  {showMultipasses
                    ? translate('pages.public.booking.multipass-selection')
                    : translate('pages.public.booking.course-selection')}
                </h2>
                {showMultipasses && (
                  <Button
                    className=""
                    size="sm"
                    variant="secondary"
                    onClick={() => setShowMultipasses(false)}
                  >
                    {translate('pages.public.booking.multipasses.backToCourses')}
                    <Undo2 className="ml-1 h-4 w-4" />
                  </Button>
                )}
              </div>
              <AnimatePresence>
                {courseCollapsibleOpen && !showMultipasses && organization && organization.id && (
                  <OrganizationTags
                    organization={organization}
                    setSelectedTag={setSelectedTag}
                    activeTagId={selectedTag}
                    initiallySelectedTagId={tagId}
                  />
                )}
                {isLoadingCourses && isLoadingMultipasses ? (
                  <div className="flex items-center justify-center">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
                  </div>
                ) : courses.length === 0 ? (
                  <div className="mt-4 flex items-center space-y-3 rounded-lg border-2 border-dashed border-gray-200 bg-gray-50/50 p-2 py-10 sm:p-6">
                    <div className="mx-auto flex max-w-xs flex-col items-center gap-y-2 text-center font-normal text-gray-700">
                      <BookDashed className="size-10 stroke-2 text-gray-400" />
                      Leider haben wir keine Kurse gefunden, die zu deiner Auswahl passen.
                    </div>
                  </div>
                ) : (
                  <>
                    {showMultipasses && !isLoadingMultipasses && multipassProducts ? (
                      <Collapsible
                        open={multipassCollapsibleOpen}
                        onOpenChange={setMultipassCollapsibleOpen}
                        className="mt-4 space-y-2"
                      >
                        {selectedMultipass && (
                          <motion.div
                            layout={'position'}
                            layoutId={'multipassSelection' + selectedMultipass.id}
                            style={{
                              opacity: 100000,
                              zIndex: 100000,
                            }}
                            className="my-8 flex flex-col items-start justify-between rounded-lg bg-white p-2 px-4 ring-2 ring-indigo-600 sm:flex-row sm:items-center"
                          >
                            <div className="flex flex-col gap-y-1">
                              <div
                                className="mt-1 flex flex-wrap gap-x-2 text-base font-medium sm:mt-0"
                                translate="no"
                              >
                                <p className="font-normal text-muted-foreground">
                                  Ausgewählte Mehrfachkarte:
                                </p>
                                <p>{selectedMultipass.name}</p>
                              </div>
                              <div className="flex items-center gap-x-1">
                                <p className="text-sm font-semibold">
                                  {translate('common.currency.EUR', {
                                    val: selectedMultipass.totalPriceInMills / 1000,
                                    minimumFractionDigits: 2,
                                  })}
                                </p>
                                <p className="text-xs text-muted-foreground">inkl. Mwst</p>
                              </div>
                            </div>

                            <CollapsibleTrigger asChild>
                              <Button variant="ghost" size="sm" className="p-0 sm:p-2">
                                Andere Karte wählen
                                <Undo className="ml-1 h-4 w-4" />
                              </Button>
                            </CollapsibleTrigger>
                          </motion.div>
                        )}
                        <CollapsibleContent className="space-y-2">
                          <ul
                            role="list"
                            className={classNames(
                              multipassProducts.length > 2 ? '' : '',
                              'my-8 space-y-4 rounded-lg',
                            )}
                          >
                            {multipassProducts.map((multipassProduct) => (
                              <motion.li
                                key={multipassProduct.id}
                                layoutId={'multipassSelection' + multipassProduct.id}
                                layout={'position'}
                                style={{
                                  opacity: 100000,
                                }}
                                className={classNames(
                                  selectedMultipass?.id === multipassProduct.id
                                    ? 'ring-2 ring-indigo-500'
                                    : 'justify-start gap-x-10',
                                  'cursor-pointer overflow-hidden rounded-lg bg-violet-100/20 bg-white px-4 py-4 shadow-md ring-1 ring-inset ring-gray-200 sm:rounded-md sm:px-5',
                                )}
                                whileHover={{ scale: isMobile ? 1 : 0.98 }}
                                transition={{
                                  type: 'spring',
                                  stiffness: 400,
                                  damping: 30,
                                  duration: 3000,
                                }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => {
                                  setSelectedIdentifiableBookable({
                                    bookable: multipassProduct,
                                    bookableType: BookableType.Multipass,
                                  })
                                  setSelectedMultipass(multipassProduct)
                                }}
                              >
                                <MultipassRow multipass={multipassProduct} />
                              </motion.li>
                            ))}
                          </ul>
                        </CollapsibleContent>
                      </Collapsible>
                    ) : (
                      <Collapsible
                        open={courseCollapsibleOpen}
                        onOpenChange={setCourseCollapsibleOpen}
                        className="mt-4 space-y-2"
                      >
                        {selectedCourse && (
                          <motion.div
                            layout={'position'}
                            layoutId={'courseSelection' + selectedCourse.id}
                            style={{
                              opacity: 100000,
                            }}
                            className="flex flex-col items-start justify-between rounded-lg p-2 px-4 ring-2 ring-indigo-600 sm:flex-row sm:items-center"
                          >
                            <h4
                              className="mt-1 flex flex-wrap gap-x-2 text-base font-medium sm:mt-0"
                              translate="no"
                            >
                              <p className="font-normal text-muted-foreground">Gewählter Kurs:</p>
                              {selectedCourse.name}
                            </h4>
                            <CollapsibleTrigger asChild>
                              <Button variant="ghost" size="sm" className="p-0 sm:p-2">
                                Anderen Kurs wählen
                                <Undo className="ml-1 h-4 w-4" />
                              </Button>
                            </CollapsibleTrigger>
                          </motion.div>
                        )}
                        <CollapsibleContent className="space-y-2">
                          <ul
                            role="list"
                            className={classNames(
                              courses.length > 2 ? '' : '',
                              'mt-4 space-y-3 rounded-lg',
                            )}
                          >
                            {multipassProducts && multipassProducts.length > 0 && (
                              <motion.div
                                className={classNames(
                                  'cursor-pointer overflow-hidden rounded-lg bg-violet-100/20 bg-white px-4 py-4 ring-1 ring-inset ring-indigo-300 sm:rounded-md sm:px-5',
                                )}
                                initial={{
                                  scale: 1,
                                }}
                                whileHover={{
                                  scale: 0.98,
                                }}
                                whileTap={{ scale: 0.95 }}
                                transition={{
                                  type: 'spring',
                                  stiffness: 400,
                                  damping: 30,
                                  duration: 3000,
                                }}
                                onClick={() => setShowMultipasses(!showMultipasses)}
                              >
                                <div className="flex items-center">
                                  <div className="bezel-lg mr-2 flex size-9 flex-shrink-0 items-center justify-center rounded-lg bg-indigo-500">
                                    <Ticket className="size-5 text-white" />
                                  </div>
                                  <div>
                                    <p className="flex items-center pr-2 font-medium">
                                      {translate(
                                        'pages.public.booking.multipasses.showMultipassesCard.title',
                                      )}
                                      <ArrowRight className="ml-1 h-4 w-4" />
                                    </p>
                                    <div>
                                      <div className="text-xs font-normal text-gray-600">
                                        {translate(
                                          'pages.public.booking.multipasses.showMultipassesCard.subtitle',
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </motion.div>
                            )}
                            {courses.map((course) => (
                              <motion.li
                                layoutId={'courseSelection' + course.id}
                                layout={'position'}
                                key={course.id}
                                style={{
                                  opacity: 100000,
                                }}
                                className={classNames(
                                  selectedCourse?.id === course.id
                                    ? 'ring-2 ring-indigo-500'
                                    : 'justify-start gap-x-10',
                                  'cursor-pointer overflow-hidden rounded-lg bg-violet-100/20 bg-white px-4 py-4 shadow-md ring-1 ring-inset ring-gray-200 sm:rounded-md sm:px-5',
                                )}
                                whileHover={{ scale: isMobile ? 1 : 0.98 }}
                                transition={{
                                  type: 'spring',
                                  stiffness: 400,
                                  damping: 30,
                                  duration: 3000,
                                }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => {
                                  setSelectedCourse(course)
                                  setOrder(null)
                                  removeOrderFromLocalStorage()
                                  removeMeetingIdsFromLocalStorage()
                                }}
                              >
                                <CourseRow course={course} />
                              </motion.li>
                            ))}
                          </ul>
                        </CollapsibleContent>
                      </Collapsible>
                    )}
                  </>
                )}
              </AnimatePresence>
              {courseId && selectedCourse && (
                <motion.div
                  className="mt-10 space-y-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.3,
                  }}
                >
                  <CourseContent course={selectedCourse} />
                  <CourseBrief course={selectedCourse} organizationId={organization.id} />
                  <CourseMeetings
                    course={selectedCourse}
                    setSelectedMeeting={setSelectedIdentifiableBookable}
                    selectedMeeting={identifiableBookable}
                    order={order}
                  />
                </motion.div>
              )}
              {!emailConfirmed && identifiableBookable && (
                <div>
                  <div className="mt-8 sm:rounded-lg">
                    <div>
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        {translate('pages.public.booking.email.title')}
                      </h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>{translate('pages.public.booking.email.subtitle')}</p>
                      </div>

                      <div className="mt-5 sm:flex sm:items-center">
                        <div className="w-full sm:max-w-xs">
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="email"
                              className={classNames(
                                emailError ? 'ring-red-500 focus:ring-red-500' : '',
                                'block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6',
                              )}
                              placeholder={translate('pages.public.booking.email.placeholder')}
                              value={email ?? ''}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                if (!e.target.value.includes('@')) {
                                  setEmailError(true)
                                } else {
                                  setEmailError(false)
                                }
                              }}
                            />
                            {emailError && (
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <AnimatedCheckExclamationCircle className="h-6 w-6 text-red-400" />
                              </div>
                            )}
                            {email && email.includes('@') && (
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <AnimatedCheckCircleIcon className="h-6 w-6 text-green-500" />
                              </div>
                            )}
                          </div>
                          {emailError && (
                            <p className="mt-2 text-sm text-red-600" id="email-error">
                              {translate('pages.public.booking.email.subtitle-error')}
                            </p>
                          )}
                          <div className="mt-4">
                            <Turnstile
                              ref={turnstileRef}
                              // See: https://docs.page/marsidev/react-turnstile/use-ref-methods
                              //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //@ts-ignore
                              onExpire={() => turnstileRef.current?.reset()}
                              onError={() => setShowTurnstile(true)}
                              onBeforeInteractive={() => {
                                setShowTurnstile(true)
                                setBlockAddToCart(true)
                              }}
                              onAfterInteractive={() => setBlockAddToCart(false)}
                              siteKey={turnstileSiteId}
                              options={{
                                theme: 'light',
                                size: !showTurnstile ? 'invisible' : 'normal',
                              }}
                              onSuccess={(token) => setTurnstileChallenge(token)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <BookingButton />
            </ResizablePanel>
          </div>
        </div>
      </div>
    </div>
  )
}

function TermsOfServiceCancellationCourseGroup(props: {
  termsOfServiceId: string
  selectedMeeting: PublicCourseGroupWithBookingInfoEntity
}) {
  const { t: translate } = useTranslation()

  const { termsOfService, isError, isLoading } = useGetTermsOfServicePublic(props.termsOfServiceId)

  const { courseGroupMeetings } = useGetCourseGroupMeetingsPublic(props.selectedMeeting.id)

  if (isError) {
    return null
  }

  const currentDate = DateTime.now()

  const orderedMeetings = courseGroupMeetings.sort((a, b) =>
    DateTime.fromISO(a.start) > DateTime.fromISO(b.start) ? 1 : -1,
  )

  const startOfMeeting = DateTime.fromISO(orderedMeetings[0]?.start)

  const cancellationDeadline = startOfMeeting.minus({
    days: termsOfService.userCancellationDaysBefore,
  })

  if (isLoading) {
    return (
      <p className="mt-2 flex items-center text-sm text-gray-600">
        <Info className="mr-1 inline-block h-4 w-4" />
        <Skeleton className="h-[15px] w-2/5 bg-gray-200" />
      </p>
    )
  }

  // TOS do not allow user cancellation if today is closer to the meeting than the cancellation deadline
  if (currentDate >= cancellationDeadline || !termsOfService.userCancellationEnabled) {
    return (
      <p className="mt-2 flex items-center p-0 text-sm text-gray-600">
        <Info className="mr-1 inline-block h-4 w-4" />
        {translate('pages.public.booking.summary.revocation.expired')}
      </p>
    )
  }

  const dateUntilCancellation = cancellationDeadline.toLocaleString(DateTime.DATETIME_MED)

  return (
    <p className="mt-2 flex items-center text-sm text-gray-600">
      <Info className="mr-1 inline-block h-4 w-4" />
      {translate('pages.public.booking.summary.revocation.remaining', {
        date: dateUntilCancellation,
      })}
    </p>
  )
}

function TermsOfServiceCancellationMeetings(props: {
  termsOfServiceId: string
  selectedMeeting: PublicMeetingWithBookingInfoEntity
}) {
  const { t: translate } = useTranslation()

  const { termsOfService, isError, isLoading } = useGetTermsOfServicePublic(props.termsOfServiceId)

  if (isError) {
    return null
  }

  const currentDate = DateTime.now()

  const startOfMeeting = DateTime.fromISO(props.selectedMeeting.start)

  const cancellationDeadline = startOfMeeting.minus({
    days: termsOfService.userCancellationDaysBefore,
  })

  if (isLoading) {
    return (
      <p className="mt-2 flex items-center text-sm text-gray-600">
        <Info className="mr-1 inline-block h-4 w-4" />
        <Skeleton className="h-[15px] w-2/5 bg-gray-200" />
      </p>
    )
  }

  // TOS do not allow user cancellation if today is closer to the meeting than the cancellation deadline
  if (currentDate >= cancellationDeadline || !termsOfService.userCancellationEnabled) {
    return (
      <p className="mt-2 flex items-center p-0 text-sm text-gray-600">
        <Info className="mr-1 inline-block h-4 w-4" />
        {translate('pages.public.booking.summary.revocation.expired')}
      </p>
    )
  }

  const dateUntilCancellation = cancellationDeadline.toLocaleString(DateTime.DATETIME_MED)

  return (
    <p className="mt-2 flex items-center text-sm text-gray-600">
      <Info className="mr-1 inline-block h-4 w-4" />
      {translate('pages.public.booking.summary.revocation.remaining', {
        date: dateUntilCancellation,
      })}
    </p>
  )
}

function CustomerAppBannerBase() {
  const {
    user,
    isAuthenticated,
    isLoading: isLoadingAuthentication,
    loginWithRedirect,
  } = useAuth0()
  const { t: translate } = useTranslation()

  return (
    <div className="row-span-1 row-end-1 overflow-hidden pb-4 lg:col-span-2 lg:row-span-1 lg:row-end-1">
      <div className="flex shrink items-center justify-between">
        <div className="flex shrink-0">
          <img
            className="ml-1 h-10 w-auto rounded-lg border"
            src="/qourses_logo.webp"
            alt="qourses"
            width="256"
            height="256"
          />
        </div>
        {isLoadingAuthentication && (
          <Button disabled={true} size="sm">
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {translate('common.loading')}
          </Button>
        )}
        {!isLoadingAuthentication && !isAuthenticated && (
          <Button
            size="sm"
            className="bezel rounded-lg bg-indigo-500 bg-gradient-to-b from-indigo-500 to-indigo-600 text-xs text-white ring-1 ring-inset ring-indigo-300"
            onClick={() => {
              loginWithRedirect({
                appState: {
                  returnTo: `${window.location.pathname}${window.location.search}`,
                },
              })
            }}
          >
            Anmelden / Registrieren
            <ExternalLink className="ml-1 size-4" />
          </Button>
        )}
        {!isLoadingAuthentication && isAuthenticated && (
          <Button
            size={'sm'}
            asChild={true}
            className="bezel rounded-lg bg-indigo-500 bg-gradient-to-b from-indigo-500 to-indigo-600 text-xs ring-1 ring-inset ring-indigo-300"
          >
            <NavLink to={'/me'}>
              <>
                {user.picture ? (
                  <img
                    className="h-5 w-5 rounded-full ring-1 ring-gray-300 mr-2 object-cover"
                    src={user?.picture}
                    alt="Profilbild"
                  />
                ) : (
                  <User className="mr-1 size-4" />
                )}
                {user && user.given_name && user.family_name
                  ? `${user.given_name} ${user.family_name}`
                  : user.email}
              </>
            </NavLink>
          </Button>
        )}
      </div>
    </div>
  )
}

export const CustomerAppBanner = React.memo(CustomerAppBannerBase)

function OrganizationMediaImageBase({
  mediaImageId,
  mediaGalleryItemPosition,
  mediaGalleryItem,
  imageEditMode,
  editImageCallback,
}: {
  mediaImageId: string
  mediaGalleryItemPosition: MediaGalleryItemPosition
  mediaGalleryItem: PublicMediaGalleryItemEntity
  imageEditMode?: boolean
  editImageCallback?: () => void
}) {
  const { mediaImage, isLoading } = useGetMediaImagePublic(mediaImageId)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const queryClient = useQueryClient()

  const { t: translate } = useTranslation()

  if (isLoading && !imageEditMode) {
    return (
      <div className="bg-gray-100 w-full h-full flex items-center justify-center rounded-tl-xl md:rounded-l-xl"></div>
    )
  }

  const getPositionSpecificClassnames = (mediaGalleryItemPosition: MediaGalleryItemPosition) => {
    if (mediaGalleryItemPosition === 'hero') {
      return 'rounded-xl md:rounded-none md:rounded-l-xl'
    }
    if (mediaGalleryItemPosition === 'fifth') {
      return 'rounded-bl-xl md:rounded-br-xl md:rounded-bl-none'
    }
    if (mediaGalleryItemPosition === 'third') {
      return 'rounded-tr-xl md:rounded-tr-xl'
    }
  }

  const handleUnlinkGalleryItem = async () => {
    try {
      await qoursesApi.media.mediaControllerDeleteGalleryItem(mediaGalleryItem.id)
      queryClient.invalidateQueries(getMediaGalleryItemsQueryKey(mediaGalleryItem.organizationId))
    } catch (e) {
      sendGenericErrorNotification(e)
    }
  }

  // Handle image click to toggle fullscreen - prevent event bubbling
  const handleImageClick = (e: React.MouseEvent) => {
    if (!imageEditMode) {
      e.stopPropagation() // Stop the event from bubbling up
      e.preventDefault() // Prevent default behavior
      setIsFullScreen(true)
    }
    if (imageEditMode) {
      editImageCallback()
    }
  }

  // Handle closing fullscreen view
  const handleCloseFullScreen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation()
    }
    setIsFullScreen(false)
  }

  return (
    <>
      {mediaImage ? (
        <div className="relative w-full h-full">
          <motion.img
            animate={{ opacity: imageLoaded ? 1 : 0 }}
            transition={{
              opacity: { duration: 0.3 },
            }}
            id={mediaImage.id}
            onLoad={() => setImageLoaded(true)}
            src={getImageUrl(mediaImage.cloudflareImageId)}
            alt="Main property view"
            className={cn(
              'w-full h-full object-cover rounded-xl sm:rounded-none',
              getPositionSpecificClassnames(mediaGalleryItemPosition),
              !imageEditMode && 'cursor-pointer',
            )}
            onClick={handleImageClick}
          />
          {imageEditMode && (
            <Button
              id={mediaImage.id + '_unlinkButton'}
              variant="destructive"
              className="absolute top-3 right-1 bezel hover:bg-red-700"
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleUnlinkGalleryItem()
              }}
            >
              <Unlink className="size-4 mr-1" />
              {translate('pages.public.booking.imageGallery.deleteGalleryItemButton')}
            </Button>
          )}

          {/* Fullscreen Image Modal with higher z-index */}
          {isFullScreen && (
            <motion.div
              id="fullscreen-modal"
              key={mediaGalleryItem.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                opacity: { duration: 0.3 },
              }}
              className="fixed inset-0 z-[10000000] bg-black bg-opacity-90 flex items-center justify-center max-h-screen"
              onClick={handleCloseFullScreen}
            >
              <div className="relative max-w-screen-xl max-h-screen p-4">
                <Button
                  variant="ghost"
                  className="absolute top-5 right-5 text-white hover:bg-gray-100 z-10 p-2 rounded-xl"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCloseFullScreen()
                  }}
                >
                  <X className="size-6" />
                </Button>
                <motion.img
                  src={getImageUrl(mediaImage.cloudflareImageId)}
                  alt="Fullscreen view"
                  className="max-w-full max-h-[90vh] object-contain rounded-xl"
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking the image itself
                />
              </div>
            </motion.div>
          )}
        </div>
      ) : (
        <div
          className={cn(
            'bg-gray-100 w-full h-full flex items-center justify-center border-dashed border-[3px] border-gray-300',
            getPositionSpecificClassnames(mediaGalleryItemPosition),
          )}
          onClick={handleImageClick}
        >
          <ImageUpscale className="size-20 text-gray-300" />
        </div>
      )}
    </>
  )
}

export const OrganizationMediaImage = React.memo(OrganizationMediaImageBase)

function MobileImageGalleryBase({
  organizationMediaImages,
}: {
  organizationMediaImages: JSX.Element[]
}) {
  const containerRef = useRef<HTMLDivElement>(null)

  // Ref to track current active index
  const activeIndexRef = useRef(0)

  // Ref to track if animations should be paused
  const isPausedRef = useRef(false)

  const progressControl1 = useAnimation()
  const progressControl2 = useAnimation()
  const progressControl3 = useAnimation()
  const progressControl4 = useAnimation()
  const progressControl5 = useAnimation()

  const imageControl1 = useAnimation()
  const imageControl2 = useAnimation()
  const imageControl3 = useAnimation()
  const imageControl4 = useAnimation()
  const imageControl5 = useAnimation()

  // Create stable array references with useMemo
  const progressControls = useMemo(
    () => [
      progressControl1,
      progressControl2,
      progressControl3,
      progressControl4,
      progressControl5,
    ],
    [progressControl1, progressControl2, progressControl3, progressControl4, progressControl5],
  )

  const imageControls = useMemo(
    () => [imageControl1, imageControl2, imageControl3, imageControl4, imageControl5],
    [imageControl1, imageControl2, imageControl3, imageControl4, imageControl5],
  )

  const pauseAnimations = useCallback(() => {
    isPausedRef.current = true
    progressControls.forEach((control) => control.stop())
  }, [progressControls])

  // Function to switch to a specific image when a progress bar is clicked
  const switchToImage = useCallback(
    (index: number) => {
      // Pause any ongoing animations
      pauseAnimations()

      // Reset all progress bars
      progressControls.forEach((control, i) => {
        if (i < index) {
          // Set previous bars to completed
          control.set({ scaleX: 1, backgroundColor: '#4f46e5' })
        } else if (i > index) {
          // Set future bars to empty
          control.set({ scaleX: 0, backgroundColor: '#4f46e5' })
        } else {
          // Set current bar to empty but active color
          control.set({ scaleX: 0, backgroundColor: '#4f46e5' })
        }
      })

      // Hide all images
      imageControls.forEach((control, i) => {
        if (i === index) {
          // Show selected image
          control.set({ display: 'block' })
          control.start({ opacity: 1, transition: { duration: 0.3 } })
        } else {
          // Hide other images
          control.start({
            opacity: 0,
            transition: { duration: 0.3 },
          })
          setTimeout(() => {
            control.set({ display: 'none' })
          }, 300)
        }
      })

      // Update active index
      activeIndexRef.current = index

      // Resume animation from the new index
      setTimeout(() => {
        resumeAnimations()
      }, 500)
    },
    [progressControls, imageControls, pauseAnimations],
  )

  // Sequential animation function
  const animateProgressBars = useCallback(
    async (startIndex = 0) => {
      if (isPausedRef.current) return

      const activeColor = '#4f46e5'
      const animationDuration = 4

      // Hide all images initially
      imageControls.forEach((control) => control.set({ opacity: 0, display: 'none' }))

      for (let i = startIndex; i < progressControls.length; i++) {
        // If paused during loop, exit
        if (isPausedRef.current) return

        // Update the ref to the current index
        activeIndexRef.current = i

        // Make current image visible
        imageControls[i].set({ display: 'block' })
        imageControls[i].start({ opacity: 1, transition: { duration: 0.5 } })

        // Hide previous image if it exists
        if (i > 0) {
          imageControls[i - 1].start({ opacity: 0, transition: { duration: 0.5 } })
          setTimeout(() => {
            if (!isPausedRef.current) {
              imageControls[i - 1].set({ display: 'none' })
            }
          }, 500)
        }

        // Reset current bar first (in case we're resuming)
        if (i === startIndex) {
          progressControls[i].set({ scaleX: 0, backgroundColor: '#4f46e5' })
        }

        // Animate the current bar
        try {
          await progressControls[i].start(
            {
              scaleX: 1,
              backgroundColor: activeColor,
            },
            {
              duration: animationDuration,
              ease: 'linear',
            },
          )
          // If paused right after animation completes, exit
          if (isPausedRef.current) return
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          // Animation was interrupted, exit
          return
        }
      }

      // Reset and restart animation
      if (!isPausedRef.current) {
        setTimeout(() => {
          progressControls.forEach((control) =>
            control.set({ scaleX: 0, backgroundColor: '#4f46e5' }),
          )
          animateProgressBars()
        }, 1000)
      }
    },
    [progressControls, imageControls],
  )

  const resumeAnimations = useCallback(() => {
    isPausedRef.current = false
    animateProgressBars(activeIndexRef.current)
  }, [animateProgressBars])

  // effect to handle visibility
  useEffect(() => {
    // Skip if no container or browser doesn't support IntersectionObserver
    if (!containerRef.current || !('IntersectionObserver' in window)) return

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries

        if (entry.isIntersecting) {
          // Element is visible, resume if paused because of visibility
          if (isPausedRef.current) {
            // Only resume if not paused for other reasons (like fullscreen)
            if (!document.getElementById('gallery-fullscreen-modal')) {
              isPausedRef.current = false
              animateProgressBars(activeIndexRef.current)
            }
          }
        } else {
          // Element is not visible, pause animations
          isPausedRef.current = true
          progressControls.forEach((control) => control.stop())
        }
      },
      {
        // Consider visible when at least 10% of the element is in view
        threshold: 0.1,
      },
    )

    observer.observe(containerRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  // Set up observer to detect fullscreen modal
  useEffect(() => {
    // Create a mutation observer to watch for fullscreen modal
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          // Check if a fullscreen modal was added
          const fullscreenAdded = Array.from(mutation.addedNodes).some(
            (node) => node instanceof Element && node.id === 'fullscreen-modal',
          )

          const fullscreenRemoved = Array.from(mutation.removedNodes).some(
            (node) => node instanceof Element && node.id === 'fullscreen-modal',
          )

          if (fullscreenAdded) {
            pauseAnimations()
          } else if (fullscreenRemoved) {
            resumeAnimations()
          }
        }
      }
    })

    // Start observing the document body for added/removed nodes
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  // Start animation when component mounts
  useEffect(() => {
    animateProgressBars()

    return () => {
      progressControls.forEach((control) => control.stop())
      imageControls.forEach((control) => control.stop())
    }
  }, [])

  return (
    <div ref={containerRef}>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          delay: 0.8,
        }}
        className="grid grid-cols-5 mb-2 p-1 gap-x-1.5"
      >
        {[0, 1, 2, 3, 4].map((index) => (
          <motion.div
            whileTap={{
              scale: 0.8,
            }}
            key={index}
            className="bg-gray-200 shadow-inner rounded-lg w-full h-2 overflow-hidden cursor-pointer"
            onClick={() => switchToImage(index)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                switchToImage(index)
              }
            }}
          >
            <motion.div
              initial={{ scaleX: 0 }}
              animate={progressControls[index]}
              className="h-full w-full origin-left rounded-lg"
            />
          </motion.div>
        ))}
      </motion.div>
      <motion.div className={`overflow-hidden`}>
        {organizationMediaImages.map((image, index) => (
          <motion.div
            key={index}
            animate={imageControls[index]}
            className="aspect-square absolute left-0 p-2 w-screen h-auto"
          >
            {image}
          </motion.div>
        ))}
      </motion.div>
      <div className="w-screen h-auto aspect-square" />
    </div>
  )
}

const MobileImageGallery = React.memo(MobileImageGalleryBase)
