import useGetTagsPublic from '@/hooks/public/useGetTagsPublic.tsx'
import { classNames } from '@/utils.tsx'
import { Reorder, motion } from 'framer-motion'
import { Tag } from 'lucide-react'
import React, { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { PublicOrganizationEntity, TagEntity } from '../../../../../qourses-api-client'

function OrganizationTagsComponent(props: {
  organization: PublicOrganizationEntity
  setSelectedTag: (tag: string) => void
  activeTagId: string
  initiallySelectedTagId: string
}) {
  const {
    tags: defaultTags,
    isLoading: defaultTagsLoading,
    isError: defaultTagsError,
  } = useGetTagsPublic(props.organization.id)

  useEffect(() => {
    if (props.initiallySelectedTagId && !defaultTagsLoading && defaultTags) {
      //check if the tagId Param is actually a tagId we can select
      const tag = defaultTags.find((tag) => tag.id === props.initiallySelectedTagId)
      if (tag) {
        props.setSelectedTag(props.initiallySelectedTagId)
      }
    }
  }, [props.initiallySelectedTagId, defaultTagsLoading])

  if (defaultTagsError) {
    return null
  }

  if (defaultTagsLoading) {
    return null
  }

  function HighlightBadge(props: {
    tag: TagEntity
    activeTagId: string
    setActiveTagId: (id: string) => void
  }) {
    const jiggle = {
      animate: {
        rotate: [null, 0, 20, -20, 20, -20, 20, -20, 0, null],
        scale: 1.2,
        transition: {
          type: 'spring',
          bounce: 0.5,
          rotate: {
            repeat: 0,
          },
        },
      },
    }

    return (
      <motion.div
        whileHover={{ scale: isMobile ? 1 : 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{
          type: 'spring',
          bounce: 0.5,
        }}
        className={classNames(
          props.tag.id === props.activeTagId
            ? 'bezel bg-indigo-500 text-white'
            : 'bg-white text-gray-900',
          'flex cursor-pointer items-center gap-x-1 rounded-xl px-3 py-1 text-sm font-semibold ring-1 ring-gray-200',
        )}
        onClick={() => {
          if (props.tag.id === props.activeTagId) {
            props.setActiveTagId('')
          } else {
            props.setActiveTagId(props.tag.id)
          }
        }}
      >
        <motion.div variants={props.tag.id === props.activeTagId && jiggle} animate="animate">
          <Tag className="size-3" />
        </motion.div>
        {props.tag.name}
      </motion.div>
    )
  }

  function DefaultBadge(props: {
    tag: TagEntity
    activeTagId: string
    setActiveTagId: (id: string) => void
  }) {
    const ref = useRef<HTMLDivElement>(null)

    return (
      <motion.div
        ref={ref}
        whileHover={{ scale: isMobile ? 1 : 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{
          type: 'spring',
          bounce: 0.5,
        }}
        className={classNames(
          props.tag.id === props.activeTagId
            ? 'bezel bg-indigo-500 text-white'
            : 'bg-white text-gray-900',
          'flex cursor-pointer items-center gap-x-1 rounded-xl px-3 py-1 text-sm font-normal ring-1 ring-gray-200',
        )}
        onClick={() => {
          if (props.tag.id === props.activeTagId) {
            props.setActiveTagId('')
          } else {
            props.setActiveTagId(props.tag.id)
          }
        }}
      >
        {' '}
        <Tag className="size-3" />
        <p className="whitespace-nowrap">{props.tag.name}</p>
      </motion.div>
    )
  }

  const tagsWithOutHighlighted = defaultTags.filter(
    (tag) =>
      tag.id !== props.organization.highlightedTag1Id &&
      tag.id !== props.organization.highlightedTag2Id &&
      tag.id !== props.organization.highlightedTag3Id,
  )

  const highlightedTags = defaultTags.filter(
    (tag) =>
      tag.id === props.organization.highlightedTag1Id ||
      tag.id === props.organization.highlightedTag2Id ||
      tag.id === props.organization.highlightedTag3Id,
  )

  if (defaultTags.length === 0) {
    return null
  }

  // Combine tags in the required order
  let orderedTags = []
  const activeTag = defaultTags.find((tag) => tag.id === props.activeTagId)

  if (activeTag) {
    orderedTags.push(activeTag)
  }
  orderedTags = orderedTags.concat(highlightedTags.filter((tag) => tag.id !== props.activeTagId))
  orderedTags = orderedTags.concat(
    tagsWithOutHighlighted.filter((tag) => tag.id !== props.activeTagId),
  )

  return (
    <Reorder.Group
      onReorder={() => console.log('')}
      axis={'x'}
      values={orderedTags.map((tag) => tag.id)}
      className="my-4 flex items-center gap-x-3 overflow-x-auto rounded-lg bg-gray-50 px-4 pb-4 pt-4 shadow-inner"
    >
      {orderedTags.map((tag) => (
        <Reorder.Item dragListener={false} key={tag.id} id={tag.id} value={tag.id}>
          {highlightedTags.includes(tag) ? (
            <HighlightBadge
              tag={tag}
              activeTagId={props.activeTagId}
              setActiveTagId={props.setSelectedTag}
            />
          ) : (
            <DefaultBadge
              tag={tag}
              activeTagId={props.activeTagId}
              setActiveTagId={props.setSelectedTag}
            />
          )}
        </Reorder.Item>
      ))}
    </Reorder.Group>
  )
}

export const OrganizationTags = React.memo(OrganizationTagsComponent)
