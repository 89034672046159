import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import i18next from 'i18next'

export default function useReverseGeocoding(
  lat: number,
  long: number,
): {
  geocodeResult: any
  isLoading: boolean
  isError: boolean
} {
  const access_token = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=address&language=${i18next.language}&access_token=${access_token}`

  const {
    data: geocodeResult,
    isLoading,
    isError,
  } = useQuery(
    ['reverse_geocode', long, lat],
    async () => {
      if (lat === 0 || long === 0) {
        return { geocodeResult: undefined, isLoading: false, isError: false }
      }
      const response = await axios.get(url)
      if (response.status == 200) {
        return response.data
      } else {
        throw new Error('Error')
      }
    },
    {
      enabled: !!lat && !!long,
    },
  )

  return { geocodeResult, isLoading, isError }
}
