/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrganizationEntity = {
    id: string;
    name: string;
    companyLegalName: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
    activeCustomer: boolean;
    description: string | null;
    highlightedTag1Id: string | null;
    highlightedTag2Id: string | null;
    highlightedTag3Id: string | null;
    testMode: boolean;
    archived: boolean;
    plan: OrganizationEntity.plan | null;
    planInterval: OrganizationEntity.planInterval | null;
    planPastDue: boolean;
    stripeCustomerId: string;
    trialAvailable: boolean;
    trialUntilOverride: string | null;
    ignorePlanLimits: boolean;
    logoMediaImageId: string | null;
};
export namespace OrganizationEntity {
    export enum plan {
        MINI = 'MINI',
        CORE = 'CORE',
        PRO = 'PRO',
        ULTRA = 'ULTRA',
    }
    export enum planInterval {
        MONTHLY = 'MONTHLY',
        YEARLY = 'YEARLY',
    }
}

