/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrganizationUserNotificationPreferencesEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    bookingNotifications: OrganizationUserNotificationPreferencesEntity.bookingNotifications;
    orderNotifications: OrganizationUserNotificationPreferencesEntity.orderNotifications;
    waitingListNotifications: OrganizationUserNotificationPreferencesEntity.waitingListNotifications;
    userAddedToOrganizationNotifications: boolean;
    organizationRenamedNotifications: boolean;
    mediaNotifications: boolean;
    platformBillingNotifications: boolean;
};
export namespace OrganizationUserNotificationPreferencesEntity {
    export enum bookingNotifications {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        NONE = 'NONE',
    }
    export enum orderNotifications {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        NONE = 'NONE',
    }
    export enum waitingListNotifications {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        NONE = 'NONE',
    }
}

